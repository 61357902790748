import { Route, Routes, useNavigate } from "react-router-dom";
import { useState } from 'react'
import { useSelector } from "react-redux";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProductDetails from './ProductDetails'
import AssuranceMaintenanceDetails from './AssuranceMaintenanceDetails'
import ProductsTabs from "./ProductsTabs";
import SaveSignature from "./SaveSignature"
import axios from "axios";
import FileTable from "../../ProjectFiles";
import NISTDetails from "../../NIST/NISTDetails";
import ProductEventLog from "./ProductEventLog";
import { useCookies } from "react-cookie";
import UnauthorizedView from "../../UI/UnauthorizedView";

export default function ProductManager({setOverlayActive, setOverlayText}) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const navigate = useNavigate()
  const permissions = useSelector(state => state.role.value);
  const [jumpVid, setJumpVid] = useState({})
  const [showAlert, setShowAlert] = useState(false)
  const [alertText, setAlertText] = useState("")

  if (!permissions?.role_permissions?.find(permission => permission.area === 'Project')?.read) {
    return (
        <UnauthorizedView />
    )
  }


  const handleChange = (e) => {
    setJumpVid({...jumpVid, [e.target.name]: e.target.value})
  }

  const jump = (e) => {
    e.preventDefault() 
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_product_by_vid/?vid=${jumpVid.vid}`,
    {
      withCredentials: true,
      headers: { Authorization: `Token ${authToken}` },
    }).then(response => {
      setShowAlert(false)
      setAlertText("")
      navigate(`/community/products/details/${response.data.product_id}`)
    }).catch((error) => {
      setShowAlert(true)
      console.log(error)
      setAlertText(error.response.data.error)
    })
  }

  return (
    <Container className="mt-2" fluid>
      <Alert show={showAlert} variant="warning" dismissible onClose={() => setShowAlert(false)}>{alertText}</Alert>
      {(permissions.role_type === "NIAP" || permissions.role_type === "Validator") && (
      <Form onSubmit={jump}>
        <Row className="justify-content-end">
          <Col xs={12} md={3}>
            <Form.Group controlId="jumpbox">
              <Form.Label className="visually-hidden">Jump to VID</Form.Label>
              <Form.Control name="vid" onChange={handleChange} placeholder="Jump to VID" />
            </Form.Group>
          </Col>
          <Col xs={12} md="auto">
            <Button type="submit" className="w-100 w-md-auto mt-2 mt-md-0">Jump</Button>
          </Col>
        </Row>
      </Form>
      )}
      <Routes>
        {permissions?.role_type !== "Vendor" &&
          <Route path="/" element={<ProductsTabs />} />
        }
          <Route path="details/:id" element={<ProductDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
          <Route path="details/:id/eventlog/:vid" element={<ProductEventLog />} />
          <Route path="details/:id/:type" element={<FileTable setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
          <Route path="details/:product_id/assurancemaintenance/:id" element={<AssuranceMaintenanceDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
          <Route path="details/:product_id/assurancemaintenance/:id/:type" element={<FileTable setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
          <Route path="details/:product_id/nist/:id" element={<NISTDetails />} />
          <Route path="save_signature/:signer/:packageType/:id" element={<SaveSignature setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
      </Routes>
    </Container>
  )
}
