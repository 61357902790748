import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import moment from "moment";

import { Accordion, Button, Modal, Container, Row, Col, Alert } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import HTMLEditor from "../../../UI/HTMLEditor";
import NISTCommentModal from "../NISTCommentModal";
import NISTNotes from "../NISTNotes";
import { useInterval } from "../../../../hooks/useInterval";

const NISTSummary = ({ certReview, update, canEditNist, permissions }) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const user = useSelector((state) => state.user.value);
  const [updatedReview, setUpdatedReview] = useState({});
  const [editJust, setEditJust] = useState(false);
  const [editBlurb, setEditBlurb] = useState(false);
  const [editDraft, setEditDraft] = useState(false);
  const [activeKey, setActiveKey] = useState([]);
  const [comments, setComments] = useState([]);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toDeleteComment, setToDeleteComment] = useState({});
  const [alert, setAlert] = useState({});
  const [selectedUpdate, setSelectedUpdate] = useState({});
  const [enforceFocusModal, setEnforceFocusModal] = useState(true)

  const loadComments = useCallback(() => {
    if (certReview?.crid) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/comments/get_all_comments/?crid=${certReview?.crid}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
          setComments(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [certReview?.crid]);

  useEffect(() => {
    loadComments();
  }, [certReview?.crid]);

  useInterval(() => {
    loadComments();
  }, 300000); //5 min

  const handleItemClick = (idx) => {
    let newKeys = [...activeKey];
    if (newKeys.includes(idx)) {
      newKeys = newKeys.filter((key) => key !== idx);
    } else {
      newKeys.push(idx);
    }
    setActiveKey(newKeys);
  };

  const handleChange = (event) => {
    setUpdatedReview({ ...updatedReview, [event.target.name]: event.target.value });
  };

  const handleSave = (event) => {
    event.preventDefault();
    setEditJust(false);
    setEditBlurb(false);
    setEditDraft(false);
    axios
      .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}nist/${certReview.crid}/`, {...updatedReview, moddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())) }, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setUpdatedReview({});
        update();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const copyAndSave = (event) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/${certReview.crid}/`,
        { response: certReview?.draft, moddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())) },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setUpdatedReview({});
        update();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const pendingFinalReview = (event) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/${certReview.crid}/`,
        {
          status: "Pending Final Review",
          editorext: user.id,
          modext: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          due: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          moddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())) 
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setUpdatedReview({});
        update();
        setAlert({
          message: "Successfully Released Official Response! Status set to Pending Final Review.",
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        setAlert({
          message: "Error Releasing Official Response. Please try again.",
          variant: "danger",
        });
      });
  };

  const acceptAndRelease = (event) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/${certReview.crid}/`,
        {
          status: "Approved",
          editorext: user.id,
          modext: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          enddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          moddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())) 
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setUpdatedReview({});
        update();
        setAlert({
          message: "Successfully Released Official Response! Status set to Approved.",
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        setAlert({
          message: "Error Releasing Official Response. Please try again.",
          variant: "danger",
        });
      });
  };

  const rejectAndRelease = (event) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/${certReview.crid}/`,
        {
          status: "Rejected - Revision Required",
          editorext: user.id,
          modext: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          enddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          moddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())) 
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setUpdatedReview({});
        update();
        setAlert({
          message:
            "Successfully Released Official Response! Status set to Rejected - Revision Required.",
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        setAlert({
          message: "Error Releasing Official Response. Please try again.",
          variant: "danger",
        });
      });
  };

  const openDeleteModal = (update) => {
    setToDeleteComment(update);
    setShowDeleteModal(true);
  };

  const deleteComment = (upid) => {
    axios
      .delete(`${process.env.REACT_APP_DJANGO_ENDPOINT}nist/comments/${upid}/`, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then(() => {
        loadComments();
        setShowDeleteModal(false);
      })
      .catch((error) => alert("Unable to delete note: " + error));
  };

  return (
    <>
      <h4 className="clearfix mt-3 mx-3">
        Justification
        {canEditNist && (
          <span
            style={{ cursor: "pointer", float: "right" }}
            className="mx-3"
            onClick={() => setEditJust(true)}
          >
            <EditIcon />
          </span>
        )}
      </h4>

      {editJust && (
        <>
          <HTMLEditor
            name="just"
            handleChange={handleChange}
            defaultValue={certReview?.just}
            disabled={!editJust}
            setIsValid={() => {}}
            isValid={true}
            setEnforceFocusModal={setEnforceFocusModal}
          />
          <Button className="my-2 mx-2" variant="secondary" onClick={() => setEditJust(false)}>
            Cancel
          </Button>
          <Button className="my-2" onClick={handleSave}>
            <SaveIcon /> Save
          </Button>
        </>
      )}
      {!editJust && <div className="p-3 m-2">{certReview?.just && parse(certReview?.just)}</div>}
      <Accordion defaultActiveKey={["comments", "draft", "response", "notes"]} alwaysOpen>
        <Accordion.Item eventKey="comments" className="border-bottom-dark">
          <Accordion.Header onClick={() => handleItemClick("comments")}>
            <h4>Initial Comments</h4>
          </Accordion.Header>
          <Accordion.Body>
            {editBlurb && (
              <>
                <HTMLEditor
                  name="blurba"
                  handleChange={handleChange}
                  defaultValue={certReview?.blurba}
                  disabled={!editBlurb}
                  setIsValid={() => {}}
                  isValid={true}
                  setEnforceFocusModal={setEnforceFocusModal}
                />
                <Button
                  className="my-2 mx-2"
                  variant="secondary"
                  onClick={() => setEditBlurb(false)}
                >
                  Cancel
                </Button>
                <Button className="my-2" onClick={handleSave}>
                  <SaveIcon /> Save
                </Button>
              </>
            )}
            {!editBlurb && (
              <Container>
                <Row>
                  <Col>
                    <p>{certReview?.blurba && parse(certReview?.blurba)}</p>
                  </Col>
                  {canEditNist && (
                    <Col>
                      <p className="clearfix mx-3">
                        <span
                          style={{ cursor: "pointer", float: "right" }}
                          onClick={() => setEditBlurb(true)}
                        >
                          <EditIcon />
                        </span>
                      </p>
                    </Col>
                  )}
                </Row>
              </Container>
            )}
          </Accordion.Body>
        </Accordion.Item>
        {canEditNist && (
          <Accordion.Item eventKey="draft" className="border-bottom-dark">
            <Accordion.Header onClick={() => handleItemClick("draft")}>
              <h4>Draft Response</h4>
            </Accordion.Header>
            <Accordion.Body>
              <Container>
                <Row>
                  {editDraft && (
                    <Col>
                      <HTMLEditor
                        name="draft"
                        handleChange={handleChange}
                        defaultValue={certReview?.draft}
                        disabled={!editDraft}
                        setIsValid={() => {}}
                        isValid={true}
                        setEnforceFocusModal={setEnforceFocusModal}
                      />
                      <Button
                        className="my-2 mx-2"
                        variant="secondary"
                        onClick={() => setEditDraft(false)}
                      >
                        Cancel
                      </Button>
                      <Button className="my-2" onClick={handleSave}>
                        <SaveIcon /> Save
                      </Button>
                    </Col>
                  )}
                  {!editDraft && (
                    <Col>
                      <p>{certReview?.draft && parse(certReview?.draft)}</p>
                    </Col>
                  )}
                  {canEditNist && (
                    <Col sm={2} className="justify-content-end">
                      <Row>
                        <Button
                          variant="warning"
                          className="p-0 mb-2"
                          onClick={() => setEditDraft(true)}
                        >
                          Edit Draft
                        </Button>
                      </Row>

                      {certReview?.draft && (
                        <Row>
                          <Button
                            variant="secondary"
                            className="p-0 mb-2"
                            onClick={() => setShowCommentModal(true)}
                          >
                            Add Response
                          </Button>
                        </Row>
                      )}
                      {certReview?.draft &&
                        certReview?.stid?.status !== "Approved" &&
                        certReview?.stid?.status !== "Rejected - Revision Required" && (
                          <Row>
                            <Button onClick={copyAndSave} className="p-0">
                              Copy to Official Response
                            </Button>
                          </Row>
                        )}
                    </Col>
                  )}
                  <Row className="mt-3">
                    {comments?.map((comment) => (
                      <div key={comment.upid}>
                        <p className="text-end">
                          <span
                            className="mx-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedUpdate(comment);
                              setShowCommentModal(true);
                            }}
                          >
                            Update
                          </span>
                          <span
                            className="mx-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => openDeleteModal(comment)}
                          >
                            Delete
                          </span>
                        </p>
                        <div className="border border-secondary p-3 m-2">
                          <p className="text-end">
                            Note by {comment?.creator__first_name} {comment?.creator__last_name}{" "}
                            <br />
                            {comment?.entrydate
                              ? moment.utc(comment?.entrydate).format("YYYY.MM.DD")
                              : "---"}
                          </p>
                          {comment?.note1 && parse(comment?.note1)}
                        </div>
                      </div>
                    ))}
                  </Row>
                  <NISTCommentModal
                    show={showCommentModal}
                    onHide={() => {
                      setShowCommentModal(false);
                      setSelectedUpdate({});
                    }}
                    certReview={certReview}
                    update={loadComments}
                    selectedUpdate={selectedUpdate}
                  />
                </Row>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        )}
        <Accordion.Item eventKey="response" className="border-bottom-dark">
          <Accordion.Header onClick={() => handleItemClick("response")}>
            <h4>Official Response</h4>
          </Accordion.Header>
          <Accordion.Body>
            {alert?.message && (
              <Alert variant={alert?.variant} onClose={() => setAlert({})} dismissible>
                {alert?.message}
              </Alert>
            )}
            <div className="p-3 m-2">
              {canEditNist && certReview?.response && parse(certReview?.response)}
              {!canEditNist &&
                ["Approved", "Rejected - Revision Required", "Pending Final Review"]?.includes(
                  certReview?.stid?.status
                ) &&
                certReview?.response &&
                parse(certReview?.response)}
              {canEditNist &&
                certReview?.response &&
                certReview?.stid?.status !== "Approved" &&
                certReview?.stid?.status !== "Rejected - Revision Required" && (
                  <div className="text-end">
                  {certReview?.stid?.status !== "Pending Final Review" && 
                    <Button className="m-2 w-sm-100" onClick={pendingFinalReview} variant="primary">
                      Pending Final Review
                    </Button>
                  }
                    <Button className="m-2 w-sm-100" onClick={acceptAndRelease} variant="success">
                      Accept and Release
                    </Button>
                    <Button className="m-2 w-sm-100" onClick={rejectAndRelease} variant="danger">
                      Reject and Release
                    </Button>
                  </div>
                )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="notes">
          {certReview?.crid && 
            <>
              <Accordion.Header onClick={() => handleItemClick("notes")}>
                <h4>Certificate Review Notes</h4>
              </Accordion.Header>
              <Accordion.Body>
                <NISTNotes item={certReview} note_type="nist" user={user} id={certReview?.crid} />
              </Accordion.Body>
            </>
          }
        </Accordion.Item>
      </Accordion>
      <Modal size="md" show={showDeleteModal} onHide={() => setShowDeleteModal(false)} enforceFocus={enforceFocusModal}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Delete Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-center">
            Are You Sure You Want to Delete this By: <br />
            {toDeleteComment.creator?.first_name} {toDeleteComment.creator?.last_name} on: <br />
            {toDeleteComment.entrydate &&
              moment.utc(toDeleteComment.entrydate).format("YYYY.MM.DD [at] HHmm")}
          </h4>
          <div className="d-flex justify-content-center mt-4">
            <Button variant="primary" className="m-1" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
            <Button
              variant="danger"
              className="m-1"
              onClick={() => deleteComment(toDeleteComment.upid)}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NISTSummary;
