import { useEffect, useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Row, Col, Form } from "react-bootstrap";
import ReactSelect from "../../../ReactSelect";

export default function PPFormSection({
    product,
    editedProduct,
    handleChange,
    ppValid,
    productPps
}) {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    const [allPps, setAllPps] = useState([]);
    const [ppObj] = useState({})

    const loadProductionProfile = useCallback(()=>{
        axios
            .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/get_niap_protection_profiles/`,
                {
                    withCredentials: true,
                    headers: { Authorization: `Token ${authToken}` },
                }
            )
            .then((response) => setAllPps(response.data))
            .catch((error) => console.log(error));
    }, [product])

    useEffect(() => {
        loadProductionProfile()
    }, [loadProductionProfile]);

    const ppOptions = allPps
        ?.filter(
            (pp) =>
                !productPps?.some((productPp) => productPp.pp_id === pp.pp_id) &&
                !["Other", "None", "Not Applicable", "Default"]?.includes(pp.pp_name)
        )
        ?.map((pp) => ({ value: pp.pp_id, label: pp.pp_name }));

    return (
        <Row>
            <Form.Label>Protection Profile Claims:*</Form.Label>
            <Form.Group
                controlId="claim"
                name="protection_profile"
            >
                <Row>
                    <Col>
                        <ReactSelect
                            isMulti={true}
                            options={ppOptions}
                            newObj={!editedProduct?.protection_profile ? ppObj : editedProduct}
                            handleChange={handleChange}
                            name="protection_profile"
                            isValid={ppValid}
                            defaultValue={productPps}
                        />
                    </Col>
                </Row>
            </Form.Group>
        </Row>
    );
}
