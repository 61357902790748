import {
    Accordion,
    Container,
    Row,
    Col,
    Button,
    OverlayTrigger,
    Popover,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import HTMLEditor from "../../UI/HTMLEditor";
import parse from "html-react-parser";
import { useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import moment from "moment";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import award_zero_img from "../../../images/tq_td_awards/award_icon_zero.svg";
import award_one_img from "../../../images/tq_td_awards/award_icon_one.svg";
import award_two_img from "../../../images/tq_td_awards/award_icon_two.svg";
import award_three_img from "../../../images/tq_td_awards/award_icon_three_blue.svg";
import VotingOverlay from "./VotingOverlay";
import { handleAlertAndMailer } from "../../Products/Helper/functions";

export default function TQResponseAccordion({ technicalQuery, refetch }) {
    const [cookies] = useCookies();
    const authToken = cookies["auth_token"];
    let csrfToken = cookies["csrftoken"];
    const user = useSelector((state) => state.user.value);
    const permissions = useSelector((state) => state.role.value);
    const [response, setResponse] = useState({});
    const [saveDisabled, setSaveDisabled]=useState(true)

    const setAwardImage = (voteCount) => {
        var src;
        let alt = "";
        if (voteCount === 0) {
            alt = "award-icon-zero";
            src = award_zero_img;
        } else if (voteCount === 1) {
            alt = "award-icon-one";
            src = award_one_img;
        } else if (voteCount === 2) {
            alt = "award-icon-two";
            src = award_two_img;
        } else if (voteCount === 3) {
            alt = "award-icon-three";
            src = award_three_img;
        } else {
            alt = "award-icon-zero";
            src = award_zero_img;
        }
        return (
            <img
                alt={alt}
                src={src}
                style={{ height: "30px" }}
                className="w-auto"
            />
        );
    };

    const handleChange = (e) => {
        setSaveDisabled(false)
        setResponse({ ...response, [e.target.name]: e.target.value });
    };

    const handleResetVotes = () => {
        let data = {
            voter_one: null,
            voter_two: null,
            voter_three: null,
            vote_one_date: null,
            vote_two_date: null,
            vote_three_date: null,
            vote_one_approve: null,
            vote_two_approve: null,
            vote_three_approve: null,
            recaptcha_token: "member_portal",
            modified_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))
        }
        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${technicalQuery?.tq_id}/`,
                data,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Token ${authToken}`,
                        "X-CSRFToken": csrfToken,
                    },
                }
            )
            .then((response) => {
                refetch();
            })
            .catch((error) => {
                console.log("error updating TQ details", error);
            });
    }

    const handleSave = () => {
        setSaveDisabled(true)
        let data = {
            ...response,
            response_by: user?.id,
            response_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
            recaptcha_token: "member_portal",
            modified_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))
        }

        if(technicalQuery?.status === "Proposed"){
            data["status"] = "In Progress"
        }

        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${technicalQuery?.tq_id}/`,
                data,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Token ${authToken}`,
                        "X-CSRFToken": csrfToken,
                    },
                }
            )
            .then((response) => {
                refetch();
            })
            .catch((error) => {
                console.log("error updating TQ details", error);
            });
    };

    const handleSubmit = (status) => {
        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${technicalQuery?.tq_id}/`,
                {
                    ...response,
                    status: status,
                    recaptcha_token: "member_portal",
                    modified_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))
                },
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Token ${authToken}`,
                        "X-CSRFToken": csrfToken,
                    },
                }
            )
            .then((response) => {
                refetch();
                if(status === "Resolution Proposed"){
                    handleAlertAndMailer(csrfToken, authToken, {
                        alert_type_id: technicalQuery?.tq_id,
                        alert_type: "Technical Query",
                        subject: `Technical Query Draft Response Opened for Voting - ${technicalQuery?.title}`,
                        recipients: { to: ["TRRT Team"], cc: [user.email] },
                        alert_text: `TQ Draft Response Opened for Voting - ${technicalQuery?.title}`,
                    });
                }
            })
            .catch((error) => {
                console.log("error updating TQ details", error);
            });
    };

    const handleAddVote = (vote) => {
        let voter_field = technicalQuery?.voter_field;
        let remove_voter = false;
        if (voter_field) {
            //set the date and boolean depending on voter_field
            let vote_date_field = "";
            let vote_field = "";

            //if user has vote prior
            if (technicalQuery?.current_user_vote?.vote_field !== null) {
                voter_field = technicalQuery?.current_user_vote?.vote_field;
            }

            //if user try to click to same vote, 'remove' the vote
            if (vote === technicalQuery?.current_user_vote?.vote) {
                vote = null;
                remove_voter = true;
            }

            if (voter_field === "voter_one") {
                vote_date_field = "vote_one_date";
                vote_field = "vote_one_approve";
            } else if (voter_field === "voter_two") {
                vote_date_field = "vote_two_date";
                vote_field = "vote_two_approve";
            } else {
                vote_date_field = "vote_three_date";
                vote_field = "vote_three_approve";
            }

            axios
                .put(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${technicalQuery?.tq_id}/`,
                    {
                        [voter_field]: remove_voter ? null : user?.id,
                        [vote_date_field]: remove_voter ? null : new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                        [vote_field]: vote,
                        recaptcha_token: "member_portal",
                        modified_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))
                    },
                    {
                        withCredentials: true,
                        headers: {
                            "X-CSRFToken": csrfToken,
                            Authorization: `Token ${authToken}`,
                        },
                    }
                )
                .then((response) => {
                    refetch();
                })
                .catch((error) => console.log(error));
        }
    };

    const handleSendToCustomer = () => {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${technicalQuery?.tq_id}/`,
          {
            status: "Resolved",
            recaptcha_token: "member_portal",
            official_response: technicalQuery?.response,
            official_response_by: user?.id,
            modified_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
            official_response_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          },
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
              "X-CSRFToken": csrfToken,
            },
          }
        )
        .then((response) => {
          handleAlertAndMailer(csrfToken, authToken, {
            alert_type_id: technicalQuery?.tq_id,
            alert_type: "Technical Query",
            subject: `Technical Query Response - ${technicalQuery?.title}`,
            recipients: { to: ["Customer"], cc: [user.email] },
            alert_text: `Technical Query Response sent for TQ ID ${technicalQuery?.tq_id}`,
          });
          refetch();
        })
        .catch((error) => {
          console.log("error updating TQ details", error);
        });
    };

    return (
        <>
            <Accordion>
            {technicalQuery?.status === "Rejected" ?
            <Accordion>
                <Accordion.Item eventKey="1">
                    <Accordion.Header className="border-0">
                        <h6 className="text-primary fw-bold">
                            REJECTION RATIONALE:
                        </h6>
                    </Accordion.Header>
                    <Accordion.Body>
                       {technicalQuery?.response}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            :
                <Accordion.Item eventKey="1">
                    <Accordion.Header className="border-0">
                        <h6 className="text-primary fw-bold">
                            DRAFT RESPONSE:
                        </h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        {permissions.role_permissions.find(
                            (permission) =>
                                permission.area === "Technical Questions"
                        )?.update &&
                        (technicalQuery?.status !== "TD Required" && technicalQuery?.status !== "Resolved") ? (
                            <Container>
                                <Row>
                                    <Col>
                                        {technicalQuery?.response_by && (
                                            <p className="mx-2">
                                                Response by{" "}
                                                <span className="fst-italic">
                                                    {
                                                        technicalQuery
                                                            ?.response_by
                                                            ?.first_name
                                                    }{" "}
                                                    {
                                                        technicalQuery
                                                            ?.response_by
                                                            ?.last_name
                                                    }
                                                </span>{" "}
                                                on{" "}
                                                <span className="fst-italic">
                                                    {moment(
                                                        technicalQuery?.response_on
                                                    ).format("MM/DD/YYYY")}
                                                </span>
                                            </p>
                                        )}
                                    </Col>
                                    {technicalQuery?.status ===
                                        "Resolution Proposed" && (
                                        <>
                                            <Col
                                                xs={1}
                                                className="d-flex justify-content-center w-auto"
                                            >
                                                <p className="mx-3">Please Vote Here!</p>
                                                {technicalQuery
                                                    ?.current_user_vote
                                                    ?.vote_field === null ||
                                                technicalQuery
                                                    ?.current_user_vote
                                                    ?.vote !== false ? (
                                                    <ThumbDownOffAltIcon
                                                        className="clickable"
                                                        onClick={() =>
                                                            handleAddVote(false)
                                                        }
                                                    />
                                                ) : (
                                                    <ThumbDownAltIcon
                                                        style={{
                                                            color: "var(--bs-danger)",
                                                        }}
                                                        className="clickable"
                                                        onClick={() =>
                                                            handleAddVote(false)
                                                        }
                                                    />
                                                )}
                                                {technicalQuery?.vote_count
                                                    ?.false_votes > 0 ? (
                                                    <OverlayTrigger
                                                        trigger={[
                                                            "hover",
                                                            "focus",
                                                        ]}
                                                        rootClose
                                                        placement="bottom"
                                                        key="bottom"
                                                        overlay={
                                                            <Popover>
                                                                <Popover.Body>
                                                                    <VotingOverlay 
                                                                        voteApprove={technicalQuery?.vote_one_approve === false}
                                                                        voter={technicalQuery?.voter_one}
                                                                        date={technicalQuery?.vote_one_date}
                                                                    />
                                                                    <VotingOverlay 
                                                                        voteApprove={technicalQuery?.vote_two_approve === false}
                                                                        voter={technicalQuery?.voter_two}
                                                                        date={technicalQuery?.vote_two_date}
                                                                    />
                                                                    <VotingOverlay 
                                                                        voteApprove={technicalQuery?.vote_three_approve === false}
                                                                        voter={technicalQuery?.voter_three}
                                                                        date={technicalQuery?.vote_three_date}
                                                                    />
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        <p className="mx-3">
                                                            {
                                                                technicalQuery
                                                                    ?.vote_count
                                                                    ?.false_votes
                                                            }
                                                        </p>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <p className="mx-3">
                                                        {
                                                            technicalQuery
                                                                ?.vote_count
                                                                ?.false_votes
                                                        }
                                                    </p>
                                                )}
                                            </Col>
                                            <Col
                                                xs={1}
                                                className="d-flex justify-content-center w-auto"
                                            >
                                                <>
                                                    {technicalQuery
                                                        ?.current_user_vote
                                                        ?.vote_field === null ||
                                                    technicalQuery
                                                        ?.current_user_vote
                                                        ?.vote !== true ? (
                                                        <ThumbUpOffAltIcon
                                                            className="clickable"
                                                            onClick={() =>
                                                                handleAddVote(
                                                                    true
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <ThumbUpAltIcon
                                                            style={{
                                                                color: "var(--bs-success)",
                                                            }}
                                                            className="clickable"
                                                            onClick={() =>
                                                                handleAddVote(
                                                                    true
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    {technicalQuery?.vote_count
                                                        ?.true_votes > 0 ? (
                                                        <OverlayTrigger
                                                            trigger={[
                                                                "hover",
                                                                "focus",
                                                            ]}
                                                            rootClose
                                                            placement="bottom"
                                                            key="bottom"
                                                            overlay={
                                                                <Popover>
                                                                    <Popover.Body>
                                                                        <VotingOverlay 
                                                                            voteApprove={technicalQuery?.vote_one_approve}
                                                                            voter={technicalQuery?.voter_one}
                                                                            date={technicalQuery?.vote_one_date}
                                                                        />
                                                                        <VotingOverlay 
                                                                            voteApprove={technicalQuery?.vote_two_approve}
                                                                            voter={technicalQuery?.voter_two}
                                                                            date={technicalQuery?.vote_two_date}
                                                                        />
                                                                        <VotingOverlay 
                                                                            voteApprove={technicalQuery?.vote_three_approve}
                                                                            voter={technicalQuery?.voter_three}
                                                                            date={technicalQuery?.vote_three_date}
                                                                        />
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }
                                                        >
                                                            <p className="mx-3">
                                                                {
                                                                    technicalQuery
                                                                        ?.vote_count
                                                                        ?.true_votes
                                                                }
                                                            </p>
                                                        </OverlayTrigger>
                                                    ) : (
                                                        <p className="mx-3">
                                                            {
                                                                technicalQuery
                                                                    ?.vote_count
                                                                    ?.true_votes
                                                            }
                                                        </p>
                                                    )}
                                                </>
                                            </Col>
                                            <Col
                                                xs={1}
                                                className="w-auto p-0 me-3"
                                            >
                                                {setAwardImage(
                                                    technicalQuery?.vote_count
                                                        ?.total_votes
                                                )}
                                            </Col>
                                        </>
                                    )}
                                </Row>
                                {!["TD Required", "Resolved", "Proposed"].includes(technicalQuery?.status) && permissions?.role_type === "NIAP" &&
                                    <Row>
                                        <Col className="d-flex justify-content-end">
                                            <p className="text-muted m-0">
                                            A total of{" "}
                                            {
                                                permissions?.role_type === "NIAP" ? 
                                                "1 vote" : 
                                                "3 votes"
                                            }{" "}needed to save and send to customer as{" "}
                                            {permissions?.role_type}.
                                            </p>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col>
                                        <HTMLEditor
                                            name="response"
                                            handleChange={handleChange}
                                            setIsValid={() => {}}
                                            isValid={true}
                                            defaultValue={
                                                technicalQuery?.response
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3 mb-3 d-flex justify-content-end">
                                    <Col xs={3} className="w-auto">
                                        <Button
                                            variant="success"
                                            onClick={handleSave}
                                            disabled={saveDisabled}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                    <Col xs={3} className="w-auto">
                                        {technicalQuery?.status !==
                                            "Resolved" &&
                                            permissions.role_permissions.find(
                                                (permission) =>
                                                    permission.area ===
                                                    "Technical Questions"
                                            )?.update && permissions?.role_type === "NIAP" && (
                                            <Button
                                                variant="primary"
                                                disabled={
                                                    //if not niap, needs 3 upvotes, else only 1 upvote
                                                    technicalQuery
                                                        ?.vote_count
                                                        ?.true_votes <
                                                    1
                                                }
                                                onClick={
                                                    handleSendToCustomer
                                                }
                                            >
                                                Send Official Response
                                            </Button>
                                        )}
                                    </Col>
                                    {
                                        permissions?.role_type === 'NIAP' &&
                                        technicalQuery?.vote_count?.total_votes === 0 &&
                                        technicalQuery?.status !== "Resolution Proposed" &&
                                        <Col xs={3} className="w-auto">
                                            <Button
                                                variant="warning"
                                                onClick={() => handleSubmit("Resolution Proposed")}
                                                disabled={!response?.response}
                                            >
                                                Open For Voting
                                            </Button>
                                        </Col>
                                    }
                                    {
                                        technicalQuery?.vote_count?.total_votes > 0 && 
                                        permissions?.role_type === 'NIAP' &&
                                            <Col xs={3} className="w-auto">
                                                <Button
                                                    variant="danger"
                                                    onClick={handleResetVotes}
                                                >
                                                    Reset Votes
                                                </Button>
                                            </Col>
                                    }
                                </Row>
                            </Container>
                        ) : (
                            <Container>
											<Row>
												<Col>
													{technicalQuery?.response_by && (
														<p className="mx-2">
															Response by{" "}
															<span className="fst-italic">
																{technicalQuery
																	?.response_by
																	?.first_name}
																{" "}
																{technicalQuery
																	?.response_by
																	?.last_name}
															</span>
															{" "}on{" "}
															<span className="fst-italic">
																{moment(
																	technicalQuery?.response_on
																).format("MM/DD/YYYY")}
															</span>
														</p>
													)}
												</Col>
												<Col
													xs={1}
													className="d-flex justify-content-center w-auto">
													<p className="mx-3">Votes</p>
													<ThumbDownOffAltIcon />
													
                                                {technicalQuery?.vote_count
                                                    ?.false_votes > 0 ? (
                                                    <OverlayTrigger
                                                        trigger={[
                                                            "hover",
                                                            "focus",
                                                        ]}
                                                        rootClose
                                                        placement="bottom"
                                                        key="bottom"
                                                        overlay={
                                                            <Popover>
                                                                <Popover.Body>
                                                                    <VotingOverlay 
                                                                        voteApprove={technicalQuery?.vote_one_approve === false}
                                                                        voter={technicalQuery?.voter_one}
                                                                        date={technicalQuery?.vote_one_date}
                                                                    />
                                                                    <VotingOverlay 
                                                                        voteApprove={technicalQuery?.vote_two_approve === false}
                                                                        voter={technicalQuery?.voter_two}
                                                                        date={technicalQuery?.vote_two_date}
                                                                    />
                                                                    <VotingOverlay 
                                                                        voteApprove={technicalQuery?.vote_three_approve === false}
                                                                        voter={technicalQuery?.voter_three}
                                                                        date={technicalQuery?.vote_three_date}
                                                                    />
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        <p className="mx-3">
                                                            {
                                                                technicalQuery
                                                                    ?.vote_count
                                                                    ?.false_votes
                                                            }
                                                        </p>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <p className="mx-3">
                                                        {
                                                            technicalQuery
                                                                ?.vote_count
                                                                ?.false_votes
                                                        }
                                                    </p>
                                                )}
												</Col>
												<Col
													xs={1}
													className="d-flex justify-content-center w-auto">
													<>
														<ThumbUpOffAltIcon />
														
                                                        {technicalQuery?.vote_count
                                                    ?.true_votes > 0 ? (
                                                    <OverlayTrigger
                                                        trigger={[
                                                            "hover",
                                                            "focus",
                                                        ]}
                                                        rootClose
                                                        placement="bottom"
                                                        key="bottom"
                                                        overlay={
                                                            <Popover>
                                                                <Popover.Body>
                                                                    <VotingOverlay 
                                                                        voteApprove={technicalQuery?.vote_one_approve === true}
                                                                        voter={technicalQuery?.voter_one}
                                                                        date={technicalQuery?.vote_one_date}
                                                                    />
                                                                    <VotingOverlay 
                                                                        voteApprove={technicalQuery?.vote_two_approve === true}
                                                                        voter={technicalQuery?.voter_two}
                                                                        date={technicalQuery?.vote_two_date}
                                                                    />
                                                                    <VotingOverlay 
                                                                        voteApprove={technicalQuery?.vote_three_approve === true}
                                                                        voter={technicalQuery?.voter_three}
                                                                        date={technicalQuery?.vote_three_date}
                                                                    />
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        <p className="mx-3">
                                                            {
                                                                technicalQuery
                                                                    ?.vote_count
                                                                    ?.true_votes
                                                            }
                                                        </p>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <p className="mx-3">
                                                        {
                                                            technicalQuery
                                                                ?.vote_count
                                                                ?.true_votes
                                                        }
                                                    </p>
                                                )}
													</>
												</Col>
												<Col
													xs={1}
													className="w-auto p-0 me-3">
													{setAwardImage(
														technicalQuery?.vote_count
															?.total_votes
													)}
												</Col>
											</Row>
                                {technicalQuery?.response &&
                                    parse(technicalQuery?.response)}
                            </Container>
                        )}
                    </Accordion.Body>
                </Accordion.Item>
            }
            </Accordion>
            <Accordion>
                <Accordion.Item eventKey="1">
                    <Accordion.Header className="border-0">
                        <h6 className="text-primary fw-bold">
                            OFFICIAL RESPONSE:
                        </h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Container>
                            {technicalQuery?.official_response &&
                                parse(technicalQuery?.official_response)}
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}
