import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Row, Col, Button, Form } from "react-bootstrap";

export default function EvaluationTeamFormSection({ product, handleChangeForm, setEvalTeam, reload }) {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    const permissions = useSelector((state) => state.role.value);

    const loadProductFromId = useCallback(()=>{
        if (product?.product_id) {
            axios
                    .get(
                        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/all_product_evaluators/?product=${product.product_id}`,
                        {
                            withCredentials: true,
                            headers: {
                                Authorization: `Token ${authToken}`,
                            },
                        }
                    )
                    .then((response) => setEvalTeam(response.data))
                    .catch((error) => console.log(error));
        }
    }, [product?.product_id])

    useEffect(() => {
            loadProductFromId()
    }, [reload]);

    return (
        <>
            {permissions?.role_permissions?.find(
                (permission) => permission.area === "Project Members"
            )?.update &&
                permissions?.role_permissions?.find(
                    (permission) => permission.area === "Evaluation Team"
                )?.create && (
                    <Row className="mt-4 mb-3">
                        <p className="fw-bold form-title">EVALUATION TEAM</p>
                        <p>Add Members to Evaluation Team?</p>
                        <Col>
                            <Button
                                variant="primary"
                                onClick={(e) => handleChangeForm(e, "eval")}
                            >
                                Click to add Evaluation Team Members
                            </Button>
                        </Col>
                        <Form.Text className="text-muted fst-italic">Lead Evaluator Assignment Required to Submit Form</Form.Text>
                    </Row>
                )}
        </>
    );
}
