import axios from "axios";
import { useState } from "react";

const CalendarEventCreator = ({ product, cookies }) => {
  let csrfToken;
  let authToken;
  let statuses;
  if (cookies) {
    csrfToken = cookies["csrftoken"];
    authToken = cookies["auth_token"];
  }
  const getStatuses = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status/get_all_statuses/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        statuses = response.data;
      })
      .catch((error) =>
        console.log("Unable to get Calendar Statuses: ", error)
      );
  };
  getStatuses();

  const createCalendarEvent = (title, statusFilter, startDate) => {
    if (statuses) {
      let currStatus = statuses.filter((status) =>
        status.text === statusFilter,
      );
      console.log(currStatus)
      let statusId = currStatus[0].id;
      startDate.setHours(0, 0, 0, 0);
      let endDate = new Date(startDate);
      endDate.setHours(23, 59, 59, 999);
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status/get_event_by_status_and_product/?statusId=${statusId}&productId=${product?.product_id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
          axios
            .put(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/${response.data.id}/`,
              {
                startDate: startDate,
                endDate: endDate,
              },
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              console.log(response.status);
            })
            .catch((error) =>
              console.log("Unable to update Calendar event: ", error)
            );
        })
        .catch(() => {
          // if event doesnt exist
          const requestData = {
            title: title,
            startDate: startDate,
            endDate: endDate,
            statusId: statusId,
            allDay: true,
            productId: product?.product_id,
          };
          axios
            .post(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/`,
              requestData,
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              console.log(response.status);
            })
            .catch((error) =>
              console.log("Unable to create Calendar event: ", error)
            );
        });
    }
  };

  return {
    createCalendarEvent,
  };
};

export default CalendarEventCreator;
