import {
    Container,
    Row,
    Col,
    OverlayTrigger,
    Popover,
    Button,
    ListGroup, 
    Tooltip
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import award_zero_img from "../../../images/tq_td_awards/award_icon_zero.svg";
import award_one_img from "../../../images/tq_td_awards/award_icon_one.svg";
import award_two_img from "../../../images/tq_td_awards/award_icon_two.svg";
import award_three_img from "../../../images/tq_td_awards/award_icon_three_blue.svg";
import qs from 'qs'
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import moment from 'moment';

export default function TQTable({ refetch, tableRef, setTrrtCount }) {
    const [cookies] = useCookies();
    const authToken = cookies["auth_token"];
    let csrfToken = cookies["csrf_token"];
    const permissions = useSelector((state) => state.role.value);
    const user = useSelector((state) => state.user.value);

    const handleAddVote = (rowData, vote) => {
        let voter_field = rowData?.voter_field;
        let remove_voter = false;
        if (voter_field) {
            //set the date and boolean depending on voter_field
            let vote_date_field = "";
            let vote_field = "";

            //if user has vote prior
            if (rowData?.current_user_vote?.vote_field !== null) {
                voter_field = rowData?.current_user_vote?.vote_field;
            }

            //if user try to click to same vote, 'remove' the vote
            if (vote === rowData?.current_user_vote?.vote) {
                vote = null;
                remove_voter = true;
            }

            if (voter_field === "voter_one") {
                vote_date_field = "vote_one_date";
                vote_field = "vote_one_approve";
            } else if (voter_field === "voter_two") {
                vote_date_field = "vote_two_date";
                vote_field = "vote_two_approve";
            } else {
                vote_date_field = "vote_three_date";
                vote_field = "vote_three_approve";
            }

            axios
                .put(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${rowData?.tq_id}/`,
                    {
                        [voter_field]: remove_voter ? null : user?.id,
                        [vote_date_field]: remove_voter ? null : new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                        [vote_field]: vote,
                        recaptcha_token: "member_portal",
                        modified_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))
                    },
                    {
                        withCredentials: true,
                        headers: {
                            "X-CSRFToken": csrfToken,
                            Authorization: `Token ${authToken}`,
                        },
                    }
                )
                .then((response) => {
                   refetch();
                })
                .catch((error) => console.log(error));
        }
    };

    const setAwardImage = (voteCount) => {
        var src;
        let alt = "";
        if (voteCount === 0) {
            alt = "award-icon-zero";
            src = award_zero_img;
        } else if (voteCount === 1) {
            alt = "award-icon-one";
            src = award_one_img;
        } else if (voteCount === 2) {
            alt = "award-icon-two";
            src = award_two_img;
        } else if (voteCount === 3) {
            alt = "award-icon-three";
            src = award_three_img;
        } else {
            alt = "award-icon-zero";
            src = award_zero_img;
        }
        return (
            <img
                alt={alt}
                src={src}
                style={{ height: "30px" }}
                className="w-auto"
            />
        );
    };

    const trrtColumns = [
        {
            title: "ID",
            field: "tq_id",
            render: (rowData) => (
                <Button
                    variant="link"
                    href={`/community/technicalqueries/details/${rowData?.tq_id}`}
                    className="text-decoration-underline small-font"
                >
                    {rowData?.tq_id}
                </Button>
            ),
        },
        {
            title: "VID",
            field: "tq_id",
            render: (rowData) => (
                <ListGroup>
                {rowData?.product_evaluation?.map((product, idx) => (
                    <OverlayTrigger
                      key={idx}
                      placement="top"
                      overlay={<Tooltip>{product?.product_name}</Tooltip>}
                    >
                      <Link
                        to={`/community/products/details/${product?.product_id}`}
                        className='text-decoration-underline'
                      >
                        <ListGroup.Item className="no-hover-effect">
                          VID{product?.v_id}
                        </ListGroup.Item>
                      </Link>
                    </OverlayTrigger>
                ))}
                </ListGroup>
            ),
        },
        {
            title: "Submitted On",
            render: (rowData) =>
            rowData.submitted_on ? moment.utc(rowData.submitted_on).format('MM/DD/YYYY') : "",
        },
        {
            title: "Team",
            field: "team.team",
        },
        { title: "Question", field: "title" },
        { title: "Status", field: "status" },
        {
            title: "Vote",
            hidden: !permissions?.role_permissions?.find(
                (permission) => permission.area === "Technical Questions"
            )?.update,
            render: (rowData) => (
                <Container>
                    {rowData?.status === "Resolution Proposed" ? (
                        <Row>
                            <Col sm={4}>
                                <>
                                    {rowData?.current_user_vote?.vote_field ===
                                        null ||
                                    rowData?.current_user_vote?.vote !==
                                        false ? (
                                        <ThumbDownOffAltIcon
                                            className="clickable"
                                            onClick={() =>
                                                handleAddVote(rowData, false)
                                            }
                                        />
                                    ) : (
                                        <ThumbDownAltIcon
                                            style={{
                                                color: "var(--bs-danger)",
                                            }}
                                            className="clickable"
                                            onClick={() =>
                                                handleAddVote(rowData, false)
                                            }
                                        />
                                    )}
                                    {rowData?.vote_count?.false_votes > 0 ? (
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            rootClose
                                            placement="bottom"
                                            key="bottom"
                                            overlay={
                                                <Popover>
                                                    <Popover.Body>
                                                        <p className="m-0">
                                                            {rowData?.vote_one_approve ===
                                                                false &&
                                                                rowData?.voter_one &&
                                                                rowData
                                                                    .voter_one
                                                                    ?.first_name +
                                                                    " " +
                                                                    rowData
                                                                        ?.voter_one
                                                                        ?.last_name}
                                                        </p>
                                                        <p className="m-0">
                                                            {rowData?.vote_two_approve ===
                                                                false &&
                                                                rowData?.voter_two &&
                                                                rowData
                                                                    .voter_two
                                                                    ?.first_name +
                                                                    " " +
                                                                    rowData
                                                                        ?.voter_two
                                                                        ?.last_name}
                                                        </p>
                                                        <p className="m-0">
                                                            {rowData?.vote_three_approve ===
                                                                false &&
                                                                rowData?.voter_three &&
                                                                rowData
                                                                    .voter_three
                                                                    ?.first_name +
                                                                    " " +
                                                                    rowData
                                                                        ?.voter_three
                                                                        ?.last_name}
                                                        </p>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <p className="d-flex justify-content-center mb-0">
                                                {
                                                    rowData?.vote_count
                                                        ?.false_votes
                                                }
                                            </p>
                                        </OverlayTrigger>
                                    ) : (
                                        <p className="d-flex justify-content-center mb-0">
                                            {rowData?.vote_count?.false_votes}
                                        </p>
                                    )}
                                </>
                            </Col>
                            <Col sm={4}>
                                <>
                                    {rowData?.current_user_vote?.vote_field ===
                                        null ||
                                    rowData?.current_user_vote?.vote !==
                                        true ? (
                                        <ThumbUpOffAltIcon
                                            className="clickable"
                                            onClick={() =>
                                                handleAddVote(rowData, true)
                                            }
                                        />
                                    ) : (
                                        <ThumbUpAltIcon
                                            style={{
                                                color: "var(--bs-success)",
                                            }}
                                            className="clickable"
                                            onClick={() =>
                                                handleAddVote(rowData, true)
                                            }
                                        />
                                    )}
                                    {rowData?.vote_count?.true_votes > 0 ? (
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            rootClose
                                            placement="bottom"
                                            key="bottom"
                                            overlay={
                                                <Popover>
                                                    <Popover.Body>
                                                        <p className="m-0">
                                                            {rowData?.vote_one_approve ===
                                                                true &&
                                                                rowData?.voter_one &&
                                                                rowData
                                                                    .voter_one
                                                                    ?.first_name +
                                                                    " " +
                                                                    rowData
                                                                        ?.voter_one
                                                                        ?.last_name}
                                                        </p>
                                                        <p className="m-0">
                                                            {rowData?.vote_two_approve ===
                                                                true &&
                                                                rowData?.voter_two &&
                                                                rowData
                                                                    .voter_two
                                                                    ?.first_name +
                                                                    " " +
                                                                    rowData
                                                                        ?.voter_two
                                                                        ?.last_name}
                                                        </p>
                                                        <p className="m-0">
                                                            {rowData?.vote_three_approve ===
                                                                true &&
                                                                rowData?.voter_three &&
                                                                rowData
                                                                    .voter_three
                                                                    ?.first_name +
                                                                    " " +
                                                                    rowData
                                                                        ?.voter_three
                                                                        ?.last_name}
                                                        </p>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <p className="d-flex justify-content-center mb-0">
                                                {
                                                    rowData?.vote_count
                                                        ?.true_votes
                                                }
                                            </p>
                                        </OverlayTrigger>
                                    ) : (
                                        <p className="d-flex justify-content-center mb-0">
                                            {rowData?.vote_count?.true_votes}
                                        </p>
                                    )}
                                </>
                            </Col>
                            <Col sm={4}>
                                {setAwardImage(
                                    rowData?.vote_count?.total_votes
                                )}
                            </Col>
                        </Row>
                    ) : (
                        <p>Resolution Not Proposed</p>
                    )}
                </Container>
            ),
        },
        {
            filtering: false,
            sorting: false,
            render: (rowData) => (
                <Link
                to={`technicalqueries/details/${rowData.tq_id}`}
                relative="route"
                className="text-decoration-none"
                >
                    <Button
                        variant="link"
                        className="m-1 p-0 btn-link"
                    >
                        <VisibilityIcon color="action" /> View
                    </Button>
                </Link> 
            ),
        },
    ];

    const options = {
        sorting: true,
        columnsButton: true,
    };

    const fetchTQUrl = () => {
        if(permissions?.role_type === "NIAP"){
          return "technical-query/niap_tq_table_data/"
        } else if (permissions?.role_type === "Lab"){
          return "technical-query/lab_tq_table_data/"
        } else if (permissions?.role_type === "Validator"){
          return "technical-query/validator_tq_table_data/"
        } else if (permissions?.role_type === "SME"){
            return "technical-query/sme_tq_table_data/"
        }
      };

    return (
        <ResponsiveMaterialTable
            title="TRRTs"
            tableRef={tableRef}
            data={(query) =>
                new Promise((resolve, reject) => {
                  // Extract the necessary information from the query object
                  const {
                      page,
                      pageSize,
                      search,
                      filters,
                      orderBy,
                      orderDirection,
                  } = query;
      
                  let newFilters = filters.map((filter) => {
                      let value = ""
                      if (Array.isArray(filter.value)) {
                          value = filter.value
                      } else {
                          value = filter.value.replace(/['"]+/g, '')
                      }
                      return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                  });
                  const params = {
                      offset: page * pageSize,
                      limit: pageSize,
                      search: search,
                      filters: newFilters,
                      orderBy: orderBy?.field,
                      orderDirection: orderDirection,
                  }

                  params['status'] = "Proposed,TD Required,Resolution Proposed,In Progress,TD Proposed,Accepted"
                  params["active"] = JSON.stringify(true)

                  axios
                      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}${fetchTQUrl()}?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                          {
                              withCredentials: true,
                              headers: {
                                  Authorization: `Token ${authToken}`,
                              },
                          }
                      )
                      .then((response) => {
                            if(setTrrtCount){
                                setTrrtCount(response.data.count)
                            }
                          resolve({
                              data: response.data.results,
                              page: page,
                              totalCount: response.data.count,
                          });
                      })
                      .catch((error) => {
                          reject(error);
                      });
                  })
                }
            options={options}
            columns={trrtColumns}
        />
    );
}
