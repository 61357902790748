import { useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from 'axios';
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import "./saveSignature.css"

const SaveSignature = ({setOverlayActive, setOverlayText}) => {
    const {packageType, id, signer} = useParams()    
    const [cookies] = useCookies()
    const navigate = useNavigate()
    const dataFetchedRef = useRef(false);
    let csrfToken = cookies['csrftoken']
    let authToken = cookies['auth_token']

    const saveSignature = useCallback(() => {
      axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/save_signature/`, 
      {product_id: id, packageType: packageType, signer: signer}, 
      {withCredentials: true, headers: {
        'X-CSRFToken': csrfToken, 
        'Authorization': `Token ${authToken}`,
      }}).then((response) => {
        setOverlayActive(false)
        if (signer !== "Vendor") {
          navigate(`/community/products/details/${id}`)
        } else {
          navigate(`/community`)
        }
      }).catch((error) => {
        console.log(error.response.data)
      })
    }, [])

    useEffect(() => {
        if (dataFetchedRef.current) return
        dataFetchedRef.current = true;
        setOverlayText("Redirecting")
        setOverlayActive(true)
        saveSignature()
    }, [])

    return (
        <Container>
            <Card className='login-card success'>
                <Card.Body>
                    <CheckBoxIcon color="primary" sx={{fontSize: 100}} />
                    <Card.Title as="h4">Success</Card.Title>
                    <p className='small'>You are being redirected...</p>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default SaveSignature