import ResponsiveMaterialTable from "../UI/MaterialTable/ResponsiveMaterialTable";
import { useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './tds.css'
import { Link } from "react-router-dom";
import { useInterval } from "../../hooks/useInterval";
import qs from 'qs'
import moment from 'moment';

export default function TechDecisionsTable({sidebarFilters}) {

    const navigate = useNavigate();
    const tableRef = useRef()

    useEffect(() => {
        tableRef.current.onQueryChange()
    }, [sidebarFilters])

    useInterval(()=>{
        tableRef.current.onQueryChange()
    }, 300000) //5 min

    const columns = [
        { title: 'Item', field: 'td_id', render: (rowData) => (
            <Link to={`/technical-decisions/${rowData.identifier}`} className='text-decoration-underline'>
              {rowData?.identifier}
            </Link>) },
        { title: 'Title', field: 'title', render: (rowData) => 
            <div className='detail-btn' onClick={() => navigate(`/technical-decisions/${rowData?.identifier}`)}>
                {rowData?.title}
            </div> 
        },
        { title: 'Protection Profiles', field: 'protection_profile', render: (rowData) =>                         
            <ul>
                {rowData?.protection_profile.map((pp) => {
                    return <li key={pp.pp_id}>{pp.pp_short_name}</li>
                })}
                {rowData?.sunset_protection_profile?.map((pp) => {
                    return <li key={pp.pp_id}>{pp.pp_short_name}</li>
                })}
            </ul>
        },
        { title: 'References', field: 'related_to' },
        { title: 'Publication Date', field: 'publication_date', render: (rowData) => (
            <>
                {rowData?.publication_date &&
                    <div data-testid='pub_date'>
                        {moment.utc(rowData?.publication_date).format('MM/DD/YYYY')}
                    </div>
                }
            </>
        ), type: 'date'
        },
        { 
            title: "Archived Date", 
            field: "removed_on", 
            hidden: sidebarFilters.status.indexOf("Archived") <0,
            type: 'date',
            render: (rowData) => (
                <>
                    {rowData?.removed_on &&
                        <div data-testid='archived_date'>
                            {moment.utc(rowData?.removed_on).format('MM/DD/YYYY')}
                        </div>
                    }
                </>
            )
        }
    ]

    const options = {
        headerStyle: {
            fontWeight: "bold",
            paddingBottom: "0"
        },
        pageSizeOptions: [20, 50, 100],
        pageSize: 20
    }

    return (
        <ResponsiveMaterialTable
            title={sidebarFilters.status.indexOf("Archived") <0 ? "Active Technical Decisions" : "Archived Technical Decisions"}
            columns={columns}
            tableRef={tableRef}
            data={(query) =>
                new Promise((resolve, reject) => {
                  // Extract the necessary information from the query object
                  const {
                      page,
                      pageSize,
                      search,
                      filters,
                      orderBy,
                      orderDirection,
                  } = query;
      
                  let newFilters = filters.map((filter) => {
                      let value = ""
                      if (Array.isArray(filter.value)) {
                          value = filter.value
                      } else {
                          value = filter.value.replace(/['"]+/g, '')
                      }
                      return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                  });
                  const params = {
                    ...sidebarFilters,
                      offset: page * pageSize,
                      limit: pageSize,
                      search: search,
                      filters: newFilters,
                      orderBy: orderBy?.field,
                      orderDirection: orderDirection,
                  }
                  axios
                      .get(
                          `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/frontend_tds?${qs.stringify(params, {arrayFormat: 'comma'})}`
                      )
                      .then((response) => {
                          resolve({
                              data: response.data.results,
                              page: page,
                              totalCount: response.data.count,
                          });
                      })
                      .catch((error) => {
                          reject(error);
                      });
                  })
                }
            options={options}
        />
    )
}
