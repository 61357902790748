import PPDetails from './PPDetails'
import { Route, Routes } from "react-router-dom";
import PPTable from './PPTable';
import { useSelector } from "react-redux";
import UnauthorizedView from '../../UI/UnauthorizedView';

export default function PPManager({setOverlayActive, setOverlayText}) {
  const permissions = useSelector(state => state.role.value);

  if(!(permissions?.role_permissions?.find((permission) => permission.area === "Protection Profile")?.read)) {
      return (
          <UnauthorizedView />
      )
  }
    
  return (
    <Routes>
        <Route path="/" element={<PPTable />} />
        <Route path="details/:id" element={<PPDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
    </Routes>
  )
}
