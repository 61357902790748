import { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import moment from "moment";
import "moment-timezone";
import FileDownload from "js-file-download";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Accordion, Button, Stack, Container, Row, Col } from "react-bootstrap";
import FileUpload from "../../../Products/FileUpload";
import NISTAssignAgentModal from "../NISTAssignAgentModal";
import { useInterval } from "../../../../hooks/useInterval";

const NISTSideInfo = ({ certReview, canEditNist, permissions, update, setOverlayActive, setOverlayText }) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [files, setFiles] = useState([]);
  const [agents, setAgents] = useState([]);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [openAgentModal, setOpenAgentModal] = useState(false);
  const [statuses, setStatuses] = useState([]);

  const loadFiles = useCallback(() => {
    if (certReview?.crid) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=nist&file_type_id=${certReview?.crid}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
          setFiles(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [certReview?.crid]);

  useEffect(() => {
    loadFiles();
  }, [certReview?.crid]);

  useInterval(() => {
    loadFiles();
  }, 300000); //5 min

  const loadAgents = useCallback(() => {
    if (certReview?.crid) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/agents/get_all_agents/?crid=${certReview?.crid}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
          setAgents(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [certReview?.crid]);

  useEffect(() => {
    loadAgents();
  }, [certReview?.crid]);

  useInterval(() => {
    loadAgents();
  }, 300000); //5 min

  const closeAndReload = () => {
    loadFiles();
    setOpenFileModal(false);
    updateNist({ stid: statuses?.find((status) => status.status === "Pending Review")?.stid });
  };

  const loadStatuses = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}nist/status/get_all_statuses`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        let statuses = response.data;
        setStatuses(statuses);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    loadStatuses();
  }, [certReview?.crid]);

  const updateNist = (updatedData) => {
    axios
      .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}nist/${certReview?.crid}/`, {...updatedData, moddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())) }, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        update();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadFile = (file) => {
    setOverlayText("Downloading")
    setOverlayActive(true)
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        setOverlayActive(false)
        FileDownload(response.data, file.file_new_name ? file.file_new_name : file.file_name);
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  const removeAgent = (qcid) => {
    axios
      .delete(`${process.env.REACT_APP_DJANGO_ENDPOINT}nist/agents/${qcid}/`, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then(() => {
        loadAgents();
      })
      .catch((error) => alert("Unable to delete note: " + error));
  };

  return (
    <>
      <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Attached Documents</h6>
          </Accordion.Header>
          <Accordion.Body>
            {files?.map((file, idx) => (
              <Stack
                direction="horizontal"
                className="d-flex justify-content-left align-items-start"
                key={idx}
              >
                {file.file_mime_type === "application/pdf" && (
                  <FontAwesomeIcon icon={faFilePdf} color="red" size="xl" />
                )}
                {file.file_mime_type !== "application/pdf" && (
                  <FontAwesomeIcon icon={faFileLines} color="gray" size="xl" />
                )}
                <div className="ms-2 text-break">
                  <Button
                    style={{ overflowWrap: "break-word" }}
                    className="text-start m-0 p-0"
                    variant="link"
                    onClick={() => downloadFile(file)}
                  >
                    {file.file_label
                      ? file.file_label
                      : file.file_new_name
                      ? file.file_new_name
                      : file.file_name}
                  </Button>
                  <p className="mb-0 ms-3">
                    {file.file_new_name ? file.file_new_name : file.file_name}
                  </p>
                  <p className="mb-0 ms-3">
                    Attached by {file?.uploaded_by?.first_name} {file?.uploaded_by?.last_name} on{" "}
                    {file?.uploaded_on &&
                      moment.utc(file?.uploaded_on).format("YYYY.MM.DD [at] HHmm")}
                  </p>
                </div>
              </Stack>
            ))}
            {(canEditNist || permissions?.role_type === "Lab") && (
              <div className="d-flex justify-content-center">
                <Button variant="link" onClick={() => setOpenFileModal(true)} className="text-info">
                  <UploadFileIcon /> Add Files
                </Button>
              </div>
            )}
            <FileUpload
              show={openFileModal}
              updateFiles={closeAndReload}
              type="nist"
              id={certReview?.crid}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Team Information</h6>
          </Accordion.Header>
          <Accordion.Body>
            <Container>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <p className="fw-bold">NCERT Team:</p>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      {canEditNist && (
                        <Button onClick={() => setOpenAgentModal(true)} variant="warning">
                          <EditIcon style={{ height: 20 }} /> Edit Team
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {agents.map((agent, idx) => (
                    <Row key={idx} className="mb-1 mt-4">
                      <Col>
                        {agent?.account_id?.first_name} {agent?.account_id?.last_name}
                      </Col>
                      <Col>
                        {canEditNist && (
                          <p
                            style={{ cursor: "pointer", float: "right" }}
                            onClick={() => removeAgent(agent?.qcid)}
                          >
                            <CloseIcon />
                          </p>
                        )}
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </Container>
            <NISTAssignAgentModal
              show={openAgentModal}
              onHide={() => setOpenAgentModal(false)}
              certReview={certReview}
              agents={agents}
              update={loadAgents}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Attribution Information</h6>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <div className="w-100 border border-secondary p-3">
              <small>Record Created:</small>
              <p>
                {certReview?.entrydate &&
                  moment.utc(certReview.entrydate).format("YYYY.MM.DD [at] HHmm")}{" "}
                by <br />
                {certReview?.creator &&
                  certReview?.creator?.first_name + " " + certReview?.creator?.last_name}
              </p>
            </div>
            <div className="w-100 border border-secondary p-3">
              <small>Last Modified:</small>
              <p>
                {certReview?.moddate &&
                  moment.utc(certReview.moddate).format("YYYY.MM.DD [at] HHmm")}{" "}
                by <br />
                {certReview?.editor &&
                  certReview?.editor?.first_name + " " + certReview?.editor?.last_name}
              </p>
            </div>
            <div className="w-100 border border-secondary p-3">
              <small>Due Date:</small>
              <p>
                {certReview?.due &&
                  moment.utc(certReview.due).format("YYYY.MM.DD")}{" "}
              </p>
            </div>
            <div className="w-100 border border-secondary p-3">
              <small>Official Response Sent to Lab:</small>
              {certReview?.modext ? (
                <p>
                  {certReview?.modext &&
                    moment.utc(certReview.modext).format("YYYY.MM.DD [at] HHmm")}{" "}
                  by <br />
                  {certReview?.editorext &&
                    certReview?.editorext?.first_name + " " + certReview?.editorext?.last_name}
                </p>
              ) : (
                <p>Not Sent</p>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default NISTSideInfo;
