import { useState, useEffect } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import axios from "axios";
import { useCookies } from "react-cookie";
import ReactSelect from "../../../ReactSelect"

const Filter = ({filters, setFilters}) => {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    const [currentFilters, setCurrentFilters] = useState({...filters, status: filters?.status?.split(','), project_pp_product: filters?.project_pp_product?.split(',')})
    const [ppSelect, setPPSelect] = useState([])
    const [formKey, setFormKey] = useState(Date.now());

    const loadProtectionProfiles = () => {
        axios.get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/get_niap_protection_profiles/`,
            {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                },
            }
        ).then(response => {
            setPPSelect(response.data.map(pp => ({label: pp.pp_name, value: pp.pp_id})))
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        loadProtectionProfiles()
    }, [])


    useEffect(() => {
        if (Object.keys(filters).length === 0) {
            setCurrentFilters({})
            document.getElementById('filters').reset()
        }
    }, [filters])

    const handleChange = (event) => {
        if (event.target.name === "status") {
            let filter = currentFilters[event.target.name]
            if (!filter) {
                filter = [event.target.value]
            } else {
                if (event.target.checked) {
                    filter.push(event.target.value)
                } else {
                    const idx = filter.indexOf(event.target.value)
                    filter.splice(idx, 1)
                }
            }
            setCurrentFilters({...currentFilters, [event.target.name]: filter})
        }else if (event.target.name === "project_pp_product" && event.target.value.length === 0) {
            const newFilters = currentFilters
            delete newFilters[event.target.name]
            setCurrentFilters({...newFilters})
        } else { 
            setCurrentFilters({...currentFilters, [event.target.name]: event.target.value})
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setFilters({...currentFilters, status: currentFilters?.status?.join(','), project_pp_product: currentFilters?.project_pp_product?.join(',')})
    }

    const handleClear = () => {
        setFilters({})
        setCurrentFilters({})
        setFormKey(Date.now())
    }

    // const removeFilter = (key) => {
    //     const newFilters = currentFilters
    //     delete newFilters[key]
    //     setFilters({...newFilters})
    // }

    return (
        <Form onSubmit={handleSubmit} id="filters" key={formKey}>
            <Form.Group className="d-inline-block" controlId="from-date">
                <Form.Label>Time Frame</Form.Label>
                <Form.Control type="date" name="from_date" onChange={handleChange} />
            </Form.Group>
            <p className="d-inline-block p-3">to</p>
            <Form.Group className="d-inline-block" controlId="to-date">
                <Form.Label>Time Frame</Form.Label>
                <Form.Control type="date" name="to_date" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="date-type" className="mb-3">
                <Form.Label>For The</Form.Label>
                <Form.Select name="date_type" onChange={handleChange}>
                    <option value="">Select a type for the time frame</option>
                    <option value="certification_date">Date Certificate Presented</option>
                    <option value="ccra_notifiy_date">CCRA Notification Date</option>
                    <option value="congrats_letter_date">Date of Congratulatory Letter</option>
                    <option value="term_warn_issued_date">Date Termination Warning Issued</option>
                    <option value="termination_date">Termination Date</option>
                    <option value="record_closeout_date">Record Closeout Date</option>
                    <option value="assurance_maintenance_date">Assurance Maintenance Date</option>
                    <option value="warehouse">Warehouse Date</option>
                    <option value="certificate_printed_date">Date Certificate Issued</option>
                    <option value="kicked_off_date">Kickoff Date</option>
                    <option value="created_on">Entry Date</option>
                    <option value="edited_on">Modification Date</option>
                    <option value="product_progress_1_estimated">Progress Point 1: Check-in Package Recieved (Estimated)</option>
                    <option value="product_progress_1_completed">Progress Point 1: Check-in Package Recieved (Completed)</option>
                    <option value="product_progress_2_estimated">Progress Point 2: Check-in Meeting Complete (Estimated)</option>
                    <option value="product_progress_2_completed">Progress Point 2: Check-in Meeting Complete (Completed)</option>
                    <option value="product_progress_3_estimated">Progress Point 3: ST Evaluation Complete (Estimated)</option>
                    <option value="product_progress_3_completed">Progress Point 3: ST Evaluation Complete (Completed)</option>
                    <option value="product_progress_4_estimated">Progress Point 4: Test Ready (Estimated)</option>
                    <option value="product_progress_4_completed">Progress Point 4: Test Ready (Completed)</option>
                    <option value="product_progress_5_estimated">Progress Point 5: Testing Complete (Estimated)</option>
                    <option value="product_progress_5_completed">Progress Point 5: Testing Complete (Completed)</option>
                    <option value="product_progress_6_estimated">Progress Point 6: Evaluator Check-out (Estimated)</option>
                    <option value="product_progress_6_completed">Progress Point 6: Evaluator Check-out (Completed)</option>
                    <option value="product_progress_6x_estimated">Progress Point 6x: Evaluator Check-out Resubmission (Estimated)</option>
                    <option value="product_progress_6x_completed">Progress Point 6x: Evaluator Check-out Resubmission (Completed)</option>
                    <option value="product_progress_7_estimated">Progress Point 7: Validator Check-out Complete (Estimated)</option>
                    <option value="product_progress_7_completed">Progress Point 7: Validator Check-out Complete (Completed)</option>
                    <option value="product_progress_7x_estimated">Progress Point 7x: Validator Check-out Complete (Estimated)</option>
                    <option value="product_progress_7x_completed">Progress Point 7x: Validator Check-out Complete (Completed)</option>
                    <option value="product_progress_8_estimated">Progress Point 8: PCL Posting Date (Estimated)</option>
                    <option value="product_progress_8_completed">Progress Point 8: PCL Posting Date (Completed)</option>
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="product-name" className="mb-3">
                <Form.Label>Product Name</Form.Label>
                <Form.Control type="text" name="product_name" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="vendor" className="mb-3">
                <Form.Label>Vendor</Form.Label>
                <Form.Control type="text" name="vendor" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="associated-lab" className="mb-3">
                <Form.Label>Lab</Form.Label>
                <Form.Control type="text" name="associated_lab" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="csfc-eligible" className="mb-3">
                <Form.Label>CSfC</Form.Label>
                <Form.Select name="csfc_eligible" onChange={handleChange}>
                    <option value="Any">Any Response</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Maybe">Maybe</option>
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="dod-compliant" className="mb-3">
                <Form.Label>DoD Annex Compliant</Form.Label>
                <Form.Select name="dod_compliant" onChange={handleChange}>
                    <option value="Any">Any Response</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="recommend-kickoff" className="mb-3">
                <Form.Label>Recommended For Kickoff</Form.Label>
                <Form.Select name="kickoff_status" onChange={handleChange}>
                    <option value="Any">Any Response</option>
                    <option value="Recommended for Kickoff">Yes</option>
                    <option value="Other">No</option>
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="recommend-posting" className="mb-3">
                <Form.Label>Recommended For Posting</Form.Label>
                <Form.Select name="product_progress_7_status" onChange={handleChange}>
                    <option value="Any">Any Response</option>
                    <option value="Ready for Release">Yes</option>
                    <option value="Other">No</option>
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="dod-compliant" className="mb-3">
                <Form.Label>ACMR Approval Recommended</Form.Label>
                <Form.Select name="current_assurance_maintenance_progress_3_status" onChange={handleChange}>
                    <option value="Any">Any Response</option>
                    <option value="In Progress">Yes</option>
                    <option value="Other">No</option>
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="status" className="mb-3">
                <Form.Label>Status</Form.Label>
                <Row>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Draft" label="In Draft" onChange={handleChange} id="status1" />
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Proposed" label="Proposed" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Proposed')} id="status2"/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Pre-Evaluation" label="Pre-Evaluation" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Pre-Evaluation')} id="status3"/>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="In Progress" label="In Progress" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'In Progress')} id="status4"/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Finalizing" label="Finalizing" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Finalizing')} id="status5"/>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Certified" label="Completed" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Certified')} id="status6" />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Terminated" label="Terminated" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Terminated')} id="status7" />
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Withdrawn" label="Withdrawn" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Withdrawn')} id="status8" />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="In Assurance Maintenance" label="In Assurance Maintenance" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'In Assurance Maintenance')} id="status9" />
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Archived" label="Archived" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Archived')} id="status10" />
                    </Col>
                </Row>
                {/* <Row>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Other" label="Other" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Other')} id="status11"/>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="null" label="Unknown" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Unknown')} id="status12"/>
                    </Col>
                </Row> */}
            </Form.Group>
            <Form.Group controlId="conformance" className="mb-3">
                <Form.Label>Conformance</Form.Label>
                <Form.Select name="conformance_type" onChange={handleChange}>
                    <option value="default">Default</option>
                    <option value="all">Match All Selections</option>
                    <option value="any">Match Any Selection</option>
                    <option value="minimum">Match Selections as the Minimum</option>
                </Form.Select>
                <ReactSelect 
                    name="project_pp_product"
                    handleChange={handleChange}
                    defaultValue={ppSelect.filter(pp => currentFilters.project_pp_product?.includes(pp.value))}
                    options={ppSelect}
                    newObj={currentFilters}
                    isMulti={true}
                    isValid={true}
                />
            </Form.Group>
            <div className="mb-3">
                <Button type="submit" variant="primary" onClick={handleSubmit}>Apply</Button>
                &nbsp;
                <Button type="reset" variant="outline-dark" onClick={handleClear}>Clear</Button>
            </div>
        </Form>
    )
}

export default Filter