import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable"
import Chip from '@mui/material/Chip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Button, Popover, OverlayTrigger, ListGroup, Tooltip } from 'react-bootstrap';
import { useState } from 'react';
import ManageValidationTeamModal from './ManageValidationTeamModal';
import ManageAMValidationTeamModal from './ManageAMValidationTeamModal';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReviewAMModal from '../AMTableModule/ReviewAMModal';
import EditIcon from "@material-ui/icons/Edit";
import FolderIcon from '@mui/icons-material/Folder';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import qs from 'qs'
import {useCookies} from "react-cookie";

export default function ValidatorResourceRequestTable({
  tableRef,
  projpps,
  currentRole,
  refetch,
  isValidatorResponseMember,
  allProducts,
  setOverlayActive,
  setOverlayText,
  setAlertTable
}) {
  const [cookies] = useCookies()
  let authToken = cookies['auth_token']
  const [showManageValTeamModal, setShowManageValTeamModal] = useState(false);
  const [showManageAMValTeamModal, setShowManageAMValTeamModal] = useState(false);
  const [currentProductInEval, setCurrentProductInEval] = useState({});
  const [showReviewAMModal, setShowReviewAMModal] = useState(false);

  const handleCloseReviewAMModal = (openTeamModal, product_id) => {
    setShowReviewAMModal(false);
    tableRef.current.onQueryChange()
    let updatedProduct = allProducts?.filter(prod => prod?.product_id === product_id)
    if(updatedProduct?.length > 0) {
      setCurrentProductInEval(updatedProduct[0]);
      if(openTeamModal) {
        setShowReviewAMModal(false);
        if(isValidatorResponseMember?.is_member){
          setShowManageAMValTeamModal(true);
        }
      }
    }
  }

  const handleShowManageTeamModal = (rowData) => {
    setCurrentProductInEval(rowData);
    if(rowData?.submission_type === "Assurance Maintenance"){
      setShowManageAMValTeamModal(true)
    } else {
      setShowManageValTeamModal(true);
    }
  };

  const getStatus = (rowData) => {
    if(rowData?.status === "Certified"){
      return "Completed"
    } else if (rowData?.status === 'In Assurance Maintenance'){
      return rowData?.current_assurance_maintenance?.status
    } else {
      return rowData?.status
    }
  }

  const columns = [
    {
      title: 'VID No.',
      field: 'v_id',
      type: 'numeric',
      render: (rowData) => (
        <Link to={`products/details/${rowData.product_id}`} className='text-decoration-underline'>
          {rowData?.v_id}
        </Link>
      ),
      cellStyle: {
        width: '10%',
        minWidth: '10%',
      },
    },
    {
      title: 'Vendor',
      field: 'vendor_id.name',
      cellStyle: {
        width: '15%',
        minWidth: '15%',
      },
    },
    {
      title: 'Product',
      field: 'product_name',
    },
    { title: 'Conformance', render: (rowData) => (
      <ListGroup>
      { projpps && projpps[rowData?.product_id]?.map((projpp, idx) => (
        <OverlayTrigger
        key={idx}
        placement="top"
        overlay={<Tooltip>{projpp.name}</Tooltip>}
      >
        <Link
          to={`/community/protectionprofiles/details/${projpp.id}`}
          className='text-decoration-underline'
        >
          <ListGroup.Item disabled>
            {projpp.pp_short_name}
          </ListGroup.Item>
        </Link>
      </OverlayTrigger>
          ))
      }
    </ListGroup>
    ) 
    },
    {
      title: 'CCTL',
      field: 'assigned_lab.name',
    },
    {
      title: 'Date Submitted',
      render: (rowData) => {
        if(rowData.current_assurance_maintenance?.lab_request_date){
          return moment.utc(rowData.current_assurance_maintenance?.lab_request_date).format('MM/DD/YYYY')
        } else if (rowData.current_assurance_maintenance?.vendor_request_date){
          return moment.utc(rowData.current_assurance_maintenance?.vendor_request_date).format('MM/DD/YYYY')
        } else {
          moment.utc(rowData?.check_in_date).format('MM/DD/YYYY')
        }
      },
    },
    {
      title: 'Team Due Date',
      render: (rowData) => (
        <>
        {
          rowData.current_assurance_maintenance?.team_due_date ? 
          moment.utc(rowData.current_assurance_maintenance?.team_due_date).format('MM/DD/YYYY') : 
          moment.utc(rowData?.team_due_date).format('MM/DD/YYYY')
        }
        </>
      ),
    },
    {
      title: 'Status',
      field: 'status',
      render: (rowData) => <Chip label={getStatus(rowData)} />,
    },
    {
      title: 'Assignment Status',
      field: 'pre_eval_status',
      render: (rowData) => {
        if(rowData.current_assurance_maintenance){
          if(rowData?.current_assurance_maintenance?.validation_team_approval_status){
            return rowData?.current_assurance_maintenance?.validation_team_approval_status
          } else {
            return "Not Released"
          }
        } else {
          return rowData?.pre_eval_status
        }
      }
    },
    {
      title: "Actions",
      field: 'actions',
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <>
          {
            rowData?.submission_type === "Assurance Maintenance" &&
            rowData?.current_assurance_maintenance?.status === "Released" ?
              <Button 
                variant="primary" 
                className='rounded-pill small-font p-1'
                onClick={() => {
                  setCurrentProductInEval(rowData); 
                  setShowReviewAMModal(true);
                }}
              >
                Review
              </Button>
            :
              <OverlayTrigger
                trigger='click'
                rootClose
                placement='bottom'
                key='bottom'
                overlay={
                  <Popover>
                    <Popover.Body>
                    <div className="d-flex-column text-center">
                    {currentRole?.role_permissions?.find(permission => permission.area === 'Project')?.read &&
                    <>
                      <Link
                        to={
                          rowData?.submission_type === "Assurance Maintenance" ?
                          `products/details/${rowData.product_id}/assurancemaintenance/${rowData?.current_assurance_maintenance?.maintenance_id}` :
                          `products/details/${rowData.product_id}`
                        }
                        className='text-decoration-none'
                      >
                        <Button variant='link' className='m-1 p-0 btn-link'>
                        <RemoveRedEyeIcon color='action' />
                          Details
                        </Button>
                      </Link>
                      <Link 
                        to={
                          rowData?.submission_type === "Assurance Maintenance" ?
                          `/community/products/details/${rowData?.product_id}/assurancemaintenance/${rowData?.current_assurance_maintenance?.maintenance_id}/am_files` :
                          `/community/products/details/${rowData?.product_id}/product_files`
                        }
                        className='text-decoration-none'
                      >
                        <Button variant='link' className='m-1 p-0 btn-link'>
                        <FolderIcon color='action' />
                          Files
                        </Button>
                      </Link>
                    </>
                      }
                      {isValidatorResponseMember?.is_member &&
                        <Button
                          variant='link'
                          className='m-1 p-0 btn-link'
                          onClick={() => handleShowManageTeamModal(rowData)}
                        >
                        <EditIcon color='action' />
                          Validation Team
                        </Button>
                      }
                        </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button variant='link'>
                  <MoreVertIcon className='float-end' />
                </Button>
              </OverlayTrigger>
          }
          </>
        );
      },
    },
  ];

  const options = {
    sorting: true,
    search: false,
    columnsButton: true,
    padding: 'dense',
    pageSize: 20
  };

  return (
    <>
      <ManageValidationTeamModal
        show={showManageValTeamModal}
        setShow={setShowManageValTeamModal}
        currentProductInEval={currentProductInEval}
        refetch={() => tableRef.current.onQueryChange()}
        setAlertTable={setAlertTable}
      />
      <ManageAMValidationTeamModal
        show={showManageAMValTeamModal}
        setShow={setShowManageAMValTeamModal}
        currentProductInEval={currentProductInEval}
        refetch={() => tableRef.current.onQueryChange()}
      />
      <ReviewAMModal
        show={showReviewAMModal}
        handleCloseModal={handleCloseReviewAMModal}
        product={currentProductInEval}
        refetch={refetch}
        setOverlayActive={setOverlayActive} 
        setOverlayText={setOverlayText} 
      />
      <ResponsiveMaterialTable  
        title="Validator Resource Requests"
        columns={columns}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const {
                page,
                pageSize,
                search,
                filters,
                orderBy,
                orderDirection,
            } = query;

            let newFilters = filters.map((filter) => {
                let value = ""
                if (Array.isArray(filter.value)) {
                    value = filter.value
                } else {
                    value = filter.value.replace(/['"]+/g, '')
                }
                return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
                offset: page * pageSize,
                limit: pageSize,
                search: search,
                filters: newFilters,
                orderBy: orderBy?.field,
                orderDirection: orderDirection,
            }
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/available_claims/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Token ${authToken}`,
                        },
                    }
                )
                .then((response) => {
                    resolve({
                        data: response.data.results,
                        page: page,
                        totalCount: response.data.count,
                    });
                })
                .catch((error) => {
                    reject(error);
                });
            })
          }
        options={options}
      />
    </>
  );
}