import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Modal, Button, ListGroup } from "react-bootstrap";
import MaterialTable from "material-table";
import { Input, TablePagination } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useInterval } from "../../hooks/useInterval";
import moment from "moment";
import parse from "html-react-parser";

export default function ExportEcrModal({
  show,
  handleClose,
  toExport,
  docTypeList
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [latestUpdate, setLatestUpdate] = useState({})

  const fetchLatestUpdate = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/get_most_recent_ecr_update/`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        setLatestUpdate(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  useEffect(() => {
    if(show){
        fetchLatestUpdate()
    }
  }, [show])

  useInterval(()=>{
    if(show){
        fetchLatestUpdate()
    }
  }, 180000) //3 min

  const columns = [
    { title: "VID", field: "pid.v_id",render: (rowData) => (
      <Link to={`/community/products/details/${rowData.pid?.product_id}`} className='text-decoration-underline'>
        {rowData?.pid?.v_id}
      </Link>
    ), },
    { title: "ID", field: "ecrid" },
    { title: "Title/Summary", field: "gist" },
    { title: "Full Comment", field: "cmnt", render: (rowData) => rowData?.cmnt && parse(rowData?.cmnt)},
    { title: "PP", field: "pp_id.pp_name"},
    { 
        title: "Document", 
        field: "dtid?.dtid?.doctype", 
        render: (rowData) => (
        <ListGroup>
          {docTypeList
            ?.filter((dt) => dt.ecrid__ecrid === rowData.ecrid)
            ?.map((dt, idx) => (
              <ListGroup.Item key={idx} disabled>
                {dt.dtid__doctype}
              </ListGroup.Item>
            ))}
        </ListGroup>
        )
    },
    { title: "Cite (Document/Page/Paragraph)", field: "cite" },
    { title: "Type", field: "type"},
    { title: "Rationale", field: "rationale", render: (rowData) => rowData?.rationale && parse(rowData?.rationale)},
    { title: "Requirement", field: "req" },
    { 
      title: "Most Recent Update", 
      field: "ecrid",
      render: (rowData) => {
          return latestUpdate[rowData?.ecrid] ? latestUpdate[rowData?.ecrid] : "";
      }
    },
    { title: "Date/Time of Update", field: "moddate", render: (rowData) => {
        return rowData?.moddate ? moment.utc(rowData?.moddate).format("MM/DD/YYYY [at] HHmm") : "";
    }},
    { title: "Status", field: "status" },
    // { title: "Assurance Activity"}
  ];

  const exportComments = () => {
        axios
        .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/excel_export/`, 
        toExport,
        {
            withCredentials: true,
            headers: { 
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}` 
            },
            responseType: "blob",
        })
        .then((response) => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = `ecr_comments_accessed_${moment().format('MM-DD-YYYY')}.xlsx`;

            a.click();

            window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error('Error exporting Excel file:', error);
        })
    handleClose();
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="me-2">Export ECR Comments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MaterialTable
          data={toExport}
          columns={columns}
          components={{
            Pagination: (props) => (
              <TablePagination
                {...props}
                labelRowsPerPage={
                  <label htmlFor="rowsPerPage">Rows Per Page</label>
                }
                SelectProps={{ input: <Input id="rowsPerPage" /> }}
              />
            ),
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>Cancel</Button>
        <Button
          type="button"
          variant="warning"
          onClick={() => exportComments()}
        >
          Export Comments
        </Button>
      </Modal.Footer>
    </Modal>
  );
}