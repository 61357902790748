import { useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Modal, Form, Container, Row, Col, Button } from "react-bootstrap";
import HTMLEditor from "../UI/HTMLEditor";

export default function SubmitEarModal({ show, handleClose, currentEar }) {
    const [cookies] = useCookies()
    let csrfToken = cookies['csrftoken']
    let authToken = cookies['auth_token']
    const currentUser = useSelector(state => state.user.value);
    const [editedEarFileLabel, setEditedEarFileLabel] = useState("Entropy Assessment Report (EAR)")
    const [earFiles, setEarFiles] = useState([])
    const [newEar, setNewEar] = useState({})
    const [enforceFocusModal, setEnforceFocusModal] = useState(true)

    const handleChange = (e) => {
        if (e.target.name === 'ear_file') {
            const newEarFiles = earFiles ? earFiles : []
            newEarFiles.push({
                file_type: 'ear-review',
                file_source_type: 'Product',
                file_display_name: 'Entropy Assessment Report (EAR)',
                uploaded_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                file_name: e.target.files[0].name,
                file_label: editedEarFileLabel,
                active: true,
                file: e.target.files[0],
                private: newEar.ear_file_public_status === "Public" ? false : true
            })
            setEarFiles([...newEarFiles])
        } else if (e.target.name === "ear_file_label") {
            setEditedEarFileLabel(e.target.value);
        } else {
            setNewEar({ ...newEar, [e.target.name]: e.target.value })
        }
        console.log('handleChange', newEar)
    }

    const handleAddEar = (e) => {
        e.preventDefault();
        const earData = new FormData()
        for (let f in earFiles) {
            earData.append('attachments_file', earFiles[f].file)
            earData.append('attachments_source_type', earFiles[f].file_source_type)
            earData.append('attachments_uploaded', earFiles[f].uploaded_on)
            earData.append('active', JSON.stringify(true))
            earData.append('file_label', earFiles[f].file_label)
            earData.append('private', earFiles[f].private)
        }
        earData.append('pid', currentEar?.pid?.product_id)
        earData.append('creator', currentUser?.id)
        earData.append('editor', currentUser?.id)
        earData.append('entrydate', new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())).toISOString())
        earData.append('status', 'Pending Review')

        axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/`,
            earData,
            {
                withCredentials: true, headers: {
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                if(newEar?.changes){
                    axios
                    .post(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
                    {
                        note: newEar?.changes,
                        submitted_by: currentUser.id,
                        note_type: 'ear-review',
                        note_type_id: response?.data?.earid,
                        submitted_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                    },
                    {
                        withCredentials: true,
                        headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                        },
                    }
                    )
                    .then((response) => {
                        console.log('note added')
                    })
                    .catch((error) => alert("Unable to add note: " + error));
                }

                axios.put(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${currentEar?.earid}/`,
                    {
                        pid: currentEar?.pid?.product_id,
                        active: false
                    },
                    {
                        withCredentials: true, headers: {
                            'X-CSRFToken': csrfToken,
                            'Authorization': `Token ${authToken}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => handleClose())
                    .catch(error => console.log("Unable to submit new product: " + error));
            })
            .catch(error => console.log("Unable to submit new product: " + error));
    }

    return (
        <Modal
            size="lg"
            show={show}
            onHide={() => {
                handleClose();
            }}
            enforceFocus={enforceFocusModal}
        >
            <Form onSubmit={handleAddEar}>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        <h3 className="text-bright-navy">
                            {
                                currentEar?.status === "Rejected - Pending Revision" ?
                                    "RESUBMIT" : "SUBMIT"
                            } EAR
                        </h3>
                        <h3 className="text-bright-navy">
                            VID {currentEar?.pid?.v_id} - {currentEar?.pid?.product_name}
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="mt-3">
                            <Row>
                                <p className='fw-bold form-title'>ATTACH EAR FILE</p>
                            </Row>
                            <Row>
                                <Col xl={6} sm={12}>
                                    <Form.Group className='text-start' controlId="ear_file_public_status">
                                        <Form.Label className='small text-secondary'>Document Visibility</Form.Label>
                                        <Form.Select name="ear_file_public_status" onChange={handleChange}>
                                            <option value=""></option>
                                            <option value="Public">Public</option>
                                            <option value="Proprietary">Proprietary</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xl={6} sm={12}>
                                    <Form.Group controlId="ear_file_label">
                                        <Form.Label className="small text-secondary">
                                            File Label
                                        </Form.Label>
                                        <Form.Control
                                            value={editedEarFileLabel}
                                            type="text"
                                            name="ear_file_label"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={8} sm={12} className='mt-2'>
                                    <Form.Group controlId='ear_file'>
                                        <Form.Label className='small text-secondary'>Attach EAR Files</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="ear_file"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Row>
                        <Row className="mt-5">
                            <Col>
                                <Form.Group>
                                    <Form.Label className="fw-bold form-title">SUMMARY OF CHANGES</Form.Label>
                                    <HTMLEditor
                                        name="changes"
                                        handleChange={handleChange}
                                        setIsValid={() => { }}
                                        isValid={true}
                                        setEnforceFocusModal={setEnforceFocusModal}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary" type="submit">
                        Submit
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}