import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import { useCookies } from "react-cookie";
import ReadOnlyProductForm from "../../Products/ProductManager/ProductForm/ReadOnlyProductForm";
import moment from "moment";
import StageTracker from "../StageTracker/StageTracker";
import { useInterval } from "../../../hooks/useInterval";
import VendorCheckOutModal from "../../Products/ProductManager/VendorCheckOutModal";
import CertificateModal from "../../Products/ProductManager/CertificateModal";
import qs from "qs";

const VendorActiveProductsTable = ({ setOverlayActive, setOverlayText }) => {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const csrfToken = cookies['csrftoken']
  const [currentProduct, setCurrentProduct] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [groupedCheckInFiles, setGroupedCheckInFiles] = useState({});
  const [progressPoints, setProgressPoints] = useState({})
  const [showCertificate, setShowCertificate] = useState(false)
  const [showROIModal, setShowROIModal] = useState(false)
  const tableRef = useRef();
  const defaultStatuses = [
    "Draft",
    "Proposed",
    "Submitted",
    "Pre-Evaluation",
    // "Evaluation",
    "In Evaluation",
    // "Validation",
    "In Progress",
    "Finalizing",
    // "Completion",
    "Assurance Maintenance",
    // "Active",
    // "Certified",
    // "Terminated",
    // "Withdrawn",
    // "Approved",
  ];

  const saveCertificate = async () => {
    if (currentProduct) {
      await axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/save_certificate/`,
          {
            product_id: currentProduct.product_id,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
        })
        .catch((error) => {
          console.log("error updating draft certificate", error.response.data);
        });
    }
  };

  const refetchProducts = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const productStatusOptions = {
    Draft: "Draft",
    Proposed: "Proposed",
    Submitted: "Submitted",
    "Pre-Evaluation": "Pre-Evaluation",
    Evaluation: "Evaluation",
    "In Evaluation": "In Evaluation",
    Validation: "Validation",
    "In Progress": "In Progress",
    Finalizing: "Finalizing",
    Completion: "Completion",
    "Assurance Maintenance": "Assurance Maintenance",
    Active: "Active",
    Certified: "Certified",
    Archived: "Archived",
    Terminated: "Terminated",
    Withdrawn: "Withdrawn",
    Approved: "Approved",
  };

  const getGroupedFiles = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_grouped_signed_check_in_files/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setGroupedCheckInFiles(response.data);
      })
      .catch((error) => {
        console.log("Error getting files");
      });
  }, []);

  useEffect(() => {
    getGroupedFiles();
  }, []);

  useInterval(() => {
    tableRef.current.onQueryChange();
    getGroupedFiles();
  }, 300000); //5 min

  const getProgressPoints = () => {
    axios
    .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/get_all_grouped_progress_points/`, {
      withCredentials: true,
      headers: {
        Authorization: `Token ${authToken}`,
      },
    })
    .then((response) => setProgressPoints(response.data))
    .catch((error) => console.log(error));
  }

  useEffect(() => {
    getProgressPoints();
  }, []);

  useInterval(() => {
    tableRef.current.onQueryChange();
    getProgressPoints();
  }, 300000); //5 min

  const daysPassed = (inputDate) => {
    if (inputDate) {
      const startDate = moment(inputDate);
      const endDate = moment();
      const daysSinceStartDate = endDate.diff(startDate, "days");
      return daysSinceStartDate;
    } else {
      return 0;
    }
  };

  const handleOpenModal = (data) => {
    setShowModal(true);
    setCurrentProduct(data);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const columns = [
    {
      title: "VID No.",
      field: "v_id",
      type: "numeric",
    },
    {
      title: "Product",
      field: "product_name",
    },
    {
      title: "Product Status",
      field: "status",
      // defaultFilter: defaultStatuses,
      // lookup: productStatusOptions,
      // render: (rowData) => {
      //   return (
      //     <div style={{ width: "max-content" }}>
      //       <OverlayTrigger
      //         trigger="click"
      //         placement="bottom"
      //         rootClose
      //         key="bottom"
      //         overlay={
      //           <Popover
      //             style={{
      //               minWidth: "200px",
      //               maxWidth: "600px",
      //               width: "max-content",
      //             }}
      //           >
      //             <Popover.Body>
      //               <StageTracker rowData={rowData} />
      //             </Popover.Body>
      //           </Popover>
      //         }
      //       >
      //         <Button
      //           variant="link"
      //           className="text-bright-navy small-font"
      //           style={{ width: "max-content" }}
      //         >
      //           {rowData?.status === "Certified" ? "Completed" : rowData?.status}
      //         </Button>
      //       </OverlayTrigger>
      //     </div>
      //   );
      // },
    },
    {
      title: "",
      render: (rowData) => (
        <div>
          {!groupedCheckInFiles[rowData?.product_id] &&
          !rowData.signature &&
          rowData?.pre_eval_status === "Proposed" ? (
            <Button size="sm" className="review" onClick={() => handleOpenModal(rowData)}>
              Review & Sign
            </Button>
          ) : progressPoints[rowData?.product_id]?.sort((a, b) => a?.progress_point - b?.progress_point)[6].status === 'Ready for Release' && (rowData?.vendor_checkout_signature === null || rowData?.vendor_checkout_signature === "") ? (
            <>
            {rowData?.vendors_cc_draft_cert_verifier === null &&
              <Button
                  size="sm" className="review bg-warning" style={{minWidth: 120}}
                  onClick={() => {setCurrentProduct(rowData); setShowCertificate(true)}}
              >
                  Review Draft Certificate
              </Button>
            }
              <br />
              <br />
              <Button 
                  size="sm" className="review bg-success" style={{minWidth: 120}}
                  onClick={() => {setCurrentProduct(rowData); setShowROIModal(true)}}
              >
                  Sign Release Form
              </Button>
            </>
          ) : (<></>)}
        </div>
      ),
    },
    {
      title: "Days in Progress",
      field: "check_in_date",
      render: (rowData) => <>{daysPassed(rowData?.kicked_off_date)}</>,
    },
    {
      title: "CCTL",
      field: "assigned_lab.name",
    },
    {
      title: "POC Name",
      field: "assigned_lab.poc",
    },
    {
      title: "POC Email",
      field: "assigned_lab.poc_email",
    },
    {
      title: "Submitted As",
      field: "submission_type",
    },
  ];

  const options = {
    sorting: true,
    search: false,
    columnsButton: true,
    padding: "dense",
    pageSize: 20,
  };

  return (
    <>
      <ResponsiveMaterialTable
        columns={columns}
        title="Active Products"
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } = query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };

            axios
              .get(
                `${
                  process.env.REACT_APP_DJANGO_ENDPOINT
                }project/product/vendor_active_products/?${qs.stringify(params, {
                  arrayFormat: "comma",
                })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                }
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
      />
      <ReadOnlyProductForm
        show={showModal}
        handleCloseModal={() => handleCloseModal(1)}
        setOverlayActive={setOverlayActive}
        setOverlayText={setOverlayText}
        product={currentProduct}
        refetch={refetchProducts}
        review={true}
      />
      <CertificateModal
          show={showCertificate}
          saveCertificate={saveCertificate}
          handleClose={() => setShowCertificate(false)}
          product={currentProduct}
          refetch={refetchProducts}
      />
      <VendorCheckOutModal 
          show={showROIModal} 
          handleClose={() => setShowROIModal(false)} 
          product={currentProduct} 
          setOverlayActive={setOverlayActive} 
          setOverlayText={setOverlayText} 
      />
    </>
  );
};

export default VendorActiveProductsTable;
