import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import documentTypes from "../Products/FileUpload/DocumentTypes.json"

export default function ReplaceFileModal({
  show,
  updateFiles,
  type,
  id,
  setShow,
  currFile,
}) {
  const [cookies] = useCookies();
  const csrfToken = cookies["csrftoken"];
  const authToken = cookies["auth_token"];
  const [file, setFile] = useState({});
  const [editedFileLabel, setEditedFileLabel] = useState("");
  const [validated, setValidated] = useState(false);
  const currentUser = useSelector((state) => state.user.value);
  
  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      const submittedData = new FormData();
      submittedData.append("file_type", type);
      submittedData.append("file_type_id", id);
      submittedData.append(`file`, file?.file);
      submittedData.append("file_display_name", file?.file_display_name);
      submittedData.append("file_name", file?.file?.name);
      submittedData.append("file_label", file?.file_label);
      submittedData.append("file_location", `/${type}/${id}/`);
      submittedData.append("file_mime_type", file?.file?.type);
      submittedData.append("uploaded_on", new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())).toISOString());
      submittedData.append("file_source_type", "Other");
      submittedData.append("active", JSON.stringify(true));
      submittedData.append("replaced", JSON.stringify(true));
      submittedData.append('replaced_by', currentUser.id)
      submittedData.append('replaced_on', new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())).toISOString())
      if (file.file_public_status === "Proprietary") {
        submittedData.append("private", JSON.stringify(true));
      }
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${currFile?.file_id}/`,
          submittedData,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          updateFiles()
          setShow(false)
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setValidated(true);
  };

  const handleChange = (e) => {
    if (e.target.name === "file") {
      setFile({ ...file, [e.target.name]: e.target.files[0] });
    } else if (e.target.name === "file_display_name") {
      setEditedFileLabel(e.target.value);
      setFile({
        ...file,
        [e.target.name]: e.target.value,
        file_label: e.target.value,
      });
    } else if (e.target.name === "file_label") {
      setEditedFileLabel(e.target.value);
      setFile({ ...file, [e.target.name]: e.target.value });
    } else {
      setFile({ ...file, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    setValidated(false)
    setFile(currFile);
    setEditedFileLabel(currFile?.file_label);
    setFile((prevFile) => ({
      ...prevFile,
      file_public_status: currFile?.private ? "Proprietary" : "Public",
    }));
  }, [show]);

  return (
    <Modal size="lg" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title as="h1">Replace: {file.file_name}</Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Container fluid>
            <Row className="pt-2">
              <Col xl={3} sm={12}>
                <Form.Group
                  className="text-start"
                  controlId="file_display_name"
                >
                  <Form.Label className="small text-secondary">
                    Type*
                  </Form.Label>
                  <Form.Select
                    name="file_display_name"
                    value={file?.file_display_name}
                    onChange={handleChange}
                    required
                  >
                    <option value=""></option>
                    {documentTypes[type]?.[currentUser?.user_role?.role_type]?.map((docType, idx) => (
                      <option key={idx} value={docType}>{docType}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={3} sm={12}>
                <Form.Group
                  className="text-start"
                  controlId="file_public_status"
                >
                  <Form.Label className="small text-secondary">
                    Document Visibility*
                  </Form.Label>
                  <Form.Select
                    name="file_public_status"
                    value={file?.file_public_status ?? ""}
                    onChange={handleChange}
                    required
                  >
                    <option value=""></option>
                    <option value="Public">Public</option>
                    <option value="Proprietary">Proprietary</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={5} sm={12}>
                <Form.Group
                  className="text-start"
                  controlId="file_label"
                  required
                >
                  <Form.Label className="small text-secondary">
                    File Label*
                  </Form.Label>
                  <Form.Control
                    value={editedFileLabel}
                    type="text"
                    name="file_label"
                    onChange={handleChange}
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xl={5} sm={12}>
                <Form.Group controlId="file">
                  <Form.Label className="small text-secondary">
                    Attach Files*
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={handleChange}
                    disabled={
                      !file.file_type ||
                      !file.file_public_status ||
                      !file.file_label
                    }
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button variant="warning" type="submit">
            Replace
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
