import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay-ts'
import { Container, Row, Col, Breadcrumb, Button } from "react-bootstrap";
import MemberPortalSubheader from "../Subheader/MemberPortalSubheader";
import AccountManager from "../AccountManager";
import OrganizationManager from "../OrganizationManager";
import Dashboard from "../Dashboard";
import ProductManager from "../Products/ProductManager";
import QA from '../QA/index'
import PPManager from "../ProtectionProfiles/PPManager";
import VirtTeams from "../VirtualTeams";
import TechnicalQueryManager from "../TRRTS/TRRTSManager";
import TechDecisionManager from "../TechnicalDecisions/Manager";
import Sidebar from "../Sidebar/Sidebar.js";
import EmailForm from "../EmailForm";
import { useCookies } from "react-cookie";
import ECRManager from "../ECR";
import EARManager from "../EAR";
import PCMManager from "../NIAPAdmin/PCM";
import MailingList from "../MailingList";
import DocumentManager from "../DocumentManager";
import ExternalLinksManager from "../NIAPAdmin/ExternalLinksManager";
import EventsManager from "../NIAPAdmin/EventsManager";
import AnnouncementsManager from "../NIAPAdmin/AnnouncementsManager"
import GlossaryManager from "../NIAPAdmin/GlossaryManager";
import NISTManager from "../NIST";
import FAQManager from "../NIAPAdmin/FAQs";
import LabgramsManager from "../NIAPAdmin/LabgramsManager";
import PolicyLettersManager from "../NIAPAdmin/PolicyLetters";
import DocSerialNumberManager from "../NIAPAdmin/DocSerialNumberManager";
import EvaluationCalendar from "../EvaluantionCalendar"
import TrainingCenter from "../NIAPAdmin/TrainingCenter/index.js";
import WarningIcon from "@mui/icons-material/Warning"
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import TrainingModal from "./TrainingModal/index.js";
import ReportsCenter from "./Reports/index.js";
import TechnicalCommunitiesManager from "../NIAPAdmin/TechnicalCommunitiesManager/index.js";

const Admin = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const currentUser = useSelector(state => state.user.value);
  const [overlayActive, setOverlayActive] = useState(false);
  const [overlayText, setOverlayText] = useState("");
  const permissions = useSelector(state => state.role.value);
  const [selectedDashBoard, setSelectedDashBoard] = useState("")
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showTrainingModal, setShowTrainingModal] = useState(false);

  if (!authToken) {
    window.location.pathname = "/login/";
  }

  useEffect(() => {
    if (permissions.role_type === "External") {
      window.location.pathname = "/";
    }
  }, []);

  useEffect(() => {
    if(permissions.role_type === "NIAP"){
      setSelectedDashBoard("NIAP")
    } else if (permissions.role_type === "Validator"){
      setSelectedDashBoard("Validator")
    } else if (permissions.role_type === "Vendor"){
      setSelectedDashBoard("Vendor")
    } else if (permissions.role_type === "Lab"){
      setSelectedDashBoard("Lab")
    } else if (permissions.role_type === "SME"){
      setSelectedDashBoard("SME")
    }
  }, [permissions])

  useEffect(() => {
    document.title = "NIAP - Community";
  }, []);
  
  const getSpecialNames = (row) =>{
    //TODO: all special cases with dashes would use another method

    switch (row){
      case "technicalqueries": 
        return "Technical Queries";
      case "protectionprofiles":
        return "Protection Profiles"
      case "virtualteams":
        return "Virtual Teams"
      case "pcm":
        return "PCM"
      case "nist":
        return "NIST Cert Reviews"     
      case "faqs":
        return "FAQs"        
      case "assurancemaintenance":
        return "Assurance Maintenance"
      case "product_files":
        return "Product Files"
      case "am_files":
        return "Assurance Maintenance Files"
      case "niap":
        return "NIAP"
      default:
        if (row.includes('-')){
          const dashedLinks = row.split('-')
          dashedLinks.map(( word, index) => {
            return dashedLinks[index] = word.charAt(0).toUpperCase() + word.slice(1);
          })
          return dashedLinks.join(" ")
        }
        const idNum = Number(row);
        if (idNum){
          return "ID: " + idNum
        }
        return row.charAt(0).toUpperCase() + row.slice(1);
    }
  }

  const getBreadURL = (url_path, name) =>{
    const sliceLength = url_path.findIndex(item => item === name);
    const newURL = url_path.slice(1, sliceLength+1).join('/');
    return newURL

  }

  const getBreadCrumb = () =>{
    const bPath = window.location.pathname.split('/');    
    // checks if the last path name is a '/' or 'eventlog' then remove the vid at end
    if (bPath[bPath.length -1] === '' || bPath[bPath.length -2] === 'eventlog'){
      bPath.pop();
    }
    // Exclude 'details' since it is not a page 
    // const urlPath = bPath.filter(item => !item.includes('details'));
    const urlPath = bPath
    // ECR has special breadcrumb
    if (urlPath.includes('ecr-comments')){
      return ;
    }
    
    return (
      <Breadcrumb>
        {urlPath.map((row, index) => {
          if (index === 0){
            return (
              <Breadcrumb.Item onClick={() => navigate('/')} key={index}>
                  Home
              </Breadcrumb.Item>
            )}
            else if (index < urlPath.length - 1 && row !== "details" && row !== "assurancemaintenance"){
              return (
                <Breadcrumb.Item key={index} onClick={() => 
                  { 
                    const test = getBreadURL(urlPath, row);
                    if (index === urlPath.length - 2){
                      return navigate(`/${test}`)
                    } else {
                      return navigate(`/${test}/`)
                    }
                  }}
                >
                  {getSpecialNames(row)}
                </Breadcrumb.Item>
              )      
            }
            else {
              return (
                <Breadcrumb.Item 
                  key={index} 
                  active
                >
                  {decodeURI(getSpecialNames(row))}
                </Breadcrumb.Item>
              ) 
            }
          }
        )}
      </Breadcrumb>
  )}

  // const getTrainingModal = () => {
  //   // this array will hold the pathnames that will have the training button, 
  //   // right now its just products until you add more

  //   // use this console.log to see the pathname
  //   // console.log(window.location.pathname)
  //   let linksWithTrainingButton = ["products/details"]
  //   if (linksWithTrainingButton?.some(link => window.location.pathname.includes(link))){
  //     return (
  //       <Button onClick={() => setShowTrainingModal(true)} variant="outline-dark" className="mt-1 mx-3">
  //         <ModelTrainingIcon />Training - Click Me to Learn More About This Process!
  //       </Button>
  //     )
  //   }
  // }

  if (!permissions?.role_type || permissions?.role_type === 'External' || !authToken) {
    return <Container>
      <Row>
        <Col>
          <h2><WarningIcon /> Unauthorized</h2>
        </Col>
      </Row>
    </Container>
  }

  return (
    <LoadingOverlay active={overlayActive} spinner text={overlayText} styles={{overlay: (base) => ({
      ...base,
      zIndex: 4000000
    })}}>
      <MemberPortalSubheader userName={currentUser?.first_name} selectedDashBoard={selectedDashBoard} />
        <Container fluid>
          <TrainingModal show={showTrainingModal} handleClose={() => setShowTrainingModal(false)} />
          <Row>
          <Col xs={sidebarOpen ? 7 : 2} md={sidebarOpen ? 2 : 1} className="px-0">
            <Sidebar setSelectedDashBoard={setSelectedDashBoard} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
          </Col>
          <Col xs={sidebarOpen ? 5 : 10} md={sidebarOpen ? 10 : 11}>
          <Row>
            <Col sm={12}>
            {permissions.role_type !== "Vendor" &&
              getBreadCrumb()
            }
            </Col>
            {/* <Col sm={12} md={4} className="d-flex justify-content-end">
              {getTrainingModal()}
            </Col> */}
          </Row>
            {authToken && (
              <Routes>
                <Route path="calendar" element={<EvaluationCalendar />} />
                <Route path="accounts/*" element={<AccountManager />} />
                <Route path="organizations/*" element={<OrganizationManager />} />
                <Route path="products/*" element={<ProductManager setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
                <Route path="protectionprofiles/*" element={<PPManager setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
                <Route path="question-answer/*" element={<QA />} />
                <Route path="virtualteams/*" element={<VirtTeams />} />
                <Route path="technicalqueries/*" element={<TechnicalQueryManager setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
                <Route path="technical-decisions/*" element={<TechDecisionManager />} />
                <Route path="ecr-comments/*" element={<ECRManager setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
                <Route path="ear-reviews/*" element={<EARManager setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
                <Route path="events-manager/*" element={<EventsManager />} />
                <Route path="announcements-manager/*" element={<AnnouncementsManager />} />
                <Route path="glossary-manager/*" element={<GlossaryManager />} />
                <Route path="documents/*" element={<DocumentManager setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
                <Route path="mailing-lists/*" element={<MailingList />} />
                <Route path="email" element={<EmailForm />} />
                <Route path="pcm/*" element={<PCMManager setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
                <Route path="faqs/*" element={<FAQManager />} />                
                <Route path="external-links/*" element={<ExternalLinksManager />} />
                <Route path="nist/*" element={<NISTManager setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
                <Route path="labgrams-manager/*" element={<LabgramsManager setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
                <Route path="policy-letters/*" element={<PolicyLettersManager />} />
                <Route path="document-serial-numbers/*" element={<DocSerialNumberManager />} />
                <Route path="training-center/*" element={<TrainingCenter />} />
                <Route path="technical-communities/*" element={<TechnicalCommunitiesManager />} />
                <Route path="reports/*" element={<ReportsCenter />} />
                <Route path="" element={<Dashboard setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} selectedDashBoard={selectedDashBoard} />} />
              </Routes>
            )}
          </Col>
          </Row>
        </Container>
    </LoadingOverlay>
  );
};

export default Admin;
