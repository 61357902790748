import { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios";
import { useCookies } from "react-cookie";
import FileDownload from "js-file-download";
import CctlCheckOutModal from './CctlCheckOutModal'
import CertificateModal from './CertificateModal'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import moment from "moment";

export default function PCLReviewDetails({ 
    product, 
    pcl,
    labSignedCheckout,
    securityTarget, 
    validationReport, 
    certificate, 
    assuranceActivityReport,
    protectionProfiles, 
    setOverlayActive,
    setOverlayText,
    refetch,
    saveCertificate
}) {
    const [cookies] = useCookies();
    const authToken = cookies["auth_token"];
    const [showModal, setShowModal] = useState(false)
    const [showCertificate, setShowCertificate] = useState(false)

    const downloadFile = (file) => {
        setOverlayText("Downloading")
        setOverlayActive(true)
        axios
            .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Token ${authToken}`,
                    },
                    responseType: "blob",
                }
            )
            .then((response) => {
                setOverlayActive(false)
                FileDownload(response.data, (file.file_new_name ? file.file_new_name : file.file_name));
            })
            .catch((error) => {
                console.log("Error");
            });
    };

    return (
        <Container className="mt-4 mb-4">
            <Row>
                <Col>
                    <Row className="mb-3">
                        <Col>
                            <h5 className="text-primary fw-bold">
                                PCL REVIEW DETAILS
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mx-3">
                        <Col className="m-auto" sm={12} md={6}>
                            <p className="m-0">
                                Validation Report Number:{" "}
                                {validationReport?.file_label}
                                <FileDownloadIcon
                                    className="mx-2 clickable"
                                    style={{ color: "var(--bs-green)" }}
                                    onClick={() =>
                                        downloadFile(validationReport)
                                    }
                                />
                            </p>
                        </Col>
                        <Col className="m-2">
                            <Row>
                            {Object.keys(labSignedCheckout).length > 0 ? 
                                <>
                                    <Col
                                        xs={12}
                                        sm={6}
                                        md={2}
                                        className="mt-1"
                                        style={{ width: "fit-content" }}
                                    >
                                        <p className="m-0">
                                            Release of Information:
                                        </p>
                                    </Col>
                                    <Col lg={1} className="p-2 m-auto clickable" style={{width: "fit-content"}}>
                                        <FontAwesomeIcon icon={faFilePdf} color='red' size='xl' onClick={() => {downloadFile(labSignedCheckout)}}/>
                                    </Col>
                                    <Col lg={1} className="p-2 m-auto" style={{width: "fit-content"}}>
                                        <CheckCircleIcon style={{ color: "var(--bs-green)" }} />
                                    </Col>
                                    <Col className="m-auto p-0" style={{textAlign: "center"}}>
                                        <p className="m-0 small-font">
                                            Approved By{" "}
                                            {labSignedCheckout?.uploaded_by?.first_name}{" "}
                                            {labSignedCheckout?.uploaded_by?.last_name}{" "}
                                            <br />
                                            {moment(labSignedCheckout?.uploaded_on).format(
                                                "MM/DD/YYYY"
                                            )}
                                        </p>
                                    </Col>
                                </>
                            :
                                <>
                                    <Col
                                        xs={12}
                                        sm={6}
                                        md={2}
                                        className="mt-1"
                                        style={{ width: "fit-content" }}
                                    >
                                        <p className="m-0">
                                            Release of Information:
                                        </p>
                                    </Col>
                                    <Col xs={12} sm={6} md={4}>
                                        <Button
                                            variant="warning"
                                            className="rounded-pill small-font"
                                            onClick={() => setShowModal(true)}
                                        >
                                            Review and Sign
                                        </Button>
                                    </Col>
                                </>
                            }
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mx-3">
                        <Col className="m-2">
                            <Row>
                            {product?.labs_cc_draft_cert_verifier !== null ?
                                <>
                                    <Col
                                        xs={12}
                                        sm={6}
                                        md={2}
                                        className="mt-1"
                                        style={{ width: "fit-content" }}
                                    >
                                        <p className="m-0">
                                            Draft CC Certificate:
                                        </p>
                                    </Col>
                                    <Col lg={1} className="px-2 m-auto" style={{width: "fit-content"}}>
                                        <CheckCircleIcon style={{ color: "var(--bs-green)" }} />
                                    </Col>
                                    <Col className="p-0 d-flex" style={{textAlign: "center"}}>
                                        <p className="m-0 small-font">
                                            Approved By{" "}
                                            {product?.labs_cc_draft_cert_verifier?.first_name}{" "}
                                            {product?.labs_cc_draft_cert_verifier?.last_name}{" "}
                                            <br />
                                            {moment(product?.labs_cc_draft_cert_date).format(
                                                "MM/DD/YYYY"
                                            )}
                                        </p>
                                    </Col>
                                </>
                            :
                                <>
                                    <Col
                                        xs={12}
                                        sm={6}
                                        className="mt-2"
                                        style={{ width: "fit-content" }}
                                    >
                                        <p className="m-0">Draft CC Certificate:</p>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Button
                                            variant="warning"
                                            className="rounded-pill small-font"
                                            onClick={() => setShowCertificate(true)}
                                        >
                                            Review Draft Certificate
                                        </Button>
                                    </Col>
                                </>
                            }
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <CctlCheckOutModal 
                show={showModal} 
                handleClose={() => setShowModal(false)} 
                product={product} 
                pcl={pcl} 
                security_target={securityTarget} 
                validation_report={validationReport} 
                certificate={certificate?.certificate} 
                assurance_activity_report={assuranceActivityReport}
                setOverlayActive={setOverlayActive} 
                setOverlayText={setOverlayText} 
            />
            <CertificateModal
                show={showCertificate}
                saveCertificate={saveCertificate}
                handleClose={() => setShowCertificate(false)}
                product={product}
                validationReport={validationReport}
                protectionProfiles={protectionProfiles} 
                refetch={refetch}
            />
        </Container>
    );
}
