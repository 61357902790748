import { Route, Routes } from "react-router-dom";
import NISTTable from "./NISTTable";
import NISTDetails from "./NISTDetails";
import UnauthorizedView from "../UI/UnauthorizedView"
import { useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { useCookies } from "react-cookie";
import axios from "axios";

const CheckPermNISTManager = ({setOverlayActive, setOverlayText}) => {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"]; 
    const [ncertMember, setNcertMember] = useState();
    const permissions = useSelector(state => state.role.value);
    const user = useSelector(state => state.user.value);

    useEffect(() => {
        if (user) {
          axios
            .get(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_ncrt_team_member?user=${user.id}`,
              {
                withCredentials: true,
                headers: { Authorization: `Token ${authToken}` },
              }
            )
            .then((response) => {
              setNcertMember(response.data.is_member);
            })
            .catch((err) => console.log("unable to get member's virtual teams", err));
        }
      }, [user]);

    if (permissions?.role_type === "NIAP" || permissions?.role_type === "Lab" || ncertMember === true) {
        return (
            <NISTManager setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
        )
    } else if (permissions?.role_type !== "NIAP" && ncertMember === false) {
        return (
            <UnauthorizedView />
        )
    }

    return (
        <div></div>
    )

}

const NISTManager = ({setOverlayActive, setOverlayText}) => {
    return (
        <Routes>
            <Route path="" element={<NISTTable />} />
            <Route path="details/:id" element={<NISTDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
        </Routes>
    )
}

export default CheckPermNISTManager