import { useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Container, Row, Col, Accordion, Button } from "react-bootstrap";
import HTMLEditor from "../../../UI/HTMLEditor";
import moment from "moment";
import "moment-timezone";
import parse from "html-react-parser";
import TechDecisionAuditModal from "../TechDecisionAuditModal";
import TDVoting from "./TDVoting";

export default function TDDetailsAccordions({
  techDecision,
  techDecisionHistory,
  refetch,
  currentUser,
  permissions,
}) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const [editedContent, setEditedContent] = useState({});
  const [auditOpen, setAuditOpen] = useState(false);
  const [auditType, setAuditType] = useState("");

  const openAudit = (type) => {
    setAuditType(type);
    setAuditOpen(true);
  };

  const closeAudit = () => {
    setAuditType("");
    setAuditOpen(false);
  };

  // for audit modal, for resetting votes or saving accordion field changes
  const saveTextFields = async (e, status) => {
    let data = {};
    if (status === "Reset Votes") {
      data = {
        voter_one: null,
        voter_two: null,
        voter_three: null,
        voter_four: null,
        voter_five: null,
        vote_one_date: null,
        vote_two_date: null,
        vote_three_date: null,
        vote_four_date: null,
        vote_five_date: null,
        vote_one_approve: null,
        vote_two_approve: null,
        vote_three_approve: null,
        vote_four_approve: null,
        vote_five_approve: null,
      };
    } else {
      data = {
        ...editedContent,
      };
    }
    if (editedContent?.resolution) {
      data["status"] = "TD Proposed";
    }
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/${techDecision?.td_id}/`,
        data,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then(() => {
        refetch();
        closeAudit();
      })
      .catch((error) => console.log("Unable to archive pp: " + error));
    setEditedContent({});
  };

  const handleChange = (e) => {
    const modifier = {};
    if (e.target.name === "justification") {
      modifier["justified_by"] = currentUser.id;
      modifier["justified_on"] = new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()));
      modifier["modified_by"] = currentUser.id;
      modifier["modified_on"] = new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()));
      setEditedContent({
        ...editedContent,
        ...modifier,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "resolution") {
      modifier["resolved_by"] = currentUser.id;
      modifier["resolved_on"] = new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()));
      modifier["modified_by"] = currentUser.id;
      modifier["modified_on"] = new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()));
      setEditedContent({
        ...editedContent,
        ...modifier,
        [e.target.name]: e.target.value,
      });
    } else {
      setEditedContent({
        ...editedContent,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handlePublish = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/${techDecision?.td_id}/`,
        {
          status: "Published",
          released_by: currentUser?.id,
          released_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
          publication_date: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then(() => refetch())
      .catch((error) => console.log("Unable to archive pp: " + error));
  };

  return (
    <>
      <TechDecisionAuditModal
        show={auditOpen}
        handleSave={{}}
        saveTextFields={saveTextFields}
        id={techDecision?.td_id}
        auditType={auditType}
        handleClose={closeAudit}
      />
      <Accordion defaultActiveKey={["1", "2", "3", "4"]} alwaysOpen flush>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="border-0">
            <h6 className="text-primary fw-bold">ISSUE DESCRIPTION</h6>
          </Accordion.Header>
          {permissions?.role_permissions?.find(
            (permission) => permission.area === "Technical Decisions"
          )?.update && techDecision?.status !== "Published" ? (
            <Accordion.Body>
              <Row>
                <HTMLEditor
                  name="description"
                  handleChange={handleChange}
                  setIsValid={() => {}}
                  isValid={true}
                  defaultValue={techDecision?.description}
                />
              </Row>
              {editedContent?.description && (
                <Row className="mt-2">
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="primary"
                      className="text-center d-inline-block"
                      onClick={() => openAudit("text")}
                    >
                      Save Changes
                    </Button>
                  </div>
                </Row>
              )}
            </Accordion.Body>
          ) : (
            <Accordion.Body>
              {techDecision?.description && parse(techDecision.description)}
            </Accordion.Body>
          )}
        </Accordion.Item>
        <Accordion.Item
          eventKey="2"
          className="border-top border-start-0 border-end-0 border-bottom-0 border-secondary"
        >
          <Accordion.Header className="border-0">
            <h6 className="text-primary fw-bold">RESOLUTION</h6>
          </Accordion.Header>
          {techDecision?.status === "Published" ||
          !permissions?.role_permissions?.find(
            (permission) => permission.area === "Technical Decisions"
          )?.update ? (
            <Accordion.Body>
              <Row>{techDecision?.resolution && parse(techDecision?.resolution)}</Row>
            </Accordion.Body>
          ) : (
            <Accordion.Body>
              <Row>
                {techDecision?.resolved_on && (
                  <Col xs={12} md={9}>
                    <p>
                      Last Updated By{" "}
                      <span className="fst-italic">
                        {techDecision?.resolved_by?.first_name}{" "}
                        {techDecision?.resolved_by?.last_name}{" "}
                      </span>{" "}
                      on{" "}
                      <span className="fst-italic">
                        {techDecision?.resolved_on &&
                          moment.utc(techDecision?.resolved_on).format("YYYY.MM.DD [at] HHmm")}
                      </span>
                    </p>
                  </Col>
                )}
                {techDecision?.status === "TD Proposed" && (
                  <TDVoting techDecision={techDecision} refetch={refetch} currentUser={currentUser}/>
                )}
              </Row>
              <Row>
                <HTMLEditor
                  name="resolution"
                  handleChange={handleChange}
                  setIsValid={() => {}}
                  isValid={true}
                  defaultValue={techDecision?.resolution}
                />
              </Row>
              <Row className="mt-3 mb-3 d-flex justify-content-end">
                {editedContent?.resolution && (
                  <Col xs={3} className="w-auto">
                    <Button
                      variant="primary"
                      className="text-center d-inline-block"
                      onClick={() => openAudit("text")}
                    >
                      Save Changes
                    </Button>
                  </Col>
                )}
                {permissions?.role_type === "NIAP" && techDecision?.vote_count?.total_votes > 0 && (
                  <Col xs={3} className="w-auto">
                    <Button
                      variant="danger"
                      className="text-center d-inline-block"
                      onClick={(e) => openAudit("reset")}
                    >
                      Reset Votes
                    </Button>
                  </Col>
                )}
                <Col xs={3} className="w-auto">
                  {techDecision?.status === "TD Proposed" && (
                    <Button
                      variant="success"
                      className="text-center d-inline-block"
                      onClick={handlePublish}
                      disabled={
                        //if not niap, needs 5 upvotes, else only 1 upvote
                        permissions?.role_type !== "NIAP"
                          ? techDecision?.vote_count?.true_votes < 5
                          : techDecision?.vote_count?.true_votes < 1
                      }
                    >
                      Publish
                    </Button>
                  )}
                </Col>
              </Row>
            </Accordion.Body>
          )}
        </Accordion.Item>
        <Accordion.Item
          eventKey="3"
          className="border-top border-start-0 border-end-0 border-bottom-0 border-secondary  "
        >
          <Accordion.Header>
            <h6 className="text-primary fw-bold">JUSTIFICATION</h6>
          </Accordion.Header>
          {techDecision?.status === "Published" ||
          !permissions?.role_permissions?.find(
            (permission) => permission.area === "Technical Decisions"
          )?.update ? (
            <Accordion.Body>
              {techDecision?.justification && parse(techDecision?.justification)}
            </Accordion.Body>
          ) : (
            <Accordion.Body>
              {techDecision?.justified_on && (
                <Row>
                  <p>
                    Last Updated By{" "}
                    <span className="fst-italic">
                      {techDecision?.justified_by?.first_name}{" "}
                      {techDecision?.justified_by?.last_name}{" "}
                    </span>{" "}
                    on{" "}
                    <span className="fst-italic">
                      {techDecision?.justified_on &&
                        moment.utc(techDecision?.justified_on).format("YYYY.MM.DD [at] HHmm")}
                    </span>
                  </p>
                </Row>
              )}
              <Row>
                <HTMLEditor
                  name="justification"
                  handleChange={handleChange}
                  setIsValid={() => {}}
                  isValid={true}
                  defaultValue={techDecision?.justification}
                  disabled={techDecision?.concurrence_approve?.length >= 5}
                />
              </Row>
              {editedContent?.justification && (
                <Row className="mt-2">
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="primary"
                      className="text-center d-inline-block"
                      onClick={() => openAudit("text")}
                    >
                      Save Changes
                    </Button>
                  </div>
                </Row>
              )}
            </Accordion.Body>
          )}
        </Accordion.Item>
        <Accordion.Item
          eventKey="5"
          className="border-top border-start-0 border-end-0 border-bottom-0 border-secondary  "
        >
          <Accordion.Header>
            <h6 className="text-primary fw-bold">HISTORY</h6>
          </Accordion.Header>
          <Accordion.Body>
            {techDecisionHistory?.map((history, idx) => (
              <Container key={idx}>
                <Row>
                  <Col className="text-start">
                    <h5>
                      Updated By: {history?.updated_by?.first_name} {history?.updated_by?.last_name}
                    </h5>
                  </Col>
                  <Col className="text-end">
                    <h5>
                      Updated On:{" "}
                      {history?.updated_on
                        ? moment.utc(history?.updated_on).format("YYYY.MM.DD [at] HHmm")
                        : "N/A"}
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5>Reason For Update</h5>
                    <p>{history?.reason_for_update}</p>
                  </Col>
                </Row>
              </Container>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
