import moment from "moment";

export default function VotingOverlay({ voteApprove, voter, date }) {
  return (
    voteApprove && (
      <p className="m-0 mb-2 border-bottom">
        {voter &&
          voter?.first_name +
            " " +
            voter?.last_name +
            ", " +
            voter?.user_role?.role_name +
            ", at " +
            moment.utc(date).format("MM/DD/YYYY hh:mm A")}
      </p>
    )
  );
}
