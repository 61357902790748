import axios from "axios";
import { useCookies } from "react-cookie";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import ERTApproveRejectModal from "./ERTApproveRejectModal";
import { useInterval } from "../../../hooks/useInterval";
import qs from "qs";
import Filter from "../../EAR/Filter.js";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export default function EARReviewsTable({ refetch, filter, setEarCount }) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const earTableRef = useRef();
  const user = useSelector((state) => state.user.value);
  const permissions = useSelector((state) => state.role.value);
  const [review, setReview] = useState({});
  const [showERTModal, setShowERTModal] = useState(false);
  const [ERTMember, setERTMember] = useState(false);
  const [completeView, setCompleteView] = useState(false);
  const [filterOpen, setFilterOpen] = useState(true);
  const [sidebarFilters, setSidebarFilters] = useState({ status: "Pending Review" });

  useEffect(() => {
    if (earTableRef.current) {
      earTableRef.current.onQueryChange();
    }
  }, [sidebarFilters, refetch]);

  useInterval(() => {
    earTableRef.current.onQueryChange();
  }, 300000); //5 min

  const refetchTable = () => {
    earTableRef.current.onQueryChange();
  };

  const handleCloseCompleteTerminateModal = () => {
    setCompleteView(false);
    setShowERTModal(false);
  };

  const changeFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const getERTUsers = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_ert_team_member/?user=${user.id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => {
        if (response?.data?.is_member === true) {
          setERTMember(true);
        } else {
          setERTMember(false);
        }
      })
      .catch((err) => console.log("unable to get member's virtual teams", err));
  }, []);

  useEffect(() => {
    getERTUsers();
  }, [user.id, getERTUsers]);

  useInterval(() => {
    getERTUsers();
  }, 300000); //5 min

  const columns = [
    {
      title: "ID",
      field: "earid",
      type: "numeric",
      headerStyle: {position: "sticky", left: 0},
      cellStyle: {
        width: "7%",
        position: "sticky",
        left: 0,
        zIndex: 99
      },
      render: (rowData) => (
        <Link
          to={`/community/ear-reviews/details/${rowData?.earid}`}
          className="text-decoration-underline"
        >
          {rowData?.earid}
        </Link>
      ),
    },
    {
      title: "VID No.",
      field: "pid.v_id",
      render: (rowData) => <>VID{rowData?.pid?.v_id}</>,
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Agent",
      field: "agent",
      render: (rowData) => (
        <>
          {rowData?.agent?.first_name} {rowData?.agent?.last_name}
        </>
      ),
    },
    {
      title: "Submitted",
      field: "entrydate",
      type: "date",
    },
    {
      title: "Deadline",
      field: "due",
      type: "date",
    },
    {
      title: "Escalated to ERT",
      hidden: !ERTMember,
      render: (rowData) => rowData?.escalated && <CheckCircleIcon color="danger" />,
    },
    {
      title: "Actions",
      headerStyle: {background: "white", position: "sticky", right: 0},
      cellStyle: {background: "white", position: "sticky", right: 0},
      render: (rowData) => (
        <Container>
          <Row>
            <Col>
              <Link
                to={`/community/ear-reviews/details/${rowData?.earid}`}
                className="text-decoration-none"
              >
                <Button size="sm" className="rounded-pill small-font pt-0 pb-0 mb-2">
                  View Details
                </Button>
              </Link>
            </Col>
            {ERTMember && rowData?.status === "Pending Review" && (
              <>
                <Col>
                  <Button
                    size="sm"
                    variant="success"
                    className="rounded-pill small-font pt-0 pb-0 mb-2"
                    onClick={() => {
                      setReview(rowData);
                      setShowERTModal(true);
                      setCompleteView(true);
                    }}
                  >
                    Complete
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="sm"
                    variant="danger"
                    className="rounded-pill small-font pt-0 pb-0"
                    onClick={() => {
                      setReview(rowData);
                      setShowERTModal(true);
                    }}
                  >
                    Terminate
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Container>
      ),
    },
  ];

  const options = {
    sorting: true,
    search: false,
    columnsButton: true,
    pageSize: 20,
  };

  return (
    <>
      <ERTApproveRejectModal
        show={showERTModal}
        handleClose={handleCloseCompleteTerminateModal}
        review={review}
        refetch={filter ? refetchTable : refetch}
        user={user}
        complete={completeView}
      />
      {filter && (
        <Row className="mt-3 justify-content-end">
          <Col sm={12} md={2} className="d-flex justify-content-end align-content-center mb-2">
            <Button
              variant="link rounded-pill"
              style={{ backgroundColor: "#dedede", maxHeight: 40 }}
              onClick={changeFilter}
            >
              {filterOpen && (
                <>
                  <KeyboardArrowRightIcon />
                  <KeyboardArrowRightIcon />
                  &nbsp; Close Filter &nbsp;
                  <KeyboardArrowRightIcon />
                  <KeyboardArrowRightIcon />
                </>
              )}
              {!filterOpen && (
                <>
                  <KeyboardArrowLeftIcon />
                  <KeyboardArrowLeftIcon />
                  &nbsp; Open Filter &nbsp;
                  <KeyboardArrowLeftIcon />
                  <KeyboardArrowLeftIcon />
                </>
              )}
            </Button>
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col xs={filterOpen && filter ? 2 : 12} md={filterOpen && filter ? 9 : 12}>
          <ResponsiveMaterialTable
            title="EAR Reviews"
            columns={columns}
            tableRef={earTableRef}
            data={(query) =>
              new Promise((resolve, reject) => {
                // Extract the necessary information from the query object
                const { page, pageSize, search, filters, orderBy, orderDirection } = query;
                let newFilters = filters.map((filter) => {
                  let value = "";
                  if (Array.isArray(filter.value)) {
                    value = filter.value;
                  } else {
                    value = filter.value.replace(/['"]+/g, "");
                  }
                  return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                });

                const params = {
                  ...sidebarFilters,
                  offset: page * pageSize,
                  limit: pageSize,
                  search: search,
                  filters: newFilters,
                  orderBy: orderBy?.field,
                  orderDirection: orderDirection,
                };

                if(permissions?.role_type === "Lab" || permissions?.role_permissions?.find(permission => permission.area === 'EAR Reviews')?.local_only){
                  params["local_only"] = true;
                }

                if(permissions?.role_type === "SME"){
                  params["escalated"] = true;
                }

                axios
                  .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/?${qs.stringify(params, {
                      arrayFormat: "comma",
                    })}`,
                    {
                      withCredentials: true,
                      headers: {
                        Authorization: `Token ${authToken}`,
                      },
                    }
                  )
                  .then((response) => {
                    if(setEarCount){
                      setEarCount(response.data.count);
                    }
                    resolve({
                      data: response.data.results,
                      page: page,
                      totalCount: response.data.count,
                    });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            }
            options={options}
          />
        </Col>
        {filterOpen && filter && (
          <Col xs={10} md={3}>
            <Filter filters={sidebarFilters} setFilters={setSidebarFilters} />
          </Col>
        )}
      </Row>
    </>
  );
}
