import {
    Row,
    Col,
    Stack,
    Form,
    Container,
    Button,
    Popover,
    OverlayTrigger,
    Alert
} from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import ReviewAMModal from "../../../Dashboard/AMTableModule/ReviewAMModal";
import { useInterval } from "../../../../hooks/useInterval";
import DeleteModal from "../../../UI/DeleteModal";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@mui/icons-material/Save";

export default function AMHeader({
    product,
    maintenance,
    refetch,
    progressPoints,
    setAlert,
    setOverlayActive,
    setOverlayText
}) {
    const [cookies] = useCookies();
    const authToken = cookies["auth_token"];
    let csrfToken = cookies["csrftoken"];
    const permissions = useSelector((state) => state.role.value);
    const [showAMRequestModal, setShowAMRequestModal] = useState(false);
    const [iar, setIAR] = useState({})
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const [editedAm, setEditedAM] = useState({})
    const [isReject, setIsReject] = useState(false)
    const [isDisapproved, setIsDisapproved] = useState(false)
    
    // const handleAlertAndMailer = () => {
    //     let recipients = {cc: ["Validators", "NIAP Management", "CCTL ETAs", "AM Approver"]}
    //     if(maintenance?.lab_request !== null){
    //         recipients = {...recipients, to: ["Lab"]}
    //     } else {
    //         recipients = {...recipients, to: ["Vendor"]}
    //     }
    //     axios 
    //         .post( 
    //         `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/send_alert_and_mail/`,
    //         { 
    //             subject: `VID${product?.v_id} – IAR Denial`,
    //             recipients: recipients,
    //             alert_type: "Product",
    //             alert_text: `Assurance Maintenance for VID${product?.v_id} Denied.`,
    //             alert_type_id: product?.product_id,
    //             alert_source: "Assurance Maintenance"
    //         },
    //         {
    //             withCredentials: true,
    //             headers: {
    //             "X-CSRFToken": csrfToken,
    //             Authorization: `Token ${authToken}`,
    //             },
    //         })
    //         .then((response) => {
                
    //         })
    //         .catch((error) =>
    //             console.log("Unable to create alert and email.", error)
    //         );
    // }


    const handleRemoveFromAM = () => {
        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${maintenance?.product?.product_id}/`,
                {
                    assigned_lab: product?.am_initial_cctl?.org_id,
                    am_initial_cctl: null,
                    submission_type: "Evaluation",
                    status: "Certified",
                    current_assurance_maintenance: null
                },
                {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then((response) => {
                axios
                .put(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${maintenance?.maintenance_id}/`,
                    {
                        status: "Terminated",
                        completion_date: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))
                    },
                    {
                        withCredentials: true,
                        headers: {
                            "X-CSRFToken": csrfToken,
                            Authorization: `Token ${authToken}`,
                        },
                    }
                )
                .then((response) => {
                    refetch();
                    setIsDisapproved(false)
                    setShowDeleteModal(false)
                    setAlert({message: "Successfully Removed from Assurance Maintenance!", variant: "success"})
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
    }
    const handleRejectAM = () => {
        axios
        .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${maintenance?.maintenance_id}/`,
            {
                status: "Rejected"
            },
            {
                withCredentials: true,
                headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                },
            }
        )
        .then((response) => {
            // handleAlertAndMailer()
            refetch();
            setIsReject(false)
            setShowDeleteModal(false)
            setAlert({message: "Successfully Rejected Assurance Maintenance!", variant: "success"})
        })
        .catch((error) => console.log(error));
    }


    const loadMaintenanceFiles = useCallback(()=>{
        if (maintenance?.maintenance_id) {
            axios
                .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_files_by_display_name_and_file_type_and_type_id/?file_display_name=Impact Analysis Report (IAR)&file_type=assurance-maintenance&file_type_id=${maintenance?.maintenance_id}`, {
                    withCredentials: true,
                    headers: { Authorization: `Token ${authToken}` },
                })
                .then((response) => {
                    setIAR(response.data[0]);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [maintenance?.maintenance_id])

    useEffect(() => {
        loadMaintenanceFiles()
    }, [loadMaintenanceFiles])

    useInterval(()=>{
        loadMaintenanceFiles()
    }, 180000) //3 min

    function refreshPage() {
        window.location.reload(false);
    }

    const getDaysInProgress = (date) => {
        const startDate = moment(date);
        let endDate = moment();
        if(maintenance?.completion_date !== null){
            endDate = moment(maintenance?.completion_date)
        }
        const difference = endDate.diff(startDate, "days");
        return difference;
    };

    //update product with new assurance maintenance date
    const submitDataChange = (date) => {
        axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
                {
                    assurance_maintenance_date: date,
                },
                {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then((response) => {
                refetch();
            })
            .catch((error) => console.log(error));
    };

    const handleCloseAMRequestModal = () => {
        setShowAMRequestModal(false);
    }
    const handleChange = (e) =>{
        setEditedAM({...editedAm,[e.target.name]: e.target.value})
    }
    const handleSave = () => {
    if (Object.keys(editedAm).length > 0) {
         axios
            .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${maintenance?.maintenance_id}/`,
            { ...editedAm },
            {
                withCredentials: true,
                headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
                },
            }
            )
            .then((response) => {
                setAlert({message:"New Maintenance Name Succesfully Saved", variant:"success"})
                setIsEditing(false)
                refetch()
            })
            .catch((error) => console.log("Unable to update product: " + error));
        }
    }

    return (
        <Container>
            <DeleteModal
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                type={"Assurance Maintenance"}
                deleteFunction={isDisapproved? handleRemoveFromAM : handleRejectAM}
                isDisapproved={isDisapproved}
                isReject={isReject}
            />
            <ReviewAMModal
                show={showAMRequestModal}
                handleCloseModal={handleCloseAMRequestModal}
                product={product}
                refetch={refetch}
                postToPCLView={true}
                setOverlayActive={setOverlayActive} 
                setOverlayText={setOverlayText} 
            />
            <Row>
                <Col sm={12} lg={7}>
                    <h3 className="fw-bold text-primary">
                        ASSURANCE MAINTENANCE DETAILS:{" "}
                    </h3>
                </Col>
                {(
                    progressPoints[3]?.status === "In Progress" &&
                    permissions?.role_type === "NIAP"
                ) &&
                    <>
                        <Col md={12} lg={3}>
                            <Form.Group className="mb-3" controlId="assurance_maintenance_date">
                                <Form.Label className="small-font fw-bold">
                                    Select Date for Assurance Maintenance:
                                </Form.Label>
                                <Form.Control
                                    style={{ width: "fit-content" }}
                                    type="date"
                                    name="assurance_maintenance_date"
                                    defaultValue={product?.assurance_maintenance_date
                                        ?.toString()
                                        .substring(0, 10)}
                                    onChange={(e) => submitDataChange(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12} lg={2} className="m-auto">
                            <OverlayTrigger 
                                trigger={["hover", "hover"]}
                                rootClose 
                                disabled
                                placement='bottom' 
                                key='bottom'
                                overlay={ 
                                    maintenance?.maintenance_description !== null &&
                                    maintenance?.change_summary !== null &&
                                    product?.assurance_maintenance_date !== null 
                                    ?
                                    <span></span>
                                    :
                                    <Popover>
                                        <Popover.Body>
                                            <p className="m-0">
                                            Before Posting to PCL, please make sure to include an updated 
                                            Maintenance Description, Change Summary, and date 
                                            for Assurance Maintenance.
                                            </p>
                                        </Popover.Body>
                                    </Popover>
                                }>
                                <div style={{width: "fit-content"}}>
                                    <Button
                                        size="md"
                                        variant="warning"
                                        disabled={
                                            maintenance?.maintenance_description === null ||
                                            maintenance?.change_summary === null ||
                                            product?.assurance_maintenance_date === null
                                        }
                                        onClick={() => setShowAMRequestModal(true)}
                                    >
                                        Post to PCL
                                    </Button>
                                </div>
                            </OverlayTrigger>
                        </Col>
                    </>
                }
            </Row>
            <Row>
                <Col sm={6} className="mt-3">
                    <h3 className="fw-bold">{product?.vendor_id?.name}</h3>
                    <h4 className="fw-bold text-info">VID {product?.v_id}</h4>
                </Col>
                <Col sm={6} className="mt-3 d-flex flex-column align-items-center">
                    <Stack className="border-start border-secondary ps-2 my-2">
                        {!isEditing && permissions.role_type === "NIAP"  &&
                            <div className="d-flex justify-content-end">
                                <EditIcon
                                color="action"
                                className="clickable"
                                style={{ fontSize: "1.5rem" }}
                                onClick={() => setIsEditing(true)}
                                />
                            </div>
                         }
                        {isEditing && (
                            <div className="d-flex justify-content-end">
                                <SaveIcon
                                    color="action"
                                    className="clickable"
                                    style={{ fontSize: "1.5rem" }}
                                    onClick={() => handleSave()}
                                />
                            </div>
                        )}                   
                        { isEditing ? (
                            <>
                                <Form.Label>Release: </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    onChange={handleChange}
                                    defaultValue={maintenance?.title}
                                />
                            </>
                        ) :<h4 className="fw-bold">Release: {maintenance?.title}</h4> }
                    </Stack>                    
                </Col>
                <Col md={2} className="border-dark border-bottom border-top">
                    <Stack className="border-secondary ps-2 my-2">
                        <h5>Days In AM</h5>
                        {maintenance?.iar_received ?
                            <h5 className="text-secondary">{getDaysInProgress(maintenance?.iar_received)}</h5>
                            :
                            <h5 className="text-secondary">0</h5>
                        }
                        <h5 className="text-secondary">
                        </h5>
                    </Stack>
                </Col>
                <Col md={3} className="border-dark border-bottom border-top">
                    <Stack className="border-start border-secondary ps-2 my-2">
                        <h5>IAR Received:</h5>
                        {maintenance?.iar_received ?
                            <h5 className="text-secondary">{moment.utc(maintenance?.iar_received).format('MM/DD/YYYY')}</h5>
                            :
                            <h5 className="text-secondary">IAR Not Uploaded</h5>
                        }
                        <h5 className="text-secondary">
                        </h5>
                    </Stack>
                </Col>
                <Col className="border-dark border-bottom border-top">
                    <Stack className="border-start border-secondary ps-2 my-2">
                        <h5>AM Status</h5>
                        <h5 className="text-secondary">{maintenance?.status==="Terminated" ? "Disapproved" : maintenance?.status}</h5>
                        {permissions?.role_type === 'NIAP' && product?.status === 'In Assurance Maintenance' &&
                        <Button variant="warning" className="rounded-pill small-font p-1 mt-2 mb-2" onClick={() => {
                            setIsDisapproved(true)
                            setShowDeleteModal(true)
                            }}>
                            Remove From Assurance Maintenance
                        </Button>
                        }
                        {permissions?.role_type === 'NIAP' && maintenance?.status === 'Pending' &&
                        <Button variant="danger" className="rounded-pill small-font p-1 mt-2 mb-2" onClick={() =>{ 
                            setIsReject(true)
                            setShowDeleteModal(true)
                        }}>
                            Reject Assurance Maintenance
                        </Button>
                        }
                    </Stack>
                </Col>
                <Col className="border-dark border-bottom border-top">
                    <Stack className="border-start border-secondary ps-2 my-2">
                        <h5>{maintenance?.status==="Terminated" ? "Disapproved on ":"Completed"}</h5>

                        <h5 className="text-secondary">
                            {maintenance?.completion_date
                                ? moment.utc(maintenance?.completion_date).format("MM/DD/YYYY")
                                : "TBD"}
                        </h5>
                    </Stack>
                </Col>
            </Row>
        </Container>
    );
}