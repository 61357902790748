import { Modal, Container, Row, Col, Form, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useState } from "react";
import axios from "axios";
import moment from "moment"

export default function ItcInformationModal({
  show,
  handleClose,
  technicalQuery,
  setAlertInfo
}) {
  const [cookies] = useCookies();
  const csrfToken = cookies["csrftoken"];
  const authToken = cookies["auth_token"];
  const [newInfo, setNewInfo] = useState({});

  const handleChange = (e) => {
    setNewInfo({ ...newInfo, [e.target.name]: e.target.value });
  };

  const handleSave = (e) => {
    if(Object.keys(newInfo).length > 0){
        axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${technicalQuery?.tq_id}/`,
          { ...newInfo, recaptcha_token: "member_portal", 
            modified_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))
           },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
            console.log("doing this 2")
          setAlertInfo({message:"Itc Information Succesfully Saved", variant:"success"})
        })
        .catch((error) => {
          console.log("Error");
        });
    }
    handleClose();

  };

  let teamStatusTypes = [
    "Not Applicable",
    "Needs To Be Sent",
    "Sent For Resolution",
    "Resolved",
  ];

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title as="h3" className="text-primary">
          Edit iTC Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row className="pt-2">
            <Form.Group className="text-start" controlId="date_sent">
              <Form.Label className="small text-secondary">
                Interpretation Team Status:
              </Form.Label>

              <Form.Select
                name="team_status"
                onChange={handleChange}
                defaultValue={
                  technicalQuery?.team_status
                    ? technicalQuery?.team_status
                    : null
                }
              >
                <option value=""></option>
                {teamStatusTypes.map((status, idx) => (
                  <option value={status} key={idx}>
                    {status}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="text-start" controlId="date_sent">
              <Form.Label className="small text-secondary">
                Date Sent
              </Form.Label>
              <Form.Control
                type="date"
                name="date_sent"
                defaultValue={  moment
                    .utc(technicalQuery?.date_sent)
                    .format("YYYY-MM-DD")}
                onChange={handleChange}
              ></Form.Control>
            </Form.Group>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
