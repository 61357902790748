import { useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Modal, Button, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import {handleAlertAndMailer} from '../Products/Helper/functions';

export default function EAREditForm({
    show,
    handleClose,
    review,
    refetch,
    setAlert,
}) {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const [update, setUpdate] = useState({ ...review });
    const [note, setNote] = useState("");
    const currentUser = useSelector((state) => state.user.value);
    const [showWarning, setShowWarning] = useState(false);

    const handleChange = (e, tier3) => {
        if (tier3 !== undefined) {
            if (tier3 === true) {
                setUpdate({ ...update, apriori: true, tier3: true }); //tier3 and apriori is true when additional review required
            } else {
                setUpdate({ ...update, apriori: true, tier3: false }); //apriori is true and tier3 is false when additional review NOT required
            }
        } else if (e.target.name === "apriori" && tier3 === undefined) {
            //if tier3 is undefined, then apriori and tier3 are false
            setUpdate({ ...update, apriori: false, tier3: false });
        } else if (e.target.name === "reqd") {
            setUpdate({
                ...update,
                [e.target.name]: e.target.value === "true",
            });
        } else {
            setUpdate({ ...update, [e.target.name]: e.target.value });
        }
    };

    const handleUpdate = (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (!form.checkValidity()) {
            e.stopPropagation();
        } else {
            const data = {
                ...update,
                pid: review.pid.product_id,
                agent: review?.agent?.id,
                escalated: true,
                release_date: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                status: "Pending Review",
                due: new Date(moment().add(7, 'days').format('YYYY-MM-DD')),
                response: review?.response?.upid ?? null,
                escalator: currentUser?.id,
            };
            axios
                .put(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${review?.earid}/`,
                    data,
                    {
                        withCredentials: true,
                        headers: {
                            "X-CSRFToken": csrfToken,
                            Authorization: `Token ${authToken}`,
                        },
                    }
                )
                .then((response) => {
                    if (note !== "") {
                        handleAddNote();
                    }
                    handleAlertAndMailer(
                        csrfToken, 
                        authToken, 
                        {
                            subject: `EAR Review ${response.data?.earid} for VID ${response.data?.pid} - Pending Review`,
                            recipients: {to: ["ERT Team"], cc: ["NIAP Management"]},
                            alert_type: "EAR",
                            alert_text: `EAR Review ${response.data?.earid} for VID ${response.data?.pid} - Escalated to ERT Team.`,
                            alert_type_id: response.data?.earid,
                        }
                    )
                    refetch();
                    handleClose();
                    setAlert({
                        message: "EAR Escalated to ERT!",
                        type: "success",
                    });
                })
                .catch((error) => {
                    console.log(error);
                    setAlert({
                        message: "Error encountered escalating EAR to ERT.",
                        type: "danger",
                    });
                });
        }
    };

    const handleAddNote = () => {
        axios
            .post(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
                {
                    note: note,
                    submitted_by: currentUser?.id,
                    submitted_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                    note_type: "ear-review",
                    note_type_id: review?.earid,
                },
                {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then((response) => {})
            .catch((error) => console.log("Unable to add note: " + error));
    };

    return (
        <Modal
            size="lg"
            show={show}
            onHide={() => {
                handleClose();
                setNote("");
            }}
        >
            <Form onSubmit={handleUpdate}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        RELEASE EAR {review?.earid} TO ERT TEAM
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="mb-3">VID {review?.pid?.v_id}</h3>
                    <Row>
                        <Form.Group controlId="reqd">
                            <Form.Label className="fw-bold">
                                EAR Required for Evaluation?
                            </Form.Label>
                            <Form.Check
                                type="radio"
                                label="Yes"
                                name="reqd"
                                defaultChecked={review?.reqd === true}
                                value={true}
                                onChange={handleChange}
                                id="reqd1"
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                name="reqd"
                                defaultChecked={review?.reqd === false}
                                value={false}
                                onChange={handleChange}
                                id="reqd2"
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="my-4" controlId="apriori">
                            <Form.Label className="fw-bold">
                                Was this Report Approved for a Prior Evaluation?
                            </Form.Label>
                            <Form.Check
                                type="radio"
                                label="Yes, but additional review by the ERT required"
                                name="apriori"
                                defaultChecked={
                                    review?.apriori === true &&
                                    review?.tier3 === true
                                } //tier3 is true when additional review required
                                onChange={(e) => {
                                    handleChange(e, true);
                                }}
                                id="apriori2"
                            />
                            <Form.Check
                                type="radio"
                                label="Yes, and no additional review required"
                                name="apriori"
                                defaultChecked={
                                    review?.apriori === true &&
                                    review?.tier3 === false
                                } //tier3 is false when additional NOT review required
                                onChange={(e) => {
                                    handleChange(e, false);
                                }}
                                id="apriori1"
                            />
                            <Form.Check
                                type="radio"
                                label="No or unknown"
                                name="apriori"
                                defaultChecked={
                                    review?.apriori === false &&
                                    review?.tier3 === false
                                }
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                id="apriori3"
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="note">
                            <Form.Label className="fw-bold">
                                Justification for Re-Use of an EAR or
                                Non-Applicability of an EAR:
                                {update?.apriori === true ? "*" : ""}
                            </Form.Label>
                            <Form.Control
                                required={
                                    update?.apriori === true ? true : false
                                }
                                name="just"
                                onChange={handleChange}
                                defaultValue={review?.just}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group
                            className="mb-3"
                            controlId="escalation_comment"
                        >
                            <Form.Label className="fw-bold">Comment</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={6}
                                name="escalation_comment"
                                onChange={(e) => setNote(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {showWarning ? (
                        <>
                            <p>Are you sure you want to Release to ERT?</p>
                            <Button
                                variant="success"
                                type="submit"
                                className="mx-4"
                            >
                                YES, Submit
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() => {
                                    setShowWarning(false);
                                }}
                            >
                                No, Cancel
                            </Button>
                        </>
                    ) : (
                        <div className="mx-4">
                            <Button
                                variant="light"
                                type="button"
                                className="mx-4"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                onClick={() => setShowWarning(true)}
                            >
                                Release
                            </Button>
                        </div>
                    )}
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
