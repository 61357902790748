import { Accordion, Container, Row, Col } from "react-bootstrap";
import moment from "moment";

export default function TechDecisionSideInfo({ techDecision }) {
    const showApprovalVotes = (vote) => {
        let approve_fields = [
            "vote_one_approve",
            "vote_two_approve",
            "vote_three_approve",
            "vote_four_approve",
            "vote_five_approve",
        ];

        let date_fields = [
            "vote_one_date",
            "vote_two_date",
            "vote_three_date",
            "vote_four_date",
            "vote_five_date",
        ];

        let voter_fields = [
            "voter_one",
            "voter_two",
            "voter_three",
            "voter_four",
            "voter_five",
        ];

        return approve_fields.map((field, idx) => {
            if (techDecision[field] === vote) {
                return (
                    <Row key={idx}>
                        <p>
                            {techDecision[date_fields[idx]] &&
                                moment(techDecision[date_fields[idx]]).format(
                                    "YYYY.MM.DD [at] HHmm"
                                )}{" "}
                            by
                            <br></br>
                            {techDecision[voter_fields[idx]]?.first_name}{" "}
                            {techDecision[voter_fields[idx]]?.last_name}{", "}
                            {techDecision[voter_fields[idx]]?.user_role?.role_name}
                        </p>
                    </Row>
                );
            }
            return null;
        });
    };

    return (
        <div className="mt-3">
            <Accordion flush className="border border-dark mb-3">
                <Accordion.Item eventKey="1">
                    <Accordion.Header className="bg-secondary">
                        <h6 className="sidebar-title">Modification History</h6>
                    </Accordion.Header>
                    <Accordion.Body className="bg-light p-1">
                        <Container>
                            <Row>
                                <Col>
                                    <Row>
                                        <p className="text-secondary">
                                            Record Created:
                                        </p>
                                    </Row>
                                    <Row className="border-bottom">
                                        <p>
                                            {techDecision?.created_on &&
                                                moment(
                                                    techDecision?.created_on
                                                ).format(
                                                    "YYYY.MM.DD [at] HHmm"
                                                )}{" "}
                                            by
                                            <br></br>
                                            {
                                                techDecision?.created_by
                                                    ?.first_name
                                            }{" "}
                                            {techDecision?.created_by?.last_name}{" "}
                                        </p>
                                    </Row>
                                    <Row>
                                        <p className="text-secondary">
                                            Last Modified:
                                        </p>
                                    </Row>
                                    <Row className="border-bottom">
                                        {techDecision?.modified_by && (
                                            <p>
                                                {techDecision?.modified_on &&
                                                    moment(
                                                        techDecision.modified_on
                                                    ).format(
                                                        "YYYY.MM.DD [at] HHmm"
                                                    )}{" "}
                                                by
                                                <br></br>
                                                {
                                                    techDecision?.modified_by
                                                        ?.first_name
                                                }{" "}
                                                {
                                                    techDecision?.modified_by
                                                        ?.last_name
                                                }{" "}
                                            </p>
                                        )}
                                    </Row>
                                    <Row>
                                        <p className="text-secondary">
                                            Published Date:
                                        </p>
                                    </Row>
                                    <Row>
                                        {techDecision?.released_by && (
                                            <p>
                                                {techDecision?.released_on &&
                                                    moment(
                                                        techDecision.released_on
                                                    ).format(
                                                        "YYYY.MM.DD [at] HHmm"
                                                    )}{" "}
                                                by
                                                <br></br>
                                                {
                                                    techDecision?.released_by
                                                        ?.first_name
                                                }{" "}
                                                {
                                                    techDecision.released_by
                                                        ?.last_name
                                                }{" "}
                                            </p>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion flush className="border border-dark mb-3">
                <Accordion.Item eventKey="1">
                    <Accordion.Header className="bg-secondary">
                        <h6 className="sidebar-title">Approvals</h6>
                    </Accordion.Header>
                    <Accordion.Body className="bg-light p-1">
                        <Container>
                            <Row>
                                <p className="text-secondary">Votes for YES:</p>
                            </Row>
                            {showApprovalVotes(true)}
                            <Row className="border-top">
                                <p className="text-secondary">Votes for NO:</p>
                            </Row>
                            {showApprovalVotes(false)}
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}
