import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import { Button, Stack } from "react-bootstrap";
import moment from "moment";
import { useState} from "react";
import ReviewAMModal from "./ReviewAMModal";
import axios from "axios";
import qs from 'qs'
import {useCookies} from "react-cookie";

export default function AMTable({ refetch, tableRef, setOverlayActive, setOverlayText }) {
  const [cookies] = useCookies()
  let authToken = cookies['auth_token']
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});

  const handleOpenModal = (data) => {
    setShowReviewModal(true);
    setCurrentProduct(data);
  };

  const handleCloseModal = () => {
    setShowReviewModal(false);
    refetch()
  };

  const daysPassed = (inputDate) => {
    if (inputDate) {
      const startDate = moment(inputDate);
      const endDate = moment();
      const daysSinceStartDate = endDate.diff(startDate, "days");
      return daysSinceStartDate;
    } else {
      return 0;
    }
  };

  const amColumns = [
    {
      title: "Submitted On",
      render: (rowData) => {
        if(rowData?.current_assurance_maintenance.lab_request_date){
          return moment.utc(rowData?.current_assurance_maintenance.lab_request_date).format("MM/DD/YYYY")
        } else if (rowData?.current_assurance_maintenance.vendor_request_date){
          return moment.utc(rowData?.current_assurance_maintenance.vendor_request_date).format("MM/DD/YYYY")
        } else {
          return <>No Date</>
        }
      }
    },
    {
      title: "VID",
      render: (rowData) => rowData.v_id,
    },
    {
      title: "Product Name",
      render: (rowData) => rowData.product_name,
    },
    {
      title: "CCTL",
      render: (rowData) => rowData.assigned_lab.name,
    },
    {
      title: "Requested By",
      render: (rowData) => {
        if(rowData?.lab_request !== null){
          return rowData?.current_assurance_maintenance?.lab_request?.org?.name
        } else {
          return rowData?.current_assurance_maintenance?.vendor_request?.org?.name
        }
      }
    },
    // {
    //   title: "Due Date",
    //   render: (rowData) => {
    //     if(rowData?.current_assurance_maintenance?.team_due_date){
    //       return moment.utc(rowData?.current_assurance_maintenance?.team_due_date).format("MM/DD/YYYY")
    //     } else {
    //       return <>No Due Date Set</>
    //     }
    //   }
    // },
    // {
    //   title: "Product Status",
    //   field: "status",
    //   render: (rowData) => (rowData.status === 'Certified' ? "Completed" : rowData.status)
    // },
    // {
    //   title: "AM Status",
    //   field: "current_assurance_maintenance.status"
    // },
    // {
    //   title: "Days in AM",
    //   render: (rowData) => daysPassed(rowData?.current_assurance_maintenance?.validator_approval_date),
    // },
    {
      title: "",
      cellStyle: {width: "10%", minWidth: "10%"},
      render: (rowData) => (
        <Stack>
        {rowData?.current_assurance_maintenance?.status === "Requested" ?
          <Button
            size="sm"
            className="rounded-pill"
            onClick={() => handleOpenModal(rowData)}
          >
            Review
          </Button>
          :
          <Button
            size="sm"
            href={`/community/products/details/${rowData?.product_id}/assurancemaintenance/${rowData.current_assurance_maintenance?.maintenance_id}`}
            className="rounded-pill"
          >
            View
          </Button>
        }
        </Stack>
      ),
    },
  ];

  const options = {
    sorting: true,
    columnsButton: true,
  };

  return (
    <>
      <ResponsiveMaterialTable
        title="AM Requests"
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const {
                page,
                pageSize,
                search,
                filters,
                orderBy,
                orderDirection,
            } = query;

            let newFilters = filters.map((filter) => {
                let value = ""
                if (Array.isArray(filter.value)) {
                    value = filter.value
                } else {
                    value = filter.value.replace(/['"]+/g, '')
                }
                return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
                offset: page * pageSize,
                limit: pageSize,
                search: search,
                filters: newFilters,
                orderBy: orderBy?.field,
                orderDirection: orderDirection,
            }
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/niap_am/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Token ${authToken}`,
                        },
                    }
                )
                .then((response) => {
                    resolve({
                        data: response.data.results,
                        page: page,
                        totalCount: response.data.count,
                    });
                })
                .catch((error) => {
                    reject(error);
                });
            })
          }
        options={options}
        columns={amColumns}
      />
      <ReviewAMModal
        show={showReviewModal}
        handleCloseModal={() => handleCloseModal()}
        product={currentProduct}
        refetch={refetch}
        setOverlayActive={setOverlayActive}
        setOverlayText={setOverlayText}
      />
    </>
  );
}
