import { useEffect, useState, useCallback, useRef } from "react";
import { Button, ListGroup, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import ResponsiveMaterialTable from "../../../UI/MaterialTable/ResponsiveMaterialTable";
import { MTableActions } from "material-table";
import { useCookies } from "react-cookie";
import axios from "axios";
import moment from "moment";
import FileDownload from 'js-file-download';
import { useSelector } from "react-redux";
import { useInterval } from "../../../../hooks/useInterval";
import AddEarForm from "./AddEarForm";
import qs from 'qs'


export default function EARDetailsAccordion({id, setOverlayActive, setOverlayText}) {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"];
    const permissions = useSelector((state) => state.role.value);
    const [groupedEarFiles, setGroupedEarFiles] = useState({});
    const [showButton, setShowButton] = useState(false);
    const [currentEar, setCurrentEar] = useState(true)
    const [show, setShow] = useState(false)
    const tableRef = useRef()

    const isValidatorOrEvaluatorProductId = useCallback(()=>{
        if (id) {
          axios
            .get(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/is_validator_or_evaluator/?product=${id}`,
              {
                withCredentials: true,
                headers: { Authorization: `Token ${authToken}` },
              }
            )
            .then((response) => {
              if(permissions?.role_type === 'NIAP' || response.data?.is_validator_or_evaluator){
                setShowButton(true)
              } else {
                setShowButton(false)
              }
            })
            .catch((error) => {
              console.log('error getting project personnel: ', error)
            });
    }
    }, [id])

    const onShow = () => {
        setShow(true)
    }

    const onHide = () => {
        setShow(false)
        getEarFiles()
        tableRef.current.onQueryChange()
    }

    useEffect(() => {
        isValidatorOrEvaluatorProductId()
      }, [isValidatorOrEvaluatorProductId]);

    const getEarFiles = useCallback(() => {
        if (id) {
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/get_grouped_ear_files/?product_id=${id}`,
                    {
                        withCredentials: true,
                        headers: { Authorization: `Token ${authToken}` },
                    }
                )
                .then((response) => {
                    setGroupedEarFiles(response.data);
                })
                .catch((error) => {
                    setGroupedEarFiles({});
                });
        }
    }, [id]);

    useEffect(() => {
        getEarFiles();
    }, [getEarFiles]);

    useInterval(()=>{
        tableRef.current.onQueryChange()
        getEarFiles();
    }, 180000) //3 min

    useEffect(() => {
        tableRef.current.onQueryChange()
    }, [currentEar, id])

    const downloadFile = (file) => {
        setOverlayText("Downloading")
        setOverlayActive(true)
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
            {
                withCredentials: true, 
                headers: {
                    'Authorization':  `Token ${authToken}`,
                },
                responseType: 'blob'
            }).then((response) => {
                setOverlayActive(false)
                FileDownload(response.data, (file.file_new_name ? file.file_new_name : file.file_name))
            }).catch((error) => {
                console.log('Error')
            })
      }

    const columns = [
        {
            title: "EAR ID",
            field: "earid",
            cellStyle: { width: '10%', minWidth: '10%' },
        },
        {
            title: "Submitted On",
            field: "entrydate",
            render: (rowData) =>
                moment.utc(rowData?.entrydate).format("MM/DD/YYYY"),
        },
        {
            title: "Completion Date",
            field: "enddate",
            render: (rowData) =>
                rowData?.enddate ? (
                    moment.utc(rowData?.enddate).format("MM/DD/YYYY")
                ) : (
                    <>TBD</>
                ),
        },
        {
            title: "Status",
            field: "status",
        },
        {
            title: "Files",
            render: (rowData) => {
                return <ListGroup className="custom-list-group">
                { groupedEarFiles && groupedEarFiles[rowData?.earid]?.map((file, idx) => (
                  <OverlayTrigger
                  key={idx}
                  placement="top"
                  overlay={<Tooltip>{file.file_label}</Tooltip>}
                >
                    <ListGroup.Item onClick={() => downloadFile(file)} className='text-decoration-underline'>
                      {file.file_label}
                    </ListGroup.Item>
                </OverlayTrigger>
                ))}
              </ListGroup>
            },
        },
        {
            title: "",
            cellStyle: { width: "10%", minWidth: "10%" },
            hidden: !showButton,
            render: (rowData) => (
                <Button
                    variant="link"
                    className="text-bright-navy"
                    href={`/community/ear-reviews/details/${rowData?.earid}`}
                    size="sm"
                >
                    View Details
                </Button>
            ),
        },
    ];

    const options = {
        sorting: true,
        search: false,
        columnsButton: true,
        padding: "dense",
        pageSize: 5
    };

    return (
        <>
            <ResponsiveMaterialTable
                columns={columns}
                title={`${currentEar ? "Current" : "Past"} EAR Reviews`}
                tableRef={tableRef}
                data={(query) =>
                    new Promise((resolve, reject) => {
                    // Extract the necessary information from the query object
                    const {
                        page,
                        pageSize,
                        search,
                        filters,
                        orderBy,
                        orderDirection,
                    } = query;
        
                    let newFilters = filters.map((filter) => {
                        let value = ""
                        if (Array.isArray(filter.value)) {
                            value = filter.value
                        } else {
                            value = filter.value.replace(/['"]+/g, '')
                        }
                        return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                    });
                    const params = {
                        offset: page * pageSize,
                        limit: pageSize,
                        search: search,
                        filters: newFilters,
                        orderBy: orderBy?.field,
                        orderDirection: orderDirection,
                        product_id: id,
                        // current_ear: currentEar
                    }
                    axios
                        .get(
                            `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/current_product_ears/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                            {
                                withCredentials: true,
                                headers: {
                                    Authorization: `Token ${authToken}`,
                                },
                            }
                        )
                        .then((response) => {
                            resolve({
                                data: response.data.results,
                                page: page,
                                totalCount: response.data.count,
                            });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                    })
                    }
                options={options}
                components={{
                    Actions: props => <span>
                        <MTableActions {...props} />
                        {/* <span className="ps-2">
                            <Form.Check 
                                type="switch"
                                inline
                                onChange={() => {setCurrentEar(!currentEar)}}
                                label="Show Past EAR?"
                                checked={currentEar !== true}
                            />
                        </span> */}
                    </span>
                }}
                actions={[
                    {
                    icon: 'add',
                    tooltip: 'Add EAR Review',
                    isFreeAction: true,
                    onClick: onShow
                    }
                ]}
            />
            <AddEarForm show={show} onHide={onHide} product_id={id} getEarFiles={getEarFiles} />
        </>
    );
}
