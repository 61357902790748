import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import FileDownload from "js-file-download";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import parse from 'html-react-parser'
import { handleAlertAndMailer } from "../../Products/Helper/functions"

export default function ReviewAMModal({
  show,
  handleCloseModal,
  product,
  refetch,
  postToPCLView,
  setOverlayActive,
  setOverlayText
}) {
  const [cookies] = useCookies();
  const [files, setFiles] = useState([]);
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const user = useSelector((state) => state.user.value);
  const permissions = useSelector((state) => state.role.value);
  const [maintenance, setMaintenance] = useState({});
  const [maintenanceUpdates, setMaintenanceUpdates] = useState({})
  const [openRejection, setOpenRejection] = useState(false);
  const [protectionClaims, setProtectionClaims] = useState([]);

  function refreshPage() {
    window.location.reload(false);
  }

  const handleChange = (e) => {
    setMaintenanceUpdates({...maintenanceUpdates, [e.target.name]: e.target.value})
  }

  useEffect(() => {
    setOpenRejection(false);
  }, [show])

  const getMaintenance = (maintenance_id) => {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${maintenance_id}/`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
          setMaintenance(response.data);
        })
        .catch((error) => console.log(error));
  };
  
  const getFiles = (maintenance_id) => {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_files_by_type_and_file_source_type_and_type_id/?file_type=assurance-maintenance&file_source_type=Assurance+Maintenance&file_type_id=${maintenance_id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          }
        )
        .then((response) => {
          setFiles(response.data.filter((f) => (f.active = true)));
        })
        .catch((error) => setFiles([]));
  }

  const getPPs = (product_id) => {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/protection_profiles_by_name/?product=${product_id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          }
        )
        .then((response) => {
          setProtectionClaims(response.data);
        })
        .catch((error) => console.log(error));
  }

  useEffect(() => {
    if(show){
      if(product.current_assurance_maintenance.maintenance_id){
        getMaintenance(product.current_assurance_maintenance.maintenance_id)
        getFiles(product.current_assurance_maintenance.maintenance_id)
        getPPs(product.product_id)
      }
    }
  }, [show, product])

  const downloadFile = (file) => {
    setOverlayText("Downloading")
    setOverlayActive(true)
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        setOverlayActive(false)
        FileDownload(response.data, (file.file_new_name ? file.file_new_name : file.file_name));
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  const handleCertifyAlertMail = () => {
    let recipients = {cc: ["Validators", "NIAP Management", "CCTL ETAs", "AM Approver"]}
    if(maintenance?.lab_request !== null){
        recipients = {...recipients, to: ["Lab"]}
    } else {
        recipients = {...recipients, to: ["Vendor"]}
    }
    handleAlertAndMailer(
      csrfToken, 
      authToken, 
      {
        alert_type_id: product?.product_id,
        alert_type: "Product",
        alert_source: "Assurance Maintenance",
        subject: `VID${product?.v_id} – IAR Concurrence`,
        recipients: recipients,
        alert_text: `Assurance Maintenance for VID${product?.v_id} Posted.`
      }
    )
  }

  const handleNIAPReviewAlertMail = (message, subject) => {
    let recipients = {to: ["Validator Resource Team"], cc: ["NIAP Management"]}

    handleAlertAndMailer(
      csrfToken, 
      authToken, 
      {
        alert_type_id: product?.product_id,
        alert_type: "Product",
        alert_source: "Assurance Maintenance",
        subject: subject,
        recipients: recipients,
        alert_text: message
      }
    )
  }

  const handleUpdateProgress = (product_id) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
        {
          maintenance: maintenance?.maintenance_id,
          progress_point: 1,
          status: "Completed",
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        if(response.data.status === 'Update Already Exists'){
          refreshPage();
        } else {
          handleCloseModal(true, product_id);
        }
      })
      .catch((error) => console.log(error));
  }

  const handleCertifyAM = () => {
    axios
        .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
            {
                maintenance: maintenance?.maintenance_id,
                progress_point: 4,
                status: "Completed",
            },
            {
                withCredentials: true,
                headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                },
            }
        )
        .then((response) => {
            if (response.data.status === "Update Already Exists") {
                refreshPage();
            } else {
                handleCertifyAlertMail();
                axios
                    .put(
                        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
                        {
                            assigned_lab: product?.am_initial_cctl?.org_id,
                            am_initial_cctl: null,
                            submission_type: "Evaluation",
                            status: "Certified",
                            current_assurance_maintenance: null,
                        },
                        {
                            withCredentials: true,
                            headers: {
                                "X-CSRFToken": csrfToken,
                                Authorization: `Token ${authToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        axios
                          .put(
                              `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${maintenance?.maintenance_id}/`,
                              {
                                  ...maintenanceUpdates,
                                  status: "Approved",
                                  agent: user.id,
                                  niap_approval_date: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))
                              },
                              {
                                  withCredentials: true,
                                  headers: {
                                      "X-CSRFToken": csrfToken,
                                      Authorization: `Token ${authToken}`,
                                  },
                              }
                          )
                          .then((response) => {
                              refetch()
                              handleCloseModal()
                          })
                          .catch((error) => {
                              console.log(error);
                          });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        })
        .catch((error) => console.log(error));
  }

  const updateAMProduct = () => {
    axios
      .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product.product_id}/`,
          {
              status: "In Assurance Maintenance",
          },
          {
              withCredentials: true,
              headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
              },
          }
      )
      .then((response) => {
          console.log('update product')
      })
      .catch((error) => {
          console.log(error);
      });
  }

  const handleSubmit = (approved) => {
    let message = "";
    let subject = "";
    let data = maintenanceUpdates;
    //if NIAP release or changes needed
    if(!postToPCLView && permissions?.role_type === 'NIAP'){
      if (approved) {
        message = `Request for Assurance Maintenance for VID ${product.v_id} has been Released by NIAP.`;
        subject = `Assurance Maintenance Request Has Been Release by NIAP`;
        data = {
          ...data,
          status: "Released",
        };
      } else {
        // message = `Request for Assurance Maintenance for VID ${product.v_id} Requires Changes.`;
        // subject = `Assurance Maintenance Request Requires Changes`;
        data = {
          ...data,
          "status": "Rejected",
          "rejection_rationale": maintenanceUpdates?.rejection_rationale
        };
      }
    } else { //if validator
      // message = `Request for Assurance Maintenance for VID ${product.v_id} has been Approved.`;
      // subject = `Assurance Maintenance Request Has Been Approved by Validators`;
      data = {
        "status": "Pending",
        "validator_approval_date": new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
      }
    }
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${maintenance?.maintenance_id}/`,
        data,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        //if approved by validator, updated progress 1 as completed;
        if(approved && permissions?.role_type === 'Validator'){
          handleUpdateProgress(response.data.product);
        } else {
          if (approved) {
            updateAMProduct();
          }
          if(message !== "" && subject !== ""){
            handleNIAPReviewAlertMail(message, subject);
          }
          handleCloseModal();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showFooterButtons = () => {
    if(postToPCLView){
      return (
        <div className="d-flex justify-content-end">
          <Button variant="outline-dark" className="me-2" onClick={() => handleCloseModal()}>
            Cancel
          </Button>
          <Button onClick={handleCertifyAM}>Post ACMR Addendum</Button>
        </div>
      )
    } else if (!postToPCLView && permissions?.role_type === 'NIAP'){
      return (
        <div className="d-flex justify-content-end">
          <Button variant="outline-dark" className="me-2" onClick={() => handleCloseModal()}>
            Cancel
          </Button>
          <Button
          type="submit"
          variant="danger"
          className="me-2"
          onClick={() => setOpenRejection(true)}
          >
            Changes Needed
          </Button>
          <Button onClick={() => handleSubmit(true)}>Release</Button>
        </div>
      )
    } else {
      return (
        <div className="d-flex justify-content-end">
          <Button variant="outline-dark" className="me-2" onClick={() => handleCloseModal()}>
            Cancel
          </Button>
          {permissions?.role_type !== 'Validator' && //if not validator, show reject button
            <Button
            type="submit"
            variant="danger"
            className="me-2"
            onClick={() => setOpenRejection(true)}
            >
              Reject
            </Button>
          }
          <Button onClick={() => handleSubmit(true)}>{permissions?.role_type === 'Validator' ? "Assign Validation Team" : "Approve for AM"}</Button>
        </div>
      )
    }
  }

  return (
    <Modal
      show={show}
      onHide={() => handleCloseModal()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>+ASSURANCE MAINTENANCE REQUEST:{" "}
            <span className="text-primary">VID {product?.v_id}</span>
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <h5 className="fw-bold form-title mb-2">EVALUATION INFORMATION</h5>

          <Row className="mt-3">
            <Col>
              <Row>
                <Form.Group className="mb-3" controlId="product_name">
                  <Row>
                    <Col sm={4}>
                      <Form.Label column>Product Name:</Form.Label>
                    </Col>
                    <Col>
                      <Form.Control
                        plaintext
                        name="product_name"
                        defaultValue={product?.product_name}
                        readOnly
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3" controlId="certification_date">
                  <Row>
                    <Col sm={4}>
                      <Form.Label>Certification Date:</Form.Label>
                    </Col>
                    <Col className="m-0">
                      <Form.Control
                        plaintext
                        className="p-0"
                        defaultValue={moment.utc(product?.certification_date).format("MM/DD/YYYY")}
                        readOnly
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3" controlId="expiration_date">
                  <Row>
                    <Col sm={4}>
                      <Form.Label>Expiration Date:</Form.Label>
                    </Col>
                    <Col>
                      <Form.Control
                        plaintext
                        className="p-0"
                        defaultValue={product?.assurance_maintenance_date && moment(product?.assurance_maintenance_date).format('MM/DD/YYYY')}
                        readOnly
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3" controlId="pp_claim">
                  <Row>
                    <Col sm={4}>
                      <Form.Label>Protection Profile Claims:</Form.Label>
                    </Col>
                    <Col>
                      {protectionClaims?.map((pp, idx) => (
                        <OverlayTrigger
                          key={idx}
                          placement="top"
                          overlay={<Tooltip>{pp.pp__pp_name}</Tooltip>}
                        >
                          <div key={idx} style={{width: 'max-content'}}>{pp.pp__pp_short_name}</div>
                        </OverlayTrigger>
                      ))}
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
            </Col>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="summary_of_changes">
              <Row>
                <Col>
                  <Form.Label>Notes on Changes:</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col className="px-4">
                  <p>{maintenance?.maintenance_release && parse(maintenance?.maintenance_release)}</p>
                </Col>
              </Row>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            {files?.length > 0 && (
              <div className="border">
                <p className="mx-3 mt-2">Attached Files:</p>
                {files?.map((file, idx) => (
                  <Row className="m-3 d-flex justify-content-around" key={idx}>
                    <Col>
                      <Button
                        variant="link"
                        className="text-bright-navy text-decoration-underline"
                        onClick={() => downloadFile(file)}
                      >
                        {file.file_display_name}
                      </Button>
                    </Col>
                    <Col sm={4}>{file.file_label}</Col>
                    <Col sm={4}>
                          {file.private ? "Proprietary" : "Public"}
                    </Col>
                  </Row>
                ))}
              </div>
            )}
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="title">
              <Form.Label>
                Maintenance Release:
              </Form.Label>
              <Form.Control
                type="text"
                name="title"
                readOnly={permissions?.role_type === "Validator"}
                defaultValue={
                  product?.current_assurance_maintenance?.title ? 
                  product?.current_assurance_maintenance?.title :
                    `${product?.product_name}`
                  }
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          {/* <Row className="mb-3">
            <Form.Group controlId="completion">
              <Form.Label>
                Completion Date:
              </Form.Label>
              <Form.Control
                type="date"
                name="completion_date"
                readOnly={permissions?.role_type === "Validator"}
                onChange={handleChange}
              />
            </Form.Group>
          </Row> */}
          <Row className="mb-3"> 
            <Col>
                Requested By:
            </Col>
            <Col>
                {maintenance?.vendor_request ? `${maintenance?.vendor_request?.first_name}  
                ${maintenance?.vendor_request?.last_name}` :
                `${maintenance?.lab_request?.first_name}  ${maintenance?.lab_request?.last_name}` }
            </Col>
          </Row>
          {!postToPCLView &&
          <Row>
            <Form.Group controlId="team_due_date">
              <Form.Label>
                Validation Team Assignment Due Date:
              </Form.Label>
              <Form.Control
                    className="mb-3 w-50"
                    type="date"
                    name="team_due_date"
                    readOnly={permissions?.role_type === "Validator"}
                    onChange={handleChange}
                    defaultValue={
                    maintenance?.team_due_date && 
                      moment
                      .utc(maintenance?.team_due_date)
                      .format("YYYY-MM-DD")
                    }
                  />
            </Form.Group>
          </Row>
          }
          <Row className="mt-3">
            <h5 className="fw-bold form-title mb-2">Vendor Information</h5>
          </Row>
          <Row className="mt-3">
            <Form.Group className="mb-3" controlId="vendor_name">
              <Row>
                <Col sm={2}>
                  <Form.Label>Vendor:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    plaintext
                    className="p-0"
                    defaultValue={product?.vendor_id?.name}
                    readOnly
                  />
                </Col>
              </Row>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="vendor_poc">
              <Row>
                <Col sm={2}>
                  <Form.Label>Vendor POC:</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    plaintext
                    className="p-0"
                    defaultValue={product?.vendor_poc}
                    readOnly
                  />
                </Col>
              </Row>
            </Form.Group>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {showFooterButtons()}
        {openRejection &&
          <Container>
          <Row>
            <Form.Group className="mt-4">
              <Form.Label as="h4">
                {!postToPCLView && permissions?.role_type === 'NIAP' ? 
                  "Changes Requested" 
                  : 
                  "Rationale For Rejection"
                }
              </Form.Label>
              <Form.Control
                rows={3}
                as="textarea"
                name="rejection_rationale"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <div className="d-flex justify-content-end mt-3">
            <Button className="" onClick={() => handleSubmit(false)}>Send</Button>
          </div>
          </Container>
        }
      </Modal.Footer>
    </Modal>
  );
}
