import axios from "axios";
import { useCookies } from "react-cookie";
import { Col, OverlayTrigger, Popover } from "react-bootstrap";
import award_zero_img from "../../../../images/tq_td_awards/award_icon_zero.svg";
import award_one_img from "../../../../images/tq_td_awards/award_icon_one.svg";
import award_two_img from "../../../../images/tq_td_awards/award_icon_two.svg";
import award_three_img from "../../../../images/tq_td_awards/award_icon_three_yellow.svg";
import award_four_img from "../../../../images/tq_td_awards/award_icon_four.svg";
import award_five_img from "../../../../images/tq_td_awards/award_icon_five.svg";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import VotingOverlay from "../../../TRRTS/TRRTSManager/VotingOverlay";

export default function TDVoting({ techDecision, refetch, currentUser }) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];

  const setAwardImage = (voteCount) => {
    var src;
    let alt = "";
    if (voteCount === 0) {
      alt = "award-icon-zero";
      src = award_zero_img;
    } else if (voteCount === 1) {
      alt = "award-icon-one";
      src = award_one_img;
    } else if (voteCount === 2) {
      alt = "award-icon-two";
      src = award_two_img;
    } else if (voteCount === 3) {
      alt = "award-icon-three";
      src = award_three_img;
    } else if (voteCount === 4) {
      alt = "award-icon-four";
      src = award_four_img;
    } else if (voteCount === 5) {
      alt = "award-icon-five";
      src = award_five_img;
    } else {
      alt = "award-icon-zero";
      src = award_zero_img;
    }
    return <img alt={alt} src={src} style={{ height: "30px" }} className="w-auto" />;
  };

  const handleVote = async (vote) => {
    let voter_field = techDecision?.voter_field;
    let remove_voter = false;
    if (voter_field) {
      //set the date and boolean depending on voter_field
      let vote_date_field = "";
      let vote_field = "";

      //if user has vote prior
      if (techDecision?.current_user_vote?.vote_field !== null) {
        voter_field = techDecision?.current_user_vote?.vote_field;
      }

      //if user try to click to same vote, 'remove' the vote
      if (vote === techDecision?.current_user_vote?.vote) {
        vote = null;
        remove_voter = true;
      }

      if (voter_field === "voter_one") {
        vote_date_field = "vote_one_date";
        vote_field = "vote_one_approve";
      } else if (voter_field === "voter_two") {
        vote_date_field = "vote_two_date";
        vote_field = "vote_two_approve";
      } else if (voter_field === "voter_three") {
        vote_date_field = "vote_three_date";
        vote_field = "vote_three_approve";
      } else if (voter_field === "voter_four") {
        vote_date_field = "vote_four_date";
        vote_field = "vote_four_approve";
      } else {
        vote_date_field = "vote_five_date";
        vote_field = "vote_five_approve";
      }

      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/${techDecision?.td_id}/`,
          {
            [voter_field]: remove_voter ? null : currentUser?.id,
            [vote_date_field]: remove_voter ? null : new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
            [vote_field]: vote,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then(() => refetch())
        .catch((error) => console.log("Unable to archive pp: " + error));
    }
  };

  return (
    <>
      <Col xs={1} className="d-flex justify-content-center w-auto">
        {techDecision?.current_user_vote?.vote_field === null ||
        techDecision?.current_user_vote?.vote !== false ? (
          <ThumbDownOffAltIcon className="clickable" onClick={() => handleVote(false)} />
        ) : (
          <ThumbDownIcon
            style={{
              color: "var(--bs-danger)",
            }}
            className="clickable"
            onClick={() => handleVote(false)}
          />
        )}
        {techDecision?.vote_count?.false_votes > 0 ? (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            rootClose
            placement="bottom"
            key="bottom"
            overlay={
              <Popover>
                <Popover.Body>
                    <VotingOverlay 
                        voteApprove={techDecision?.vote_one_approve === false} 
                        voter={techDecision?.voter_one} 
                        date={techDecision?.vote_one_date} 
                    />
                    <VotingOverlay 
                        voteApprove={techDecision?.vote_two_approve === false} 
                        voter={techDecision?.voter_two} 
                        date={techDecision?.vote_two_date} 
                    />
                    <VotingOverlay 
                        voteApprove={techDecision?.vote_three_approve === false} 
                        voter={techDecision?.voter_three} 
                        date={techDecision?.vote_three_date} 
                    />
                    <VotingOverlay 
                        voteApprove={techDecision?.vote_four_approve === false} 
                        voter={techDecision?.voter_four} 
                        date={techDecision?.vote_four_date} 
                    />
                    <VotingOverlay 
                        voteApprove={techDecision?.vote_five_approve === false} 
                        voter={techDecision?.voter_five} 
                        date={techDecision?.vote_five_date} 
                    />
                </Popover.Body>
              </Popover>
            }
          >
            <p className="mx-3">{techDecision?.vote_count?.false_votes}</p>
          </OverlayTrigger>
        ) : (
          <p className="mx-3">{techDecision?.vote_count?.false_votes}</p>
        )}
      </Col>
      <Col xs={1} className="d-flex justify-content-center w-auto">
        <>
          {techDecision?.current_user_vote?.vote_field === null ||
          techDecision?.current_user_vote?.vote !== true ? (
            <ThumbUpOffAltIcon
              data-testid="thumb-up-off-icon"
              className="clickable"
              onClick={() => handleVote(true)}
            />
          ) : (
            <ThumbUpIcon
              data-testid="thumb-up-icon"
              style={{
                color: "var(--bs-success)",
              }}
              className="clickable"
              onClick={() => handleVote(true)}
            />
          )}
          {techDecision?.vote_count?.true_votes > 0 ? (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              rootClose
              placement="bottom"
              key="bottom"
              overlay={
                <Popover>
                  <Popover.Body>
                    <VotingOverlay 
                        voteApprove={techDecision?.vote_one_approve} 
                        voter={techDecision?.voter_one} 
                        date={techDecision?.vote_one_date} 
                    />
                    <VotingOverlay 
                        voteApprove={techDecision?.vote_two_approve} 
                        voter={techDecision?.voter_two} 
                        date={techDecision?.vote_two_date} 
                    />
                    <VotingOverlay 
                        voteApprove={techDecision?.vote_three_approve} 
                        voter={techDecision?.voter_three} 
                        date={techDecision?.vote_three_date} 
                    />
                    <VotingOverlay 
                        voteApprove={techDecision?.vote_four_approve} 
                        voter={techDecision?.voter_four} 
                        date={techDecision?.vote_four_date} 
                    />
                    <VotingOverlay 
                        voteApprove={techDecision?.vote_five_approve} 
                        voter={techDecision?.voter_five} 
                        date={techDecision?.vote_five_date} 
                    />
                  </Popover.Body>
                </Popover>
              }
            >
              <p className="mx-3">{techDecision?.vote_count?.true_votes}</p>
            </OverlayTrigger>
          ) : (
            <p className="mx-3">{techDecision?.vote_count?.true_votes}</p>
          )}
        </>
      </Col>
      <Col xs={1} className="w-auto p-0 me-3">
        {setAwardImage(techDecision?.vote_count?.total_votes)}
      </Col>
    </>
  );
}
