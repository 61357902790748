import { Modal, Button, Form } from 'react-bootstrap'
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import HTMLEditor from '../../../UI/HTMLEditor';
import axios from 'axios';
import { useCookies } from 'react-cookie';

export default function NotesForm({ show, note_type, currentNote, item, user, handleCloseNotesForm, id, replyTo }) {

  const [cookies] = useCookies()
  let csrfToken = cookies['csrftoken']
  let authToken = cookies['auth_token']
  const currentUser = useSelector(state => state.user.value);
  const permissions = useSelector(state => state.role.value);
  const [note, setNote] = useState({})
  const [enforceFocusModal, setEnforceFocusModal] = useState(true)
  const handleChange = (e) => setNote({...note, [e.target.name]:  e.target.value}
    );
  const handleSubmit = (e) => {
    e.preventDefault()
    if(!note.note){
      e.stopPropagation();
    }else{
      if(currentNote) {
        axios.put(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/${currentNote?.note_id}/`, 
        {
          visible_to_labs: note.visible_to_labs, 
          note: note.note
        }, 
          {withCredentials: true, headers: {'X-CSRFToken': csrfToken, 'Authorization': `Token ${authToken}`}})
          .then(() => handleCloseNotesForm())
          .catch(error => alert("Unable to add note: " + error));
      } else {       
         axios.post(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/`, 
          {
            visible_to_labs: note.visible_to_labs,
            note: note.note, 
            submitted_by: currentUser.id, 
            submitted_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())), 
            note_type: note_type, 
            note_type_id: id, 
            in_response_to: replyTo
          }, 
          {withCredentials: true, headers: {'X-CSRFToken': csrfToken, 'Authorization': `Token ${authToken}`}})
          .then((response) => {
            setNote({})
            handleCloseNotesForm()
          })
          .catch(error => alert("Unable to add note: " + error));
      }
    }
  }

  useEffect(() => {
    if(!currentNote){
      if(permissions?.role_type === "Lab"){
        setNote({"visible_to_labs": true})
      } else {
        setNote({"visible_to_labs": false}) //Nist Notes should not be visible to labs by default
      }
    }else{
      setNote({...currentNote})    
    }
  }, [show, currentNote])
  
  return (
    <Modal size="lg" show={show} onHide={handleCloseNotesForm} enforceFocus={enforceFocusModal}>
      <Modal.Header closeButton>
        <h2>+ NOTE for Cert Review on Product {item?.pid?.product_id} + VID {item?.pid?.v_id}</h2>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
            <Form.Label className='fw-bold'>Submitter: {user}</Form.Label>
            {(permissions.role_type === "NIAP"  || permissions.role_type === "Validator") && <Form.Group controlId='note'>
              <Form.Label>Make Visible to Labs?</Form.Label>
                <Form.Check className="mx-3" type="radio" name="visible_to_labs" value={true} inline label="Yes" onChange={handleChange} checked={note.visible_to_labs === true || note.visible_to_labs === "true"} id="1"/>
                <Form.Check type="radio" name="visible_to_labs" value={false} inline label="No" onChange={handleChange} checked={note.visible_to_labs === false || note.visible_to_labs === "false"} id="2"/>
              </Form.Group>
            }
            <Form.Group className='mb-3' controlId='title'>
                <Form.Label className='fw-bold'>{`Title`}</Form.Label>
                <Form.Control type="text" name="title" onChange={handleChange} defaultValue={currentNote?.title} />
              </Form.Group>
            <Form.Group className="mb-3" controlId='note'>
                <Form.Label className='fw-bold'>Overview:*</Form.Label>
                <HTMLEditor name='note' handleChange={handleChange} setIsValid={() => {}} isValid={note.note} defaultValue={currentNote?.note} setEnforceFocusModal={setEnforceFocusModal}/>
            </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
            <Button variant="light" type="reset" onClick={() => handleCloseNotesForm()}>Cancel</Button>
            <Button variant="primary" type="submit" onClick={handleSubmit}>{currentNote?.note_id ? 'Update' : 'Add'}</Button>
      </Modal.Footer>
    </Modal>
  )
}
