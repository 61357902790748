import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Stack, Accordion, Button, Alert, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useCookies } from "react-cookie";
import EARNotes from "./EARNotes";
import EARSideInfo from "./EARSideInfo";
import EARResponseModal from "./EARResponseModal";
import EAREscalateERTModal from "./EAREscalateERTModal";
import ERTApproveRejectModal from "../Dashboard/ProductsModule/ERTApproveRejectModal";
import moment from "moment";
import parse from "html-react-parser";
import { useInterval } from "../../hooks/useInterval";
import EditIcon from "@mui/icons-material/Edit";
import Select from "react-select";
import UnauthorizedView from "../UI/UnauthorizedView";
import { Link } from "react-router-dom";
import HTMLEditor from '../UI/HTMLEditor'

export default function CheckPermEARDetails({setOverlayActive, setOverlayText}) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const { id } = useParams();
  const [canView, setCanView] = useState(false);

  useEffect(() => {
    if (id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/is_auth_to_view_ear?ear_id=${id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          }
        )
        .then((response) => {
          if(response.data?.can_view === true){
            setCanView(true);
          } else {
            setCanView(false);
          }
        })
        .catch((err) => console.log("unable to get member's virtual teams", err));
    }
  }, [id]);

  if(canView){
    return <EARDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
  } else if (canView === false) {
    return <UnauthorizedView />
  }

  return <></>

}

function EARDetails({setOverlayActive, setOverlayText}) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const { id } = useParams();
  const [review, setReview] = useState({});
  const user = useSelector((state) => state.user.value);
  const permissions = useSelector((state) => state.role.value);
  const [readOnly, setReadOnly] = useState(true);
  const [earPPs, setEarPPs] = useState([]);
  const [showEscalateModal, setShowEscalateModal] = useState(false);
  const [showCompleteTerminateModal, setShowCompleteTerminateModal] = useState(false);
  const [ERTMember, setERTMember] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [completeView, setCompleteView] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [isEditing, setIsEditing] = useState("");
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [ertMembers, setERTMembers] = useState([]);
  const [textFieldUpdate, setTextFieldUpdate] = useState({});

  const earStatusOptions = [
    { label: "Pending Review", value: "Pending Review" },
    { label: "Draft", value: "Draft" },
    {
      label: "Completed - Approved With Provisions",
      value: "Completed - Approved With Provisions",
    },
    {
      label: "Completed - Approved to Proceed",
      value: "Completed - Approved to Proceed",
    },
    {
      label: "Completed - EAR Previously Approved",
      value: "Completed - EAR Previously Approved",
    },
    {
      label: "Completed - No EAR Required",
      value: "Completed - No EAR Required",
    },
    {
      label: "Rejected - Pending Revision",
      value: "Rejected - Pending Revision",
    },
  ];

  useEffect(() => {
    //if user is NIAP or ERTMember, they can see edit icon and can edit columns
    if (permissions?.role_type === "NIAP" || ERTMember) {
      setUserCanEdit(true);
    }
  }, [ERTMember]);

  const noYesOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const handleChangeTextField = (e) => {
    setTextFieldUpdate({...textFieldUpdate, [e.target.name]: e.target.value});
  }

  const handleChange = (e) => {
    let data = {
      [e.target.name]: e.target.value,
      pid: review.pid.product_id,
    }

    if(e.target.name === "status"){
      if(["Pending Review", "Draft"]?.includes(e.target.value)){
        data['approved'] = false;
        data['rejected'] = false;
        axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${id}/`,
          data,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          }
        )
        .then((response) => {
          setAlert({ message: "Update Successfully Saved!", type: "success" });
          getReview();
        })
        .catch((err) => {
          console.log("Error saving update", err);
          setAlert({ message: "Error saving update.", type: "danger" });
        });
      } else if (e.target.value?.includes("Completed")){
        setShowCompleteTerminateModal(true);
        setCompleteView(true);
        // data['approved'] = true;
        // data['rejected'] = false;
      } else {
        setShowCompleteTerminateModal(true);
        // data['rejected'] = true;
        // data['approved'] = false;
      }
    } else {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${id}/`,
          data,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          }
        )
        .then((response) => {
          // if (e.target.name === "agent") {
            //send mail to alert members
            // axios
            // .post(
            //     `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/send_alert_and_mail/`,
            //     { 
            //       subject: `You have been assigned to EAR Review ${review?.earid}.`,
            //       recipients: {to: ["Agent"], cc: ["ERT Team"]},
            //       alert_type: "EAR Review",
            //       alert_text: `You have been assigned to EAR Review ${review?.earid}.`,
            //       alert_type_id: review?.earid,
            //       alert_source: "EAR Review"
            //     },
            //     {
            //         withCredentials: true,
            //         headers: {
            //             "X-CSRFToken": csrfToken,
            //             Authorization: `Token ${authToken}`,
            //         },
            //     }
            // )
            // .then((response) => console.log(response))
            // .catch((error) => console.log("error sending email: " + error));
          // } else {
          //   setAlert({ message: "Update Successfully Saved!", type: "success" });
          // }
          setAlert({ message: "Update Successfully Saved!", type: "success" });
          getReview();
        })
        .catch((err) => {
          console.log("Error saving update", err);
          setAlert({ message: "Error saving update.", type: "danger" });
        });
    }
    setIsEditing("");
  };

  const getReview = useCallback(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${id}/`, {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        })
        .then((response) => {
          setReview(response.data);
          getEarPPs(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  const getIsErtMember = useCallback(() => {
    if (user) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_ert_team_member?user=${user.id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          }
        )
        .then((response) => {
          setERTMember(response.data.is_member);
        })
        .catch((err) => console.log("unable to get member's virtual teams", err));
    }
  }, [user]);

  const getErtMembers = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/ert_team_members/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => {
        let members = response.data?.map((member) => {
          return {
            label: `${member?.account_id__first_name} ${member?.account_id__last_name}`,
            value: member.account_id,
          };
        });
        setERTMembers(members);
      })
      .catch((err) => console.log("unable to get ert team members", err));
  }, [user]);


  const getEarPPs = (rev) => {
    if (rev) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_ear_pp/?ear=${id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          }
        )
        .then((response) => setEarPPs(response.data))
        .catch((error) => console.log(error));
    }
  };

  const handleCloseCompleteTerminateModal = () => {
    setCompleteView(false);
    setShowCompleteTerminateModal(false);
    getReview();
  };

  const handleCloseResponseModal = () => {
    setShowResponseModal(false);
    getReview();
  };

  useEffect(() => {
    getReview();
  }, [getReview]);

  useInterval(() => {
    getReview();
  }, 60000); //1 min

  useEffect(() => {
    getIsErtMember();
  }, [getIsErtMember]);

  useInterval(() => {
    getIsErtMember();
  }, 180000); //3 min

  useEffect(() => {
    getErtMembers();
  }, [getErtMembers]);

  useEffect(() => {
    setReadOnly(review?.approved || review?.rejected);
  }, [review.approved, review.rejected]);

  return permissions?.role_permissions?.find((permission) => permission.area === "EAR Reviews")
    ?.read ? (
    <Container className="py-5" fluid>
      <ERTApproveRejectModal
        show={showCompleteTerminateModal}
        handleClose={handleCloseCompleteTerminateModal}
        review={review}
        refetch={getReview}
        user={user}
        complete={completeView}
      />
      <EAREscalateERTModal
        show={showEscalateModal}
        handleClose={() => setShowEscalateModal(false)}
        review={review}
        refetch={getReview}
        setAlert={setAlert}
      />
      <EARResponseModal
        show={showResponseModal}
        handleClose={handleCloseResponseModal}
        review={review}
        user={user}
        refetch={getReview}
      />
      <Row>
        <Col sm={12} md={8} lg={9}>
          <Row>
            {alert.message !== "" && (
              <Alert
                variant={alert.type}
                className="mt-3"
                dismissible
                onClose={() => setAlert({ message: "", type: "" })}
              >
                {alert.message}
              </Alert>
            )}
            <Col>
              {!readOnly ? (
                permissions?.role_permissions?.find(
                  (permission) => permission.area === "EAR Reviews"
                )?.update && (
                  <Row xs="auto" className="float-end">
                    {user?.user_role?.role_type === "Validator" && review?.status === "Draft" && (
                        <Col>
                          <Button
                            variant="warning"
                            className="rounded-pill"
                            onClick={() => setShowEscalateModal(true)}
                          >
                            Release to ERT
                          </Button>
                        </Col>
                      )}
                    {ERTMember && review?.status === "Pending Review" && (
                      <Row>
                        <Col sm={12} md={6}>
                          <Button
                            size="sm"
                            variant="success"
                            className="rounded-pill"
                            disabled={!review?.response?.note1}
                            onClick={() => {
                              setShowCompleteTerminateModal(true);
                              setCompleteView(true);
                            }}
                          >
                            Complete
                          </Button>
                        </Col>
                        <Col sm={12} md={6}>
                          <Button
                            size="sm"
                            variant="danger"
                            className="rounded-pill"
                            disabled={!review?.response?.note1}
                            onClick={() => {
                              setShowCompleteTerminateModal(true);
                            }}
                          >
                            Terminate
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Row>
                )
              ) : (
                <Row>
                  <Col></Col>
                  <Col>
                    <h3
                      className={review?.approved ? "fw-bold text-success" : "fw-bold text-danger"}
                    >
                      {review?.approved ? "APPROVED" : "REJECTED"}
                    </h3>
                    {review?.gist !== null && <strong>Reasoning: {review?.gist}</strong>}
                  </Col>
                </Row>
              )}
            </Col>
            <Col sm={12}>
            </Col>
            <Col sm={6} className="mt-3">
              <h3 className="fw-bold">Details for EAR REVIEW {id}</h3>
            </Col>
            <Col sm={6} className="mt-3 d-flex align-items-center">
              <div className="vr me-3"></div>
              <h3 className="fw-bold">
                <Link to={`/community/products/details/${review?.pid?.product_id}`}>
                  {review?.pid?.product_name}
                </Link>
              </h3>
            </Col>
            <Col sm={12} className="mt-3">
              <Row>
                <Col sm={12} md={5} lg={4} xl={2} className="border-dark border-bottom border-top">
                  <Stack className="border-start border-secondary ps-2 my-2">
                    {isEditing === "status" ? (
                      <>
                        <h5>Status</h5>
                        <Select
                          options={earStatusOptions}
                          defaultValue={earStatusOptions.find((s) => s.value === review?.status)}
                          onChange={(e) =>
                            handleChange({ target: { name: "status", value: e.value } })
                          }
                        />
                      </>
                    ) : (
                      <>
                        {userCanEdit && (
                          <div className="d-flex justify-content-end">
                            <EditIcon
                              style={{ width: "15px !important", height: "15px !important" }}
                              className="edit-button clickable"
                              onClick={() => {
                                setIsEditing("status");
                              }}
                              data-testid="edit-status"
                            />
                          </div>
                        )}
                        <h5>Status</h5>
                        <h5 className="text-secondary">{review?.status}</h5>
                      </>
                    )}
                  </Stack>
                </Col>
                <Col sm={12} md={5} lg={4} xl={2} className="border-dark border-bottom border-top">
                  <Stack className="border-start border-secondary ps-2 my-2">
                    <h5>PP Claim</h5>
                    {earPPs.map((p, idx) => (
                      <h5 key={idx} className="text-secondary">
                        {p?.pp__pp_name}
                      </h5>
                    ))}
                  </Stack>
                </Col>
                <Col sm={12} md={5} lg={4} xl={2} className="border-dark border-bottom border-top">
                  <Stack className="border-start border-secondary ps-2 my-2">
                    {isEditing === "reqd" ? (
                      <>
                        <h5>EAR Required for Evaluation</h5>
                        <Select
                          options={noYesOptions}
                          defaultValue={noYesOptions.find((s) => s.value === review?.reqd)}
                          onChange={(e) =>
                            handleChange({ target: { name: "reqd", value: e.value } })
                          }
                        />
                      </>
                    ) : (
                      <>
                        {userCanEdit && (
                          <div className="d-flex justify-content-end">
                            <EditIcon
                              style={{ width: "15px !important", height: "15px !important" }}
                              className="edit-button clickable"
                              onClick={() => {
                                setIsEditing("reqd");
                              }}
                              data-testid="edit-ear-required"
                            />
                          </div>
                        )}
                        <h5>EAR Required for Evaluation</h5>
                        <h5 className="text-secondary">{review?.reqd ? "Yes" : "No"}</h5>
                      </>
                    )}
                  </Stack>
                </Col>
                <Col sm={12} md={5} lg={4} xl={2} className="border-dark border-bottom border-top">
                  <Stack className="border-start border-secondary ps-2 my-2">
                    {isEditing === "apriori" ? (
                      <>
                        <h5>Previous Approval</h5>
                        <Select
                          options={noYesOptions}
                          defaultValue={noYesOptions.find((s) => s.value === review?.apriori)}
                          onChange={(e) =>
                            handleChange({ target: { name: "apriori", value: e.value } })
                          }
                        />
                      </>
                    ) : (
                      <>
                        {userCanEdit && (
                          <div className="d-flex justify-content-end">
                            <EditIcon
                              style={{ width: "15px !important", height: "15px !important" }}
                              className="edit-button clickable"
                              onClick={() => {
                                setIsEditing("apriori");
                              }}
                              data-testid="edit-previous-approval"
                            />
                          </div>
                        )}
                        <h5>Previous Approval</h5>
                        <h5 className="text-secondary">{review?.apriori ? "Yes" : "No"}</h5>
                      </>
                    )}
                  </Stack>
                </Col>
                <Col sm={12} md={5} lg={4} xl={2} className="border-dark border-bottom border-top">
                  <Stack className="border-start border-secondary ps-2 my-2">
                    {isEditing === "due" ? (
                      <>
                        <Form.Group controlId="due">
                          <Form.Label>Deadline Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="due"
                            defaultValue={moment.utc(review?.due).format("YYYY-MM-DD")}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </>
                    ) : (
                      <>
                        {userCanEdit && (
                          <div className="d-flex justify-content-end">
                            <EditIcon
                              style={{ width: "15px !important", height: "15px !important" }}
                              className="edit-button clickable"
                              onClick={() => {
                                setIsEditing("due");
                              }}
                              data-testid="edit-deadline-date"
                            />
                          </div>
                        )}
                        <h5>Deadline Date</h5>
                        <h5 className="text-secondary">
                          {review?.due ? moment.utc(review?.due).format("MM/DD/YYYY") : "No Date"}
                        </h5>
                      </>
                    )}
                  </Stack>
                </Col>
                <Col sm={12} md={5} lg={4} xl={2} className="border-dark border-bottom border-top">
                  <Stack className="border-start border-secondary ps-2 my-2">
                    {isEditing === "agent" ? (
                      <>
                        <h5>Assigned Agent</h5>
                        <Select
                          options={ertMembers}
                          defaultValue={ertMembers.find((user) => user.value === review?.agent?.id)}
                          onChange={(e) =>
                            handleChange({ target: { name: "agent", value: e.value } })
                          }
                        />
                      </>
                    ) : (
                      <>
                        {userCanEdit && (
                          <div className="d-flex justify-content-end">
                            <EditIcon
                              style={{ width: "15px !important", height: "15px !important" }}
                              className="edit-button clickable"
                              onClick={() => {
                                setIsEditing("agent");
                              }}
                              data-testid="edit-agent"
                            />
                          </div>
                        )}
                        <h5>Assigned Agent</h5>
                        <h5 className="text-secondary">
                          {review?.agent ? review?.agent?.first_name + " " + review?.agent?.last_name : "No Assigned Agent"}
                        </h5>
                      </>
                    )}
                  </Stack>
                </Col>
              </Row>
            </Col>
            <Col sm={12} className="mt-3">
              <Accordion className="mb-4 border-0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">JUSTIFICATION</h6>
                  </Accordion.Header>
                  <Accordion.Body>
                  <Container>
                    <Row>
                    {isEditing === "just" ? 
                      <Col>
                        <HTMLEditor
                            name="just"
                            handleChange={handleChangeTextField}
                            setIsValid={() => { }}
                            isValid={true}
                            defaultValue={review?.just}
                        />
                      </Col>
                    :
                      <Col>
                        {review?.just && parse(review?.just)}
                      </Col>
                    }
                      <Col sm={1}>
                        {userCanEdit && (
                          isEditing === "just" ?
                            <>
                              <Button variant="primary" onClick={() => handleChange({ target: { name: "just", value: textFieldUpdate.just } })}>Save</Button>
                              <Button variant="warning" className="mt-2" onClick={() => setIsEditing("")}>Cancel</Button>
                            </>
                            :
                            <div className="d-flex justify-content-end">
                              <EditIcon
                                style={{ width: "15px !important", height: "15px !important" }}
                                className="edit-button clickable"
                                onClick={() => {
                                  setIsEditing("just");
                                }}
                                data-testid="edit-just"
                              />
                            </div>
                        )}
                      </Col>
                    </Row>
                  </Container>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="my-4" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">INITIAL COMMENTS</h6>
                  </Accordion.Header>
                  <Accordion.Body>{review?.blurba && parse(review?.blurba)}</Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="my-4" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">
                      {review?.status === "Pending Review" ||
                      review?.status === "Draft"
                        ? "DRAFT"
                        : "OFFICIAL"}{" "}
                      RESPONSE TO LAB
                    </h6>
                  </Accordion.Header>
                  {!readOnly &&
                    permissions?.role_permissions?.find(
                      (permission) => permission.area === "EAR Reviews"
                    )?.update &&
                    ERTMember &&
                    (review?.status === "Pending Review" ||
                      review?.status === "Draft") && (
                      <Button
                        size="sm"
                        variant="secondary"
                        className="rounded-pill"
                        onClick={() => setShowResponseModal(true)}
                      >
                        {review?.response?.upid ? "Update" : "+ Add"}
                        {review?.status === "Pending Review" ||
                        review?.status === "Draft"
                          ? " Draft"
                          : " Official"}{" "}
                        Response to Lab
                      </Button>
                    )}
                  <Accordion.Body>
                    {review?.response?.note1 && parse(review?.response?.note1)}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {(ERTMember ||
                permissions.role_type === "NIAP" ||
                review?.escalator?.id === user?.id) && (
                <EARNotes review={review} user={user} permissions={permissions} />
              )}
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={4} lg={3}>
          <EARSideInfo review={review} id={id} ERTMember={ERTMember} setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
        </Col>
      </Row>
    </Container>
  ) : (
    <UnauthorizedView />
  );
}
