import { useEffect, useState } from "react";
import { Container, Row, Col, Button, ToggleButton, ToggleButtonGroup, Form, Stack, ButtonGroup } from "react-bootstrap";
import '../Products.css'
import Filter from "./Filter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faBorderAll, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import ProductGridView from "./UI/ProductGridView";
import ProductListView from "./UI/ProductListView";
import ProductTableView from "./UI/ProductTableView";
import axios from 'axios';
import Subheader from "../../Subheader";
import ProductModals from "./ProductModals";
import { useInterval } from "../../../hooks/useInterval";
import qs from 'qs'

export default function Products() {

  const [products, setProducts] = useState([]);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0)
  const PAGE_SIZE = 6;
  const [tablePageSize, setTablePageSize] = useState()
  const [showAll, setShowAll] = useState(true)
  const [maintenances, setMaintenances] = useState({})
  const [showFilters, setShowFilters] = useState(true);
  const [filters, setFilters] = useState({"certification_status": "Certified",});
  const [productsView, setProductsView] = useState("Table");
  const [projpps, setProjpps] = useState([]);
  const [ccPps, setCcPps] = useState([])
  const [pclCounts, setPclCounts] = useState({})
  const [productFilterOptions, setProductFilterOptions] = useState([
    {name: 'tech_type', label: 'Technology Type', options: []},
    {name: 'vendor', label: 'Vendor', options: []},
    {name: 'project_pp_product', label: 'Protection Profile', options: []},
    {name: 'submitting_country_id', label: 'Certifying Country', options: []}
  ]);

  const productsPerPage = 4;

  const handleProductDisplay = (view) => {
    setProductsView(view)
    if (view === 'Table') setShowFilters(false);
  }
  const handleShowFilter = () => setShowFilters(!showFilters);

  const handleChangeFilters = (name, value) => {
    if (name === 'status') {
      delete filters['certification_status']
    } else if (name === 'certification_status') {
      delete filters['status']
    }
    setFilters({...filters, [name]: value})
  }

  const productStatuses = [
    {name: 'certification_status', value: 'Certified', display: 'Certified'}, 
    {name: 'status', display: 'In Evaluation', value: "In Progress"}, 
    {name: 'status', value: 'Archived', display:'Archived'}
  ]

  // const loadSchemes = () =>{
  //   axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}account/scheme/get_all_schemes`)
  //   .then(response => {
  //     const filterOptions = [...productFilterOptions];
  //     response.data?.forEach(scheme => {
  //       !filterOptions[3].options.some(f => f.label === scheme?.cid__name) && filterOptions[3].options.push({label: scheme?.cid__name, value: scheme?.cid__code2_iso}); // Set country filter options
  //     })
  //     setProductFilterOptions([...filterOptions])
  //   }).catch(error => {
  //     console.log(error)
  //   })
  // }

  // useEffect(() => {
  //   loadSchemes()
  // }, [])

  const loadProtectionProfiles = () =>{
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_grouped_pp_pcl/`)
      .then(response => {
        setProjpps(response.data)
        const filterOptions = [...productFilterOptions];
        Object.keys(response.data).forEach(key => {
          response.data[key].forEach(projpp => {
            !filterOptions[2].options.some(f => f.label === projpp?.pp_name) && filterOptions[2].options.push({label: projpp?.pp_name, value: projpp?.id}); // Set pp filter options
          })
        })
        filterOptions[2].options.sort((a, b) => a.label?.localeCompare(b.label))
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    loadProtectionProfiles()
  }, [])

  const loadCcProtectionProfiles = () => {
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/products/get_grouped_ccp_pp/`)
      .then(response => {
        setCcPps(response.data)
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    loadCcProtectionProfiles()
  }, [])

  const loadProductPCL = async () => {
    let params = {
      ...filters,
      publish_status: "Published",
      limit: PAGE_SIZE,
      offset: offset
    }
    await axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/pcl_products/?${qs.stringify(params, {arrayFormat: 'comma'})}`)
    .then(response => {
      const filterOptions = [...productFilterOptions];
      filterOptions[0].options = response.data?.results?.tech_types.map(tech_type => ({label: tech_type, value: tech_type}))?.sort((a, b) => a.label?.localeCompare(b.label))
      filterOptions[1].options = response.data?.results?.vendors.map(vendor => ({label: vendor, value: vendor}))?.sort((a, b) => a.label?.localeCompare(b.label))
      filterOptions[3].options = response.data?.results?.countries.map(country => ({label: country, value: country}))?.sort((a, b) => a.label?.localeCompare(b.label))
      setProductFilterOptions([...filterOptions]);
      setProducts((response.data?.results?.products))
      setTotal(response.data?.count)
    }).catch(error => {
      console.log(error)
    })
  }

  const loadProductPCLCounts = () =>{
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_pcl_counts/`)
    .then(response => {
      setPclCounts(response.data)
      if(filters.certification_status === 'Certified' && showAll === true){
        setTablePageSize(response.data.certified)
      }
      else if(filters.status === 'In Progress' && showAll === true){
        setTablePageSize(response.data.in_evaluation)
      }
      else if(filters.status === 'Archived' && showAll === true){
        setTablePageSize(response.data.archived)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    loadProductPCLCounts()
    loadProductPCL()
  }, [filters, offset])

  const loadMaintenanceGetGroup = () =>{
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/get_grouped/`)
    .then(response => {
      setMaintenances(response.data)
    }).catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    loadMaintenanceGetGroup()
  }, [])

  useInterval(()=>{
    loadProtectionProfiles()
    loadProductPCL()
    loadMaintenanceGetGroup()
  }, 300000) //5 min

  useEffect(() => {
    document.title = 'NIAP - Products';
  }, []);

  const getPclCount = () => {
    if (filters?.certification_status === 'Certified') {
      return pclCounts.certified;
    } else if (filters?.status === 'In Progress') {
      return pclCounts.in_evaluation;
    } else if (filters?.status === 'Archived') {
      return pclCounts.archived;
    } else {
      return total;
    }
  };

  const getPageTitle = () => {
    if(filters?.certification_status === 'Certified') {
      return 'PRODUCT COMPLIANT LIST (PCL)';
    } else if (filters?.status === 'In Progress') {
      return 'PRODUCTS IN EVALUATION';
    } else {
      return 'ARCHIVED PRODUCTS';
    }
  }

  const getPageBody = () => {
    if(filters?.certification_status === 'Certified') {
      return (
        <p>
          The products listed on the PCL must be considered in the context of the environment of use, including 
          appropriate risk analysis and system accreditation requirements. Customers must ensure that the products 
          selected will provide the necessary security functionality for their architecture.
        </p>
      )
    } else if (filters?.status === 'In Progress') {
      return (
        <p>
          The security products listed here are in various stages of NIAP evaluation.<span className="fw-bold"> There is no guarantee that any of these products will complete evaluation successfully.</span>
        </p>
      )
    } else {
      return (
        <p>
          The Archived Validated Products List is a repository of products validated under CCEVS which have expired 
          Assurance Maintenance Dates as detailed in Publication #6. Per CNSSP #11, products listed below are no 
          longer procurable for use on National Security Systems, but may continue to be used if already employed within 
          your organization’s IT infrastructure. For further information, see{" "}
          <a target="_blank" href="https://www.niap-ccevs.org/Documents_and_Guidance/ccevs/The%20Relationship%20of%20NIAP%20Archived%20Products%20to%20CNSSP-11.pdf" rel="noreferrer">Relationship of NIAP Archived Products to CNSSP-11</a>.
        </p>
      )
    }
  }
  
  return (
    <>
      <Subheader pageName={"Products"} />
      <Container fluid>
        <Row className="px-5 py-2">
          <Col>
            <h5 className='fw-bold'>PRODUCTS<span className="text-primary"> - {getPageTitle()}</span></h5>
            {getPageBody()}
          </Col>
        </Row>
        <ProductModals />
        <Row className='px-5 pb-5 justify-content-between align-items-center'>
          <Col className='mt-4'>
            {filters.certification_status === 'Certified' && <h3 className='fw-bold text-primary text-uppercase'>Product Compliant List<span className='border my-2 mx-4 p-2'>{pclCounts.certified}</span></h3>}
            {filters.status === 'In Progress' && <h3 className='fw-bold text-primary text-uppercase'>Products In Evaluation <span className='border my-2 mx-4 p-2'>{pclCounts.in_evaluation}</span></h3>}
            {filters.status === 'Archived' && <h3 className='fw-bold text-primary text-uppercase'>Archived Products <span className='border my-2 mx-4 p-2'>{pclCounts.archived}</span></h3>}
            {!filters.certification_status && !filters.status && <h3 className='fw-bold text-primary text-uppercase'>All Products: <span className='border my-2 mx-4 p-2'>{total}</span></h3>}
          </Col>
          <Col className='mt-4 text-end'>
            <ButtonGroup size="md">
              <Button variant={filters.certification_status === 'Certified' ? 'primary' : 'light'} className='me-4 rounded-0 shadow' onClick={() => handleChangeFilters('certification_status', 'Certified')}>
                <h2 className='text-gray-400'>{pclCounts.certified}</h2>
                <p className='m-0'>Product Compliant List</p>
              </Button>
              <Button variant={filters.status === "In Progress" ? 'primary' : 'light'} className='me-4 rounded-0 shadow' onClick={() => handleChangeFilters('status', "In Progress")}>
                <h2 className='text-gray-400'>{pclCounts.in_evaluation}</h2>
                <p className='m-0'>Products In Evaluation</p>
              </Button>
              <Button variant={filters.status === 'Archived' ? 'primary' : 'light'} className='me-4 rounded-0 shadow' onClick={() => handleChangeFilters('status', 'Archived')}>
                <h2 className='text-gray-400'>{pclCounts.archived}</h2>
                <p className='m-0'>Archived Products</p>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3} className='p-0 my-4'>
              <Filter show={showFilters} handleShowFilter={handleShowFilter} handleChangeFilters={handleChangeFilters} clearFilters={() => setFilters({})} filters={filters} filterOptions={productFilterOptions} statusOptions={productStatuses} />
          </Col>
          <Col md={showFilters ? 9 : 12} className='p-0 my-4'>
              <Row className='justify-content-between g-3'>
                <Col sm={12} md={3} className='d-flex justify-content-start'>
                  <Stack direction="horizontal">
                    {!showFilters && <Button className='ms-5 my-1 border-dark' variant='light' size='sm' onClick={handleShowFilter}>Show Filter</Button>}
                  </Stack>
                </Col>
                <Col sm={12} md={9} className='d-flex flex-wrap justify-content-start'>
                  <ToggleButtonGroup type="radio" name="options" defaultValue={1} className='px-4'>
                    {productsView === "Table" && filters.status !== 'Archived' &&
                      <Button
                        variant="link"
                        className='text-dark' 
                        onClick={() => {
                          setShowAll(!showAll);
                          setTablePageSize(showAll ? null : getPclCount());
                        }}
                      >
                        {showAll ? "Cancel " : ""}Show All
                      </Button>
                    }
                    <ToggleButton variant='light' className='d-flex justify-content-center p-2 toggle-buttons-hover' value={1} id="tbg-btn-1" onClick={(e) => handleProductDisplay("Table")} size='sm' active={productsView === "Table"}>
                      <FontAwesomeIcon className='align-self-center p-0 m-0' icon={faList} size='lg' />
                      <span className="visually-hidden">Table View</span>
                    </ToggleButton>
                    <ToggleButton variant='light' className='toggle-buttons-hover d-flex justify-content-center p-2' value={2} id="tbg-btn-2" onClick={(e) => handleProductDisplay("List")} size='sm' active={productsView === "List"}>
                      <FontAwesomeIcon className='align-self-center p-0 m-0' icon={faLayerGroup} size='lg' />
                      <span className="visually-hidden">List View</span>
                    </ToggleButton>
                    <ToggleButton variant='light' className='toggle-buttons-hover d-flex justify-content-center p-2' value={3} id="tbg-btn-3" onClick={(e) => handleProductDisplay("Grid")} size='sm' active={productsView === "Grid"}>
                      <FontAwesomeIcon className='align-self-center p-0 m-0' icon={faBorderAll} size='lg' />
                      <span className="visually-hidden">Grid View</span>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {/* <Stack direction="horizontal" className='mx-2 justify-content-center'>
                    <Form.Check type='checkbox' id='save-profile'/>
                    <Form.Label htmlFor="save-profile" className="small my-0 mx-2">Save My Profile</Form.Label>
                  </Stack> */}
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  {productsView === "Grid" && 
                    <ProductGridView 
                      items={products} 
                      setOffset={setOffset} 
                      offset={offset}
                      total={total}
                      PAGE_SIZE={PAGE_SIZE}
                      maintenances={maintenances} 
                      filters={filters} 
                      itemsPerPage={productsPerPage} 
                      projpps={projpps}
                      ccPps={ccPps}
                    />
                  }
                  {productsView === "List" && 
                    <ProductListView 
                      items={products} 
                      setOffset={setOffset} 
                      offset={offset}
                      total={total}
                      PAGE_SIZE={PAGE_SIZE}
                      maintenances={maintenances} 
                      projpps={projpps}
                      ccPps={ccPps}
                    />
                  }
                  {productsView === "Table" && 
                    <ProductTableView 
                      maintenances={maintenances} 
                      sidebarFilters={filters} 
                      projpps={projpps}
                      ccPps={ccPps}
                      tablePageSize={tablePageSize}
                    />
                  }
                </Col>
              </Row>
          </Col>
        </Row>
      </Container> 
    </>
  )
}
