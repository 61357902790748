import ResponsiveMaterialTable from '../../UI/MaterialTable/ResponsiveMaterialTable';
import { Button, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import moment from 'moment';
import SubmitEarModal from '../../EAR/SubmitEarModal';
import { useInterval } from '../../../hooks/useInterval';
import qs from 'qs'


export default function LabEarReviewTable({ tableRef, currentUserEars, refetch }) {
  const [cookies] = useCookies();
  const authToken = cookies['auth_token'];
  const [earReviewNotes, setEarReviewNotes] = useState([]);
  const [showSubmitEARModal, setShowSubmitEARModal] = useState(false);
  const [currentEar, setCurrentEar] = useState({})

  const handleCloseEARModal = () => {
    setShowSubmitEARModal(false);
    refetch();
  }

  const fetchEarNotes = useCallback(() => {
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/get_grouped_notes/`,
    { withCredentials: true, headers: { Authorization: `Token ${authToken}` }})
    .then(response => {
      setEarReviewNotes(response.data);
    }).catch(error => {
      console.log(error)
    })
  }, [])

  useEffect(() => {
    fetchEarNotes();
  }, [])

  useInterval(() => {
    fetchEarNotes();
  }, 300000); //5 min

  const recentComments = (comments) => {
    let count = 0;
    for(let comment in comments) {
      let difference = new Date().getTime() - new Date(comments[comment]).getTime();
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      if(totalDays <= 1){
        count += 1
      }
    }
    return count;
  }

  const columns = [
    {
      title: 'ID',
      cellStyle: { width: '10%', minWidth: '10%' },
      field: 'earid',
      render: (rowData) => {
        return (
          <Link
            to={`ear-reviews/details/${rowData.earid}`}
            relative="route"
            className="text-decoration-underline text-blue"
          >
            {rowData?.earid}
          </Link>
        )
      }
    },
    {
      title: 'VID',
      cellStyle: { width: '10%', minWidth: '10%' },
      field: 'pid.v_id'
    },
    {
      title: 'Status',
      field: 'status'
    },
    {
      title: 'Submitted',
      field: 'pid.check_in_date',
      render: (rowData) => {
        return (
          <>{moment(
            rowData?.pid?.check_in_date
          ).format('MM/DD/YYYY')}</>
        )
      }
    },
    {
      title: 'Deadline',
      render: (rowData) => {
        const deadline = moment(rowData?.pid?.check_in_date).add(5, 'days')
        return (
          <>{deadline.format('MM/DD/YYYY')}</>
        )
      }
    },
    {
      title: 'EAR Comments',
      cellStyle: { width: '10%', minWidth: '10%' },
      render: (rowData) => {
        return (
          <Link
            to={`ear-reviews/details/${rowData.earid}`}
            relative="route"
            className="text-decoration-none"
          >
            <Container>
              <Row>
                <Button className='p-0 rounded-5 mb-1' style={{ backgroundColor: '#FB7474', borderColor: '#FB7474', color: 'var(--bs-black)' }}>
                  {earReviewNotes[rowData?.earid] ? recentComments(earReviewNotes[rowData?.earid]) : 0} New
                </Button>
              </Row>
              <Row>
                <Button className='p-0 rounded-5' style={{ backgroundColor: '#72AEF6', borderColor: '#72AEF6', color: 'var(--bs-black)' }}>
                  {earReviewNotes[rowData?.earid] ? earReviewNotes[rowData?.earid].length : 0} Total
                </Button>
              </Row>
            </Container>
          </Link>
        )
      }
    },
    {
      title: "",
      render: (rowData) => {
        if (rowData?.status === 'Rejected - Pending Revision') {
          return (
            <div className="d-flex justify-content-center">
              <Button
                variant='bright-navy'
                className='rounded-pill small-font m-auto'
                onClick={() => {setCurrentEar(rowData); setShowSubmitEARModal(true)}}
              >
                Resubmit EAR
              </Button>
            </div>
          )
        } else {
          return <></>
        }
      }
    }
  ];

  const options = {
    sorting: true,
    search: false,
    columnsButton: true,
    padding: 'dense',
    pageSize: 20
  };

  return (
    <>
    <SubmitEarModal show={showSubmitEARModal} handleClose={handleCloseEARModal} currentEar={currentEar}/>
    <ResponsiveMaterialTable
      title={"EAR Reviews"}
      columns={columns}
      tableRef={tableRef}
      data={(query) =>
        new Promise((resolve, reject) => {
          // Extract the necessary information from the query object
          const {
              page,
              pageSize,
              search,
              filters,
              orderBy,
              orderDirection,
          } = query;

          let newFilters = filters.map((filter) => {
              let value = ""
              if (Array.isArray(filter.value)) {
                  value = filter.value
              } else {
                  value = filter.value.replace(/['"]+/g, '')
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
          });
          const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
          }
          axios
              .get(
                  `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/current_user_ears/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                  {
                      withCredentials: true,
                      headers: {
                          Authorization: `Token ${authToken}`,
                      },
                  }
              )
              .then((response) => {
                  resolve({
                      data: response.data.results,
                      page: page,
                      totalCount: response.data.count,
                  });
              })
              .catch((error) => {
                  reject(error);
              });
          })
        }
      options={options}
    />
    </>
  );
}
