import axios from "axios";

function latestApprovedMaintenance(maintenances) {
  const latestApprovedMaintenance = maintenances
    ?.filter((maintenance) => maintenance.status === "Approved")
    ?.sort((a, b) =>
      new Date(a.completion_date).getTime() <
      new Date(b.completion_date).getTime()
        ? 1
        : -1
    )[0];
  return latestApprovedMaintenance;
}

export {
    latestApprovedMaintenance,
};

//for vendor/validator proficiency feedback form
//requestor is either vendor or validator
//type is either submitted or updated (POST or PUT)
// export const handleAlertAndMailer = (csrfToken, requestor, product, type) => {
//     axios 
//     .post( 
//     `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/send_alert_and_mail/`,
//     { 
//         subject: `Proficiency Feedback Form Submission`,
//         recipients: {to: ["NIAP Management"], cc: []},
//         alert_type: "Product",
//         alert_text: `The Proficiency Feedback Form for VID${product?.v_id} has been ${type} By ${requestor}.`,
//         alert_type_id: product?.product_id,
//     },
//     {
//         withCredentials: true,
//         headers: {
//         "X-CSRFToken": csrfToken,
//         },
//     }
//     )
//     .then((response) => {})
//     .catch((error) =>
//         console.log("Unable to create alert and email.", error)
//     );
// }

export const handleAlertAndMailer = async (csrfToken, authToken, data) => {
  await axios 
  .post( 
  `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/send_alert_and_mail/`,
  data,
  {
      withCredentials: true,
      headers: {
        "Authorization": `Token ${authToken}`,
        "X-CSRFToken": csrfToken,
      },
  }
  )
  .then((response) => {
    console.log('Alert and email sent successfully.')
  })
  .catch((error) =>
      console.log("Unable to create alert and email.", error)
  );
}

