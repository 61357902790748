import { Col, Container, Form, Modal, Row, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

export const DocumentEditModal = ({
  product,
  documentName,
  validationTeam,
  setDocumentHtml,
  setDisplayIframe,
  show,
  handleClose,
}) => {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const csrfToken = cookies["csrftoken"];
  const [documentEdits, setDocumentEdits] = useState({});
  const [forLabs, setForLabs] = useState(false);

  useEffect(() => {
    setDocumentEdits({});
    //these letters are for labs
    if (
      [
        "Termination Warning Letter",
        "Termination Letter 1 - Package Incomplete",
        "Termination Letter 2 - Package Not Submitted",
        "Withdrawal Letter",
      ].includes(documentName)
    ) {
      setForLabs(true);
    } else {
      setForLabs(false);
    }
  }, [documentName]);

  const handleChange = (e) => {
    setDocumentEdits({ ...documentEdits, [e.target.name]: e.target.value });
  };

  const handleGenerate = async () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/generate_document/?document_name=${documentName}&product_id=${product?.product_id}`,
        {
          ...documentEdits,
        },
        {
          withCredentials: true,
          headers: { "X-CSRFToken": csrfToken, Authorization: `Token ${authToken}` },
          responseType: "blob",
        }
      )
      .then((response) => {
        URL.revokeObjectURL(setDocumentHtml);
        let newDoc = "";
        if (response.data.size > 0) {
          newDoc = URL.createObjectURL(response.data);
        }
        setDocumentHtml(newDoc + "#toolbar=0");
        handleClose();
        setDisplayIframe(true);
      })
      .catch((error) => {
        // console.log("error: ", error);
      });
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          Process Document Generator: Data Entry for{" "}
          <span className="fw-bold">
            {documentName} for VID{product?.v_id}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Container>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="issue_date">
                  <Form.Label className="fw-bold">Date of This Letter:</Form.Label>
                  <Form.Control
                    type="date"
                    defaultValue={moment().format("YYYY-MM-DD")}
                    name="issue_date"
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="poc">
                  <Form.Label className="fw-bold">Addressee:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={forLabs ? product?.assigned_lab?.poc : product?.vendor_id?.poc}
                    name="poc"
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="primary_address_1">
                  <Form.Label className="fw-bold">Address Line 1:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={
                      forLabs
                        ? product?.assigned_lab?.primary_address_1
                        : product?.vendor_id?.primary_address_1
                    }
                    name={forLabs ? "address_1" : "primary_address_1"}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="primary_address_2">
                  <Form.Label className="fw-bold">Address Line 2:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={
                      forLabs
                        ? product?.assigned_lab?.primary_address_2
                        : product?.vendor_id?.primary_address_2
                    }
                    name={forLabs ? "address_2" : "primary_address_2"}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="primary_city">
                  <Form.Label className="fw-bold">City:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={
                      forLabs ? product?.assigned_lab?.primary_city : product?.vendor_id?.primary_city
                    }
                    name={forLabs ? "city" : "primary_city"}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="primary_state">
                  <Form.Label className="fw-bold">State:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={
                      forLabs
                        ? product?.assigned_lab?.primary_state
                        : product?.vendor_id?.primary_state
                    }
                    name={forLabs ? "us_state_abbrv" : "primary_state"}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="primary_zip">
                  <Form.Label className="fw-bold">Zip:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={
                      forLabs ? product?.assigned_lab?.primary_zip : product?.vendor_id?.primary_zip
                    }
                    name={forLabs ? "zip" : "primary_zip"}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="product_name">
                  <Form.Label className="fw-bold">Product:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={product?.product_name}
                    name="product_name"
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="copyAddressees">
                  <Form.Label className="fw-bold">Copy Addressees:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    maxLength={2000}
                    name="copy_addressees"
                    onChange={handleChange}
                    defaultValue={
                      product?.vendor_id?.poc +
                      ", " +
                      product?.vendor_id?.name +
                      "\n" +
                      product?.assigned_lab?.poc +
                      ", " +
                      product?.assigned_lab?.name +
                      "\n" +
                      validationTeam
                        ?.map(
                          (validator) =>
                            validator?.user_id?.first_name + " " + validator?.user_id?.last_name
                        )
                        .join(", ") +
                      ", " +
                      "CCEVS Validators"
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleGenerate}>
          Update Preview
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
