import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Modal, Container, Row, Col, Button, Form } from "react-bootstrap";
import moment from "moment";
import HTMLEditor from '../../UI/HTMLEditor'

export default function EventModal({
    show,
    handleShow,
    event,
    refetch,
    setAlert,
}) {
    const [validated, setValidated] = useState(false);
    const [cookies] = useCookies();
    const [newEvent, setNewEvent] = useState({});
    const [editedEvent, setEditedEvent] = useState({});
    const [enforceFocusModal, setEnforceFocusModal] = useState(true)
    let authToken = cookies["auth_token"];
    let csrfToken = cookies["csrftoken"];
    const currentUser = useSelector((state) => state.user.value);

    useEffect(() => {
        if (event?.id) {
            setNewEvent({ ...event });
        } else {
            setNewEvent({});
        }
        setEditedEvent({});
        setValidated(false);
    }, [show]);

    const resetForm = () => {
        setValidated(false);
        setNewEvent({});
        setEditedEvent({});
    };

    const handleChange = (e) => {
        if (!event) {
            setNewEvent({ ...newEvent, [e.target.name]: e.target.value });
        } else {
            setEditedEvent({ ...editedEvent, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else {
            await axios
                .post(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/announcements/`,
                    {
                        types: "4",
                        posted: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                        creator: currentUser.id,
                        entrydate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                        ...newEvent,
                    },
                    {
                        withCredentials: true,
                        headers: {
                            "X-CSRFToken": csrfToken,
                            Authorization: `Token ${authToken}`,
                        },
                    }
                )
                .then((response) => {
                    handleShow();
                    setAlert({
                        message: "New Event Created!",
                        type: "success",
                    });
                    refetch();
                })
                .catch((error) => {
                    setAlert({
                        message: "Error Creating New Event.",
                        type: "danger",
                    });
                    console.log(error);
                });
        }
        setValidated(true);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        await axios
            .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/announcements/${event?.id}/`,
                {
                    ...editedEvent,
                    creator: newEvent.creator?.id,
                    editor: currentUser.id,
                    moddate: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                },
                {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                    },
                }
            )
            .then((response) => {
                if (refetch) {
                    refetch();
                }
                handleShow();
                setAlert({ message: "Successfully Updated Event!", type: "success" });
            })
            .catch((error) => {
                handleShow();
                console.log("error: ", error);
                setAlert({ message: "Error updating Event.", type: "danger" });
            });
    };

    return (
        <Modal show={show} onHide={handleShow} size="lg" enforceFocus={enforceFocusModal}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header className="border-0" closeButton>
                    <h3 className="text-bright-navy">
                        {!event
                            ? "+ ADD NEW EVENT"
                            : `EDIT EVENT - ${event?.title}`}
                    </h3>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Event Name:*</Form.Label>
                                    <Form.Control
                                        className="form-indent-readOnly"
                                        name="title"
                                        onChange={handleChange}
                                        value={
                                            editedEvent?.title ??
                                            newEvent?.title ??
                                            ""
                                        }
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="begins">
                                    <Form.Label>Beginning Date:*</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="begins"
                                        onChange={handleChange}
                                        value={
                                            editedEvent?.begins
                                                ? moment
                                                      .utc(editedEvent?.begins)
                                                      .format("YYYY-MM-DD")
                                                : newEvent?.begins
                                                ? moment
                                                      .utc(newEvent?.begins)
                                                      .format("YYYY-MM-DD")
                                                : ""
                                        }
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="expires"
                                >
                                    <Form.Label>Ending Date:*</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="expires"
                                        onChange={handleChange}
                                        value={
                                            editedEvent?.expires
                                                ? moment
                                                      .utc(editedEvent?.expires)
                                                      .format("YYYY-MM-DD")
                                                : newEvent?.expires
                                                ? moment
                                                      .utc(newEvent?.expires)
                                                      .format("YYYY-MM-DD")
                                                : ""
                                        }
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Label>Event Type:*</Form.Label>
                                <Form.Select
                                    name="subject"
                                    onChange={handleChange}
                                    value={
                                        editedEvent?.subject ??
                                        newEvent?.subject ??
                                        ""
                                    }
                                    required
                                >
                                    <option value=""></option>
                                    {[
                                        "Conference",
                                        "Trade Show",
                                        "Workshop",
                                        "Training",
                                    ].map((subject, i) => (
                                        <option key={i} value={subject}>
                                            {subject}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="location"
                                >
                                    <Form.Label>Event Location:</Form.Label>
                                    <Form.Control
                                        className="form-indent-readOnly"
                                        name="location"
                                        onChange={handleChange}
                                        value={
                                            editedEvent?.location ??
                                            newEvent?.location ??
                                            ""
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="venue">
                                    <Form.Label>Event Venue:</Form.Label>
                                    <Form.Control
                                        className="form-indent-readOnly"
                                        name="venue"
                                        onChange={handleChange}
                                        value={
                                            editedEvent?.venue ??
                                            newEvent?.venue ??
                                            ""
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="link">
                                    <Form.Label>Event URL:</Form.Label>
                                    <Form.Control
                                        className="form-indent-readOnly"
                                        name="link"
                                        type="url"
                                        onChange={handleChange}
                                        value={
                                            editedEvent?.link ??
                                            newEvent?.link ??
                                            ""
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                        <Form.Label>Event Announcement:</Form.Label>
                        <HTMLEditor 
                            name="announcement" 
                            handleChange={handleChange} 
                            setIsValid={() => {}} 
                            isValid={true} 
                            defaultValue={event?.announcement} 
                            setEnforceFocusModal={setEnforceFocusModal}
                        />
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Container>
                        <Row className="d-flex justify-content-end">
                            <Col xs={12} sm={2} className="mb-xs-3">
                                <Button
                                    className="w-100"
                                    variant="outline-primary"
                                    onClick={handleShow}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs={12} sm={2} className="mb-xs-3">
                                <Button variant="warning" onClick={resetForm} className="w-100">
                                    Reset
                                </Button>
                            </Col>
                            <Col xs={12} sm={2} className="mb-xs-3">
                                {!event ? (
                                    <Button variant="success" type="submit" className="w-100">
                                        Add
                                    </Button>
                                ) : (
                                    <Button
                                        variant="success"
                                        onClick={handleSave}
                                        className="w-100"
                                    >
                                        Update
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
