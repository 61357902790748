import { Card, Button, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Products/Products.css";
import moment from "moment";

export default function PPCard({ card }) {
  const statusColors = {
    Archived: "bg-orange",
    Development: "bg-yellow",
    Planning: "bg-yellow",
    Initiation: "bg-yellow",
    Publishing: "bg-green",
  };

  return (
    <Card className="shadow bg-product product-card">
      <p
        className={
          "text-center text-uppercase " +
          (card?.sunset_date && moment(card?.sunset_date).isBefore(moment()) ? statusColors["Archived"] : statusColors[card?.status])
        }
      >
        {card?.sunset_date && moment(card?.sunset_date).isBefore(moment()) ? "Archived" : "Approved"}
      </p>
      <Card.Body>
        <h5 className="fw-bold px-3">{card?.pp_name}</h5>
        <div className="d-flex px-3 my-4">
          <Stack className="card-info">
            <p className="mb-0 text-decoration-underline">Short Name</p>
            <Link to={`/protectionprofiles/${card?.pp_id}`}>
              <strong className="text-decoration-none">{card?.pp_short_name}</strong>
            </Link>
          </Stack>
          <Stack className="card-info">
            <p className="mb-0 text-decoration-underline">Technology Type</p>
            <strong>{card?.tech_type}</strong>
          </Stack>
        </div>
        <div className="d-flex px-3 my-4">
          {card?.pp_date && (
            <Stack className="card-info">
              <p className="mb-0 text-decoration-underline">Approval Date</p>
              <strong>{moment.utc(card?.pp_date).format('MM/DD/YYYY')}</strong>
            </Stack>
          )}
          <Stack className="card-info">
            <p className="mb-0 text-decoration-underline">CC Version</p>
            <strong>{card?.cc_version}</strong>
          </Stack>
        </div>
        <div className="d-flex px-3 my-4">
        {!moment(card?.sunset_date).isBefore(moment()) &&
          <Stack className="card-info">
            <p className="mb-0 text-decoration-underline">Proceeded By</p>
            <strong>{card.predecessor_id?.pp_short_name}</strong>
          </Stack>
        }
          {card?.sunset_date && (
            <Stack className="card-info">
              <p className="mb-0 text-decoration-underline">Sunset Date</p>
              <strong>{moment.utc(card?.sunset_date).format('MM/DD/YYYY')}</strong>
            </Stack>
          )}
          <Stack className="card-info">
            <p className="mb-0 text-decoration-underline">Sponsor</p>
            <strong>{card?.pp_sponsor_id}</strong>
          </Stack>
        </div>
        <Card.Text className="d-flex flex-wrap px-3 my-4">
          {!moment(card?.sunset_date).isBefore(moment()) && card?.status === "Publishing" && (
            <Link to={`/protectionprofiles/${card?.pp_id}`} className="ms-auto">
              <Button variant="light" className="border-dark btn-hover">
                Full Details
              </Button>
            </Link>
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
