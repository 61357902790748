import { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import {
  Button,
  Popover,
  OverlayTrigger,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import DeleteModal from "../../UI/DeleteModal";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import ViewPolicyModal from "./ViewPolicyModal";
import PolicyLetterModal from "./PolicyLetterModal";
import EditIcon from "@material-ui/icons/Edit";
import qs from 'qs'

export default function PolciyLettersTable() {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewPolicyModal, setShowViewPolicyModal] = useState(false);
  const [showPolicyLetterModal, setShowPolicyLetterModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currPolicy, setCurrPolicy] = useState({});
  const [policyId, setPolicyId] = useState();
  const [refresh, setRefresh] = useState(false)
  const tableRef = useRef()

  useEffect(() => {
    if (tableRef.current) {
        tableRef.current.onQueryChange()
    }
 
  }, [refresh])  

  const deletePolicyLetter = () => {
    axios
      .delete(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/policies/${policyId}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setAlert({
          message: "Policy Letter Succesfully Deleted",
          type: "success",
        });        
        setRefresh(!refresh)
        handleCloseDeleteModal();
      })
      .catch((error) => {
        setAlert({
          message: "Cannot delete Policy Letter becuase it is being used somewhere else",
          type: "danger",
        });
          console.log(error)    
      });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const columns = [
    { title: "No.", field: "policy_num" },
    { title: "Update No.", field: "update_num" },
    { title: "Addendum No.", field: "addendum_num" },
    {
      title: "Supersided No.",
      field: "superseded_num",
    },
    {
      title: "Title",
      field: "policy_title",
    },
    {
      title: "Date",
      field: "policy_date",
      render: (rowData) => {
        return moment.utc(rowData?.policy_date).format("MM/DD/YYYY");
      },
    },
    {
      title: "Author",
      field: "author",
    },
    {
      title: "ID",
      field: "policy_id",
    },

    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              key="bottom"
              rootClose
              overlay={
                <Popover>
                  <Popover.Body>
                    <Button
                      variant="link"
                      className="m-1 p-0 btn-link"
                      onClick={() => {
                        setCurrPolicy(rowData);
                        setShowViewPolicyModal(true);
                      }}
                    >
                      <RemoveRedEyeIcon color="action" /> View
                    </Button>
                    <Button
                      variant="link"
                      className="m-1 p-0 btn-link"
                      onClick={() => {
                        setCurrPolicy(rowData);
                        setIsEditing(true);
                        setShowPolicyLetterModal(true);
                      }}
                    >
                      <EditIcon color="action" /> Edit
                    </Button>
                    <Button
                      variant="link"
                      className="m-1 p-0 btn-link"
                      onClick={() => {
                        setShowDeleteModal(true);
                        setPolicyId(rowData.policy_id)
                      }}
                    >
                      <DeleteIcon color="action" /> Delete
                    </Button>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="link">
                <MoreVertIcon className="float-end" />
              </Button>
            </OverlayTrigger>{" "}
          </>
        );
      },
    },
  ];

  const options = {
    showTitle: false,
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: false,
    pageSize: 20
  };

  return (
    <Container fluid>
      <ViewPolicyModal
        show={showViewPolicyModal}
        setShow={setShowViewPolicyModal}
        currPolicy={currPolicy}
      />
      <PolicyLetterModal
        show={showPolicyLetterModal}
        setShow={setShowPolicyLetterModal}
        currentPolicy={currPolicy}
        refetch={()=>{setRefresh(!refresh)}}
        editingPolicy={isEditing}
        setAlert={setAlert}
        setEditingPolicy={setIsEditing}
      />
       <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        type="Policy Letter"
        deleteFunction={deletePolicyLetter}
      />

      <Row>
        {alert.message !== "" && (
          <Row className="d-flex justify-content-center mt-3">
            <Col sm={8}>
              <Alert
                variant={alert.type}
                dismissible
                onClose={() => setAlert({ message: "", type: "" })}
              >
                {alert.message}
              </Alert>
            </Col>
          </Row>
        )}
        <Col>
          <Row className="mt-5 mb-3">
            <Col sm={12} md={6}>
              <h3 className="text-bright-navy">Policy Letters</h3>
            </Col>
            <Col sm={12} md={6} className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  className="account-btn w-sm-100"
                  onClick={() => {
                    setIsEditing(false);
                    setShowPolicyLetterModal(true);
                  }}
                >
                  +Add Policy Letter
                </Button>
            </Col>
          </Row>
          <Row>
            <ResponsiveMaterialTable
              columns={columns}
              data={(query) =>
                new Promise((resolve, reject) => {
                  // Extract the necessary information from the query object
                  const {
                      page,
                      pageSize,
                      search,
                      filters,
                      orderBy,
                      orderDirection,
                  } = query;
      
                  let newFilters = filters.map((filter) => {
                      let value = ""
                      if (Array.isArray(filter.value)) {
                          value = filter.value
                      } else {
                          value = filter.value.replace(/['"]+/g, '')
                      }
                      return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                  });
                  const params = {
                      offset: page * pageSize,
                      limit: pageSize,
                      search: search,
                      filters: newFilters,
                      orderBy: orderBy?.field,
                      orderDirection: orderDirection,
                  }
                  axios
                      .get(
                          `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/policies/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                          {
                              withCredentials: true,
                              headers: {
                                  Authorization: `Token ${authToken}`,
                              },
                          }
                      )
                      .then((response) => {
                          resolve({
                              data: response.data.results,
                              page: page,
                              totalCount: response.data.count,
                          });
                      })
                      .catch((error) => {
                          reject(error);
                      });
                  })
                }
              tableRef={tableRef}
              options={options}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
